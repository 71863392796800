import { createReducer } from '@reduxjs/toolkit';
import { keyBy, uniq, concat, assign } from 'lodash';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const messages = createReducer(initialState.messages, {
    [actions.manager.resetMessages]: () => initialState.messages,
    [actions.manager.getMessageListSuccess]: ({ allIds, byId }, { payload: messages }) => {
        const newAllIds = messages.map((b) => b.id);
        const newById = keyBy(messages, (b) => b.id);
        const joinedByIds = assign({}, byId, newById);
        const joinedAllIds = uniq(concat(allIds, newAllIds)).sort((a, b) => a - b);
        return { byId: joinedByIds, allIds: joinedAllIds };
    },
    [actions.manager.gotMessage]: ({ allIds, byId }, { payload: message }) => {
        return {
            byId: { ...byId, [message.id]: message },
            allIds: uniq(concat(allIds, message.id))
        };
    }
});

export default messages;
