import { createReducer } from '@reduxjs/toolkit';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const activeChatId = createReducer(initialState.activeChatId, {
    [actions.manager.setActiveChatId]: (state, { payload: activeChatId }) => {
        return activeChatId;
    }
});

export default activeChatId;
