import { createAction } from '@reduxjs/toolkit';

// Manager WS Connection
export const WSConnectionConnecting = createAction('MANAGER_WS_CONNECTION_CONNECTING');
export const WSConnectionOpen = createAction('MANAGER_WS_CONNECTION_OPEN');
export const WSConnectionOnError = createAction('MANAGER_WS_CONNECTION_ON_ERROR');
export const WSConnectionClose = createAction('MANAGER_WS_CONNECTION_CLOSE');

// Organization
export const getCurrentOrganization = createAction('GET_CURRENT_ORGANIZATION');
export const getCurrentOrganizationPending = createAction('GET_CURRENT_ORGANIZATION_PENDING');
export const getCurrentOrganizationSuccess = createAction('GET_CURRENT_ORGANIZATION_SUCCESS');
export const getCurrentOrganizationFailure = createAction('GET_CURRENT_ORGANIZATION_FAILURE');

export const updateOrganization = createAction('UPDATE_ORGANIZATION');
export const updateOrganizationPending = createAction('UPDATE_ORGANIZATION_PENDING');
export const updateOrganizationSuccess = createAction('UPDATE_ORGANIZATION_SUCCESS');
export const updateOrganizationFailure = createAction('UPDATE_ORGANIZATION_FAILURE');

// Operator
export const getCurrentOperator = createAction('GET_CURRENT_OPERATOR');
export const getCurrentOperatorPending = createAction('GET_CURRENT_OPERATOR_PENDING');
export const getCurrentOperatorSuccess = createAction('GET_CURRENT_OPERATOR_SUCCESS');
export const getCurrentOperatorFailure = createAction('GET_CURRENT_OPERATOR_FAILURE');

// OperatorList
export const getOperatorList = createAction('GET_OPERATOR_LIST');
export const operatorListIsEmpty = createAction('OPERATOR_LIST_IS_EMPTY');
export const getOperatorListPending = createAction('GET_OPERATOR_LIST_PENDING');
export const getOperatorListSuccess = createAction('GET_OPERATOR_LIST_SUCCESS');
export const getOperatorListFailure = createAction('GET_OPERATOR_LIST_FAILURE');

// Chats
export const getChatList = createAction('GET_CHAT_LIST');
export const chatListIsEmpty = createAction('CHAT_LIST_IS_EMPTY');
export const getChatListPending = createAction('GET_CHAT_LIST_PENDING');
export const getChatListSuccess = createAction('GET_CHAT_LIST_SUCCESS');
export const getChatListFailure = createAction('GET_CHAT_LIST_FAILURE');

export const gotChat = createAction('GOT_CHAT');

export const setActiveChatId = createAction('SET_ACTIVE_CHAT_ID');

export const gotChatUserList = createAction('GOT_CHAT_USER_LIST');

export const banChat = createAction('BAN_CHAT');
export const banChatPending = createAction('BAN_CHAT_PENDING');
export const banChatSuccess = createAction('BAN_CHAT_SUCCESS');
export const banChatFailure = createAction('BAN_CHAT_FAILURE');

// Messages
export const getMessageList = createAction('GET_MESSAGE_LIST');
export const getMessageListPending = createAction('GET_MESSAGE_LIST_PENDING');
export const getMessageListSuccess = createAction('GET_MESSAGE_LIST_SUCCESS');
export const getMessageListFailure = createAction('GET_MESSAGE_LIST_FAILURE');
export const getMoreMessages = createAction('GET_MORE_MESSAGES');
export const messageListIsEmpty = createAction('MESSAGE_LIST_IS_EMPTY');
export const resetMessages = createAction('RESET_MESSAGES');

export const gotMessage = createAction('GOT_MESSAGE');

export const sendMessage = createAction('SEND_MESSAGE');
export const sendMessagePending = createAction('SEND_MESSAGE_PENDING');
export const sendMessageSuccess = createAction('SEND_MESSAGE_SUCCESS');
export const sendMessageFailure = createAction('SEND_MESSAGE_FAILURE');

export const createOperator = createAction('CREATE_OPERATOR');
export const createOperatorPending = createAction('CREATE_OPERATOR_PENDING');
export const createOperatorSuccess = createAction('CREATE_OPERATOR_SUCCESS');
export const createOperatorFailure = createAction('CREATE_OPERATOR_FAILURE');

export const editProfile = createAction('EDIT_PROFILE');
export const editProfilePending = createAction('EDIT_PROFILE_PENDING');
export const editProfileSuccess = createAction('EDIT_PROFILE_SUCCESS');
export const editProfileFailure = createAction('EDIT_PROFILE_FAILURE');

export const addOrganizationDomain = createAction('ADD_ORGANIZATION_DOMAIN');
export const addOrganizationDomainPending = createAction('ADD_ORGANIZATION_DOMAIN_PENDING');
export const addOrganizationDomainSuccess = createAction('ADD_ORGANIZATION_DOMAIN_SUCCESS');
export const addOrganizationDomainFailure = createAction('ADD_ORGANIZATION_DOMAIN_FAILURE');

export const removeOrganizationDomain = createAction('REMOVE_ORGANIZATION_DOMAIN');
export const removeOrganizationDomainPending = createAction('REMOVE_ORGANIZATION_DOMAIN_PENDING');
export const removeOrganizationDomainSuccess = createAction('REMOVE_ORGANIZATION_DOMAIN_SUCCESS');
export const removeOrganizationDomainFailure = createAction('REMOVE_ORGANIZATION_DOMAIN_FAILURE');

export const removeOperator = createAction('REMOVE_OPERATOR');
export const removeOperatorPending = createAction('REMOVE_OPERATOR_PENDING');
export const removeOperatorSuccess = createAction('REMOVE_OPERATOR_SUCCESS');
export const removeOperatorFailure = createAction('REMOVE_OPERATOR_FAILURE');

export const editOperator = createAction('EDIT_OPERATOR');
export const editOperatorPending = createAction('EDIT_OPERATOR_PENDING');
export const editOperatorSuccess = createAction('EDIT_OPERATOR_SUCCESS');
export const editOperatorFailure = createAction('EDIT_OPERATOR_FAILURE');

export const getNumberUnreadMessages = createAction('GET_NUMBER_UNREAD_MESSAGES');
export const getNumberUnreadMessagesPending = createAction('GET_NUMBER_UNREAD_MESSAGES_PENDING');
export const getNumberUnreadMessagesSuccess = createAction('GET_NUMBER_UNREAD_MESSAGES_SUCCESS');
export const getNumberUnreadMessagesFailure = createAction('GET_NUMBER_UNREAD_MESSAGES_FAILURE');

export const gotLastMessage = createAction('GOT_LAST_MESSAGES');
export const getLastMessages = createAction('GET_LAST_MESSAGES');
export const getLastMessagesPending = createAction('GET_LAST_MESSAGES_PENDING');
export const getLastMessagesSuccess = createAction('GET_LAST_MESSAGES_SUCCESS');
export const getLastMessagesFailure = createAction('GET_LAST_MESSAGES_FAILURE');

export const markMessageAsRead = createAction('MARK_MESSAGE_AS_READ');
export const markMessageAsReadPending = createAction('MARK_MESSAGE_AS_READ_PENDING');
export const markMessageAsReadSuccess = createAction('MARK_MESSAGE_AS_READ_SUCCESS');
export const markMessageAsReadFailure = createAction('MARK_MESSAGE_AS_READ_FAILURE');
