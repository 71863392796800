import React from 'react';
import { Provider } from 'react-redux';
import App from './App';

const AppRoot = ({ store }) => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

export default AppRoot;
