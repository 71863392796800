// Import all necessary svg-sprite icons here
// import exampleIcon from 'Media/icons/svg-sprite/example.svg'
import addAvatarIcon from 'Media/icons/svg-sprite/add-avatar.svg';
import archiveIcon from 'Media/icons/svg-sprite/archive.svg';
import arrowDownIcon from 'Media/icons/svg-sprite/arrow-down.svg';
import arrowLeftIcon from 'Media/icons/svg-sprite/arrow-left.svg';
import arrowLeftLongIcon from 'Media/icons/svg-sprite/arrow-left-long.svg';
import arrowRefreshIcon from 'Media/icons/svg-sprite/arrow-refresh.svg';
import banChatIcon from 'Media/icons/svg-sprite/ban-chat.svg';
import basketIcon from 'Media/icons/svg-sprite/basket.svg';
import checkIcon from 'Media/icons/svg-sprite/check.svg';
import closeCrossIcon from 'Media/icons/svg-sprite/close-cross.svg';
import closeCrossInCircleIcon from 'Media/icons/svg-sprite/close-cross-in-circle.svg';
import conversationIcon from 'Media/icons/svg-sprite/conversation.svg';
import copyIcon from 'Media/icons/svg-sprite/copy.svg';
import crossedEyeIcon from 'Media/icons/svg-sprite/crossed-eye.svg';
import documentIcon from 'Media/icons/svg-sprite/document.svg';
import domainIcon from 'Media/icons/svg-sprite/domain.svg';
import doubleTickIcon from 'Media/icons/svg-sprite/double-tick.svg';
import editIcon from 'Media/icons/svg-sprite/edit.svg';
import emojiIcon from 'Media/icons/svg-sprite/emoji.svg';
import exclamationMarkIcon from 'Media/icons/svg-sprite/exclamation-mark.svg';
import eyeIcon from 'Media/icons/svg-sprite/eye.svg';
import imageIcon from 'Media/icons/svg-sprite/image.svg';
import logoutIcon from 'Media/icons/svg-sprite/logout.svg';
import messageCircleIcon from 'Media/icons/svg-sprite/message-circle.svg';
import moreVerticalIcon from 'Media/icons/svg-sprite/more-vertical.svg';
import noImagesIcon from 'Media/icons/svg-sprite/no-images.svg';
import operatorsIcon from 'Media/icons/svg-sprite/operators.svg';
import paperclipIcon from 'Media/icons/svg-sprite/paperclip.svg';
import resetSettingsIcon from 'Media/icons/svg-sprite/reset-settings.svg';
import rotateLeftIcon from 'Media/icons/svg-sprite/rotate-left.svg';
import rotateRightIcon from 'Media/icons/svg-sprite/rotate-right.svg';
import searchIcon from 'Media/icons/svg-sprite/search.svg';
import sendIcon from 'Media/icons/svg-sprite/send.svg';
import settingsIcon from 'Media/icons/svg-sprite/settings.svg';
import uploadFileIcon from 'Media/icons/svg-sprite/upload-file.svg';
import userIcon from 'Media/icons/svg-sprite/user.svg';
import usersIcon from 'Media/icons/svg-sprite/users.svg';

export const NAMES = {
    ADD_AVATAR: 'add-avatar',
    ARCHIVE: 'archive',
    ARROW_DOWN: 'arrow-down',
    ARROW_LEFT: 'arrow-left',
    ARROW_LEFT_LONG: 'arrow-left-long',
    ARROW_REFRESH: 'arrow-refresh',
    BAN_CHAT: 'ban-chat',
    BASKET: 'basket',
    CHECK: 'check',
    CLOSE_CROSS: 'close-cross',
    CLOSE_CROSS_IN_CIRCLE: 'close-cross-in-circle',
    CONVERSATION: 'conversation',
    COPY: 'copy',
    CROSSED_EYE: 'crossed-eye',
    DOCUMENT: 'document',
    DOMAIN: 'domain',
    DOUBLE_TICK: 'double-tick',
    EDIT: 'edit',
    EMOJI: 'emoji',
    EXCLAMATION_MARK: 'exclamation-mark',
    EYE: 'eye',
    IMAGE: 'image',
    LOGOUT: 'logout',
    MESSAGE_CIRCLE: 'message-circle',
    MORE_VERTICAL: 'more-vertical',
    NO_IMAGES: 'no-images',
    OPERATORS: 'operators',
    PAPERCLIP: 'paperclip',
    RESET_SETTINGS: 'reset-settings',
    ROTATE_LEFT: 'rotate-left',
    ROTATE_RIGHT: 'rotate-right',
    SEARCH: 'search',
    SEND: 'send',
    SETTINGS: 'settings',
    UPLOAD_FILE: 'upload-file',
    USER: 'user',
    USERS: 'users'
};

const ICONS = {
    // example: exampleIcon
    [NAMES.ADD_AVATAR]: addAvatarIcon,
    [NAMES.ARCHIVE]: archiveIcon,
    [NAMES.ARROW_LEFT]: arrowLeftIcon,
    [NAMES.ARROW_LEFT_LONG]: arrowLeftLongIcon,
    [NAMES.ARROW_DOWN]: arrowDownIcon,
    [NAMES.ARROW_REFRESH]: arrowRefreshIcon,
    [NAMES.BAN_CHAT]: banChatIcon,
    [NAMES.BASKET]: basketIcon,
    [NAMES.CHECK]: checkIcon,
    [NAMES.CLOSE_CROSS]: closeCrossIcon,
    [NAMES.CLOSE_CROSS_IN_CIRCLE]: closeCrossInCircleIcon,
    [NAMES.CONVERSATION]: conversationIcon,
    [NAMES.COPY]: copyIcon,
    [NAMES.CROSSED_EYE]: crossedEyeIcon,
    [NAMES.DOCUMENT]: documentIcon,
    [NAMES.DOMAIN]: domainIcon,
    [NAMES.DOUBLE_TICK]: doubleTickIcon,
    [NAMES.EDIT]: editIcon,
    [NAMES.EMOJI]: emojiIcon,
    [NAMES.IMAGE]: imageIcon,
    [NAMES.EXCLAMATION_MARK]: exclamationMarkIcon,
    [NAMES.EYE]: eyeIcon,
    [NAMES.LOGOUT]: logoutIcon,
    [NAMES.MESSAGE_CIRCLE]: messageCircleIcon,
    [NAMES.MORE_VERTICAL]: moreVerticalIcon,
    [NAMES.NO_IMAGES]: noImagesIcon,
    [NAMES.OPERATORS]: operatorsIcon,
    [NAMES.PAPERCLIP]: paperclipIcon,
    [NAMES.ROTATE_LEFT]: rotateLeftIcon,
    [NAMES.ROTATE_RIGHT]: rotateRightIcon,
    [NAMES.RESET_SETTINGS]: resetSettingsIcon,
    [NAMES.SEARCH]: searchIcon,
    [NAMES.SEND]: sendIcon,
    [NAMES.SETTINGS]: settingsIcon,
    [NAMES.UPLOAD_FILE]: uploadFileIcon,
    [NAMES.USER]: userIcon,
    [NAMES.USERS]: usersIcon
};

export default ICONS;
