import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const currentOrganization = createReducer(initialState.currentOrganization, {
    [actions.manager.getCurrentOrganizationSuccess]: (state, { payload }) => {
        return payload;
    }
});

export default currentOrganization;
