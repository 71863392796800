const HTTP_PROTOCOL = 'https://';
const WEBSOCKET_PROTOCOL = 'wss://';

export const REQUEST_OPTIONS = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include'
};

export const SERVICE_NAMES = {
    AUTH: 'AUTH',
    GRECAPTCHA: 'GRECAPTCHA',
    MANAGER: 'MANAGER',
    FILE: 'FILE'
};

export const AUTH_ACTION_TYPES = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    REFRESH: 'refresh',
    CHECK: 'check',
    REGISTRATION: 'registration',
    ACTIVATION: 'activation',
    RESET: 'reset'
};

export const FILE_ACTION_TYPES = {
    UPLOAD: 'upload',
    DOWNLOAD: 'download'
};

export const RESPONSE_STATUSES = {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    TOO_MANY_REQUESTS: 429,
    FORBIDDEN: 403
};

export const getAuthRoutes = (apiUrl) => {
    const authUrlPart = `${HTTP_PROTOCOL}${apiUrl}`;
    const routes = {
        [AUTH_ACTION_TYPES.LOGIN]: `${authUrlPart}/login`,
        [AUTH_ACTION_TYPES.LOGOUT]: `${authUrlPart}/logout`,
        [AUTH_ACTION_TYPES.REFRESH]: `${authUrlPart}/refresh`,
        [AUTH_ACTION_TYPES.CHECK]: `${authUrlPart}/check`,
        [AUTH_ACTION_TYPES.REGISTRATION]: `${authUrlPart}/register`,
        [AUTH_ACTION_TYPES.ACTIVATION]: `${authUrlPart}/activate`,
        [AUTH_ACTION_TYPES.RESET]: `${authUrlPart}/reset`
    };
    return (type) => routes[type];
};

export const getWebSocketRoutes = (apiUrl) => {
    const urlPart = `${WEBSOCKET_PROTOCOL}${apiUrl}`;
    const routes = {
        [SERVICE_NAMES.MANAGER]: `${urlPart}/manager`
    };
    return (type) => routes[type];
};

export const getFileRoutes = (apiUrl) => {
    const fileUrlPart = `${HTTP_PROTOCOL}${apiUrl}/file`;
    const routes = {
        [FILE_ACTION_TYPES.UPLOAD]: `${fileUrlPart}/upload`,
        [FILE_ACTION_TYPES.DOWNLOAD]: `${fileUrlPart}/download`
    };
    return (type) => routes[type];
};
