import { createReducer, combineReducers } from '@reduxjs/toolkit';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';
import { REQUEST_STATUSES } from 'Constants';

const requestStatuses = combineReducers({
    currentOperator: createReducer(initialState.requestStatuses.currentOperator, {
        [actions.manager.getCurrentOperatorPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getCurrentOperatorSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getCurrentOperatorFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    chats: createReducer(initialState.requestStatuses.chats, {
        [actions.manager.getChatListPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getChatListSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getChatListFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.chatListIsEmpty]: () => REQUEST_STATUSES.EMPTY,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    activation: createReducer(initialState.requestStatuses.activation, {
        [actions.auth.requestActivationPending]: () => REQUEST_STATUSES.PENDING,
        [actions.auth.requestActivationSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.auth.requestActivationFailure]: () => REQUEST_STATUSES.FAILURE
    }),
    messages: createReducer(initialState.requestStatuses.messages, {
        [actions.manager.getMessageListPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getMessageListSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getMessageListFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.messageListIsEmpty]: () => REQUEST_STATUSES.EMPTY,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    sendMessage: createReducer(initialState.requestStatuses.sendMessage, {
        [actions.manager.sendMessagePending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.sendMessageSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.sendMessageFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    operators: createReducer(initialState.requestStatuses.operators, {
        [actions.manager.getOperatorListPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getOperatorListSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getOperatorListFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT,
        [actions.manager.operatorListIsEmpty]: () => REQUEST_STATUSES.EMPTY
    }),
    createOperator: createReducer(initialState.requestStatuses.createOperator, {
        [actions.manager.createOperatorPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.createOperatorSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.createOperatorFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    editProfile: createReducer(initialState.requestStatuses.editProfile, {
        [actions.manager.editProfilePending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.editProfileSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.editProfileFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    addOrganizationDomain: createReducer(initialState.requestStatuses.addOrganizationDomain, {
        [actions.manager.addOrganizationDomainPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.addOrganizationDomainSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.addOrganizationDomainFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    removeOrganizationDomain: createReducer(initialState.requestStatuses.removeOrganizationDomain, {
        [actions.manager.removeOrganizationDomainPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.removeOrganizationDomainSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.removeOrganizationDomainFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    removeOperator: createReducer(initialState.requestStatuses.removeOperator, {
        [actions.manager.removeOperatorPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.removeOperatorSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.removeOperatorFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    editOperator: createReducer(initialState.requestStatuses.editOperator, {
        [actions.manager.editOperatorPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.editOperatorSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.editOperatorFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    banChat: createReducer(initialState.requestStatuses.banChat, {
        [actions.manager.banChatPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.banChatSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.banChatFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    currentOrganization: createReducer(initialState.requestStatuses.currentOrganization, {
        [actions.manager.getCurrentOrganizationPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getCurrentOrganizationSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getCurrentOrganizationFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    updateOrganization: createReducer(initialState.requestStatuses.updateOrganization, {
        [actions.manager.updateOrganizationPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.updateOrganizationSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.updateOrganizationFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    numberUnreadMessages: createReducer(initialState.requestStatuses.numberUnreadMessages, {
        [actions.manager.getNumberUnreadMessagesPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getNumberUnreadMessagesSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getNumberUnreadMessagesFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    lastMessages: createReducer(initialState.requestStatuses.lastMessages, {
        [actions.manager.getLastMessagesPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.getLastMessagesSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.getLastMessagesFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    }),
    markMessageAsRead: createReducer(initialState.requestStatuses.markMessageAsRead, {
        [actions.manager.markMessageAsReadPending]: () => REQUEST_STATUSES.PENDING,
        [actions.manager.markMessageAsReadSuccess]: () => REQUEST_STATUSES.SUCCESS,
        [actions.manager.markMessageAsReadFailure]: () => REQUEST_STATUSES.FAILURE,
        [actions.manager.WSConnectionClose]: () => REQUEST_STATUSES.INIT
    })
});

export default requestStatuses;
