import { realtalk } from '../../submodules/protocol/lib/bundle.pb';

const PROTOCOL_SECTIONS = {
    manager: realtalk.manager
};

const getProtocolVersion = (section) => {
    const version = section.Version.Value;

    return {
        major: version.MAJOR,
        minor: version.MINOR,
        patch: version.PATCH
    };
};

const toStringProtocolVersion = (protocolVersionObj) => {
    const { major, minor, patch } = protocolVersionObj;
    return `${major}.${minor}.${patch}`;
};

export const MANAGER_PROTOCOL_VERSION_OBJECT = getProtocolVersion(PROTOCOL_SECTIONS.manager);
export const MANAGER_VERSION_STRING = toStringProtocolVersion(MANAGER_PROTOCOL_VERSION_OBJECT);
export const MANAGER_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL = `realtalk-${MANAGER_VERSION_STRING}`;

export const MANAGER_REQUESTS = {
    GET_CURRENT_ORGANIZATION: 'getCurrentOrganization',
    GET_CURRENT_OPERATOR: 'getCurrentOperator',
    GET_CHAT_LIST: 'getChatList',
    GET_CHAT_USER: 'getChatUser',
    GET_MESSAGE_LIST: 'getMessageList',
    MESSAGE: 'message',
    GET_OPERATOR_LIST: 'getOperatorList',
    OPERATOR: 'operator',
    ADD_ORGANIZATION_DOMAIN: 'addOrganizationDomain',
    REMOVE_ORGANIZATION_DOMAIN: 'removeOrganizationDomain',
    BAN_CHAT: 'banChat',
    UPDATE_ORGANIZATION: 'updateOrganization',
    GET_NUMBER_UNREAD_MESSAGES: 'getNumberUnreadMessages',
    MARK_MESSAGE_AS_READ: 'markMessageAsRead'
};

export const RESULT_CODES = {
    SUCCESS: realtalk.manager.Result.Code.SUCCESS,
    FAIL_INVALID_REQUEST: realtalk.manager.Result.Code.FAIL_INVALID_REQUEST
};

export const OPERATOR_ROLE = {
    MANAGER: realtalk.manager.OperatorRole.OR_MANAGE_OPERATORS,
    OPERATOR: realtalk.manager.OperatorRole.OR_CHAT_OPERATOR
};
