import { isNull, isFunction, set, cloneDeep } from 'lodash';
import { RESPONSE_STATUSES, AUTH_ACTION_TYPES, REQUEST_OPTIONS, getAuthRoutes } from 'Constants';

import base64Unicode from 'Utils/base64Unicode';
import logger from 'Utils/logger';

const AUTH_REQUEST_OPTIONS = {
    ...REQUEST_OPTIONS
};

const log = logger('AuthService:');
const logError = logger('Error:AuthService:');

const getRequestOptionsWithAuthorizationHeader = (login, password) => {
    const options = cloneDeep(AUTH_REQUEST_OPTIONS);
    set(options, 'headers.Authorization', `Basic ${base64Unicode.encode(`${login}:${password}`)}`);
    return options;
};

const isSuccess = (responseStatus) => {
    const { SUCCESS } = RESPONSE_STATUSES;

    return responseStatus === SUCCESS;
};

const getMaxAgeHeaderValue = (response) => {
    const { headers } = response;
    const maxAge = headers.get('Max-Age');

    return !isNull(maxAge)
        ? // Initially maxAge is in seconds
          // Convert to milliseconds if not null
          parseInt(maxAge, 10) * 1000
        : maxAge;
};

const handleResponseErrors = (response) => {
    const { SUCCESS, UNAUTHORIZED, TOO_MANY_REQUESTS, FORBIDDEN } = RESPONSE_STATUSES;
    const { status, statusText } = response;

    if ([SUCCESS, UNAUTHORIZED, TOO_MANY_REQUESTS, FORBIDDEN].includes(status)) {
        return response;
    }

    const errorMessage = `type: AuthError, status: ${status}, statusText: ${statusText}`;

    throw new Error(errorMessage);
};

class AuthService {
    constructor(authApiDomain = '', loader = fetch) {
        this._fetch = (...args) => loader(...args);
        this._getRoute = getAuthRoutes(authApiDomain);
    }

    login({ login, password, addGrecaptchaToUrl }) {
        const loginOptions = getRequestOptionsWithAuthorizationHeader(login, password);

        let url = this._getRoute(AUTH_ACTION_TYPES.LOGIN);

        if (isFunction(addGrecaptchaToUrl)) {
            url = addGrecaptchaToUrl(url);
        }

        if (!url) {
            throw new Error('Login URL is invalid');
        }

        log('Request login: url: options: %o', url, loginOptions);

        return this._fetch(url, loginOptions)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;
                const maxAge = getMaxAgeHeaderValue(response);

                return response.text().then((body) => ({
                    status,
                    maxAge,
                    body
                }));
            })
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);

                log('Got login response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.LOGIN,
                    status: response.status,
                    maxAge: response.maxAge,
                    isAuthenticated
                };

                if (response.status === RESPONSE_STATUSES.TOO_MANY_REQUESTS) {
                    info.retryAfter = parseInt(response.body, 10);
                }

                return info;
            })
            .catch((err) => {
                logError('Login request error: %o', err.message);
                throw new Error(err);
            });
    }

    register(formData) {
        const registrationUrl = this._getRoute(AUTH_ACTION_TYPES.REGISTRATION);
        const data = { ...AUTH_REQUEST_OPTIONS, body: JSON.stringify(formData) };

        if (!registrationUrl) {
            throw new Error('Registration URL is invalid');
        }

        log('Request registration');

        return this._fetch(registrationUrl, data)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;

                return response.text().then((body) => ({
                    status,
                    body
                }));
            })
            .then((response) => {
                const isRegistered = isSuccess(response.status);

                log('Got registration response: isRegistered: %o', isRegistered);

                const info = {
                    action: AUTH_ACTION_TYPES.REGISTRATION,
                    status: response.status,
                    isRegistered
                };

                return info;
            })
            .catch((err) => {
                logError('Registration request error: %o', err.message);
                throw new Error(err);
            });
    }

    // The server will always respond with 200 (Success)
    // even if the cookie is expired and the status is 401 (Unauthorized)
    // except for 400 (Bad Request)
    logout() {
        const logoutUrl = this._getRoute(AUTH_ACTION_TYPES.LOGOUT);

        if (!logoutUrl) {
            throw new Error('Logout URL is invalid');
        }

        log('Request logout');

        return this._fetch(logoutUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = !isSuccess(response.status);

                log('Got logout response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.LOGOUT,
                    status: response.status,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                logError('Logout request error: %o', err.message);
                throw new Error(err);
            });
    }

    refresh() {
        const refreshUrl = this._getRoute(AUTH_ACTION_TYPES.REFRESH);

        if (!refreshUrl) {
            throw new Error('Refresh URL is invalid');
        }

        log('Request refresh');

        return this._fetch(refreshUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);
                const maxAge = getMaxAgeHeaderValue(response);

                log('Got refresh response: isAuthenticated: %o, maxAge: %o', isAuthenticated, maxAge);

                const info = {
                    action: AUTH_ACTION_TYPES.REFRESH,
                    status: response.status,
                    maxAge,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                logError('Refresh request error: %o', err.message);
                throw new Error(err);
            });
    }

    check() {
        const checkUrl = this._getRoute(AUTH_ACTION_TYPES.CHECK);

        if (!checkUrl) {
            throw new Error('Check URL is invalid');
        }

        log('Request check auth');

        return this._fetch(checkUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);

                log('Got check auth response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.CHECK,
                    status: response.status,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                logError('Check auth request error: %o', err.message);
                throw new Error(err);
            });
    }

    activate(id) {
        const activationUrl = this._getRoute(AUTH_ACTION_TYPES.ACTIVATION);
        const data = { ...AUTH_REQUEST_OPTIONS, body: JSON.stringify({ id }) };

        if (!activationUrl) {
            throw new Error('Activation URL is invalid');
        }

        log('Request activation');

        return this._fetch(activationUrl, data)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;

                return response.text().then((body) => ({
                    status,
                    body
                }));
            })
            .then((response) => {
                const isActivated = isSuccess(response.status);

                log('Got activation response: isActivated: %o', isActivated);

                const info = {
                    action: AUTH_ACTION_TYPES.ACTIVATION,
                    status: response.status,
                    isActivated
                };

                return info;
            })
            .catch((err) => {
                logError('Activation request error: %o', err.message);
                throw new Error(err);
            });
    }

    resetPassword(formData) {
        const resetUrl = this._getRoute(AUTH_ACTION_TYPES.RESET);
        const data = { ...AUTH_REQUEST_OPTIONS, body: JSON.stringify(formData) };

        if (!resetUrl) {
            throw new Error('Reset URL is invalid');
        }

        log('Request reset');

        return this._fetch(resetUrl, data)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;

                return response.text().then((body) => ({
                    status,
                    body
                }));
            })
            .catch((err) => {
                logError('Reset request error: %o', err.message);
                throw new Error(err);
            });
    }
}

export default AuthService;
