import { createReducer } from '@reduxjs/toolkit';
import { keyBy, union, omit } from 'lodash';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const OCTET = 256;

const randomBackgroundColor = () => Math.floor(Math.random() * OCTET);

export const getRandomBackgroundColor = () => {
    const rColor = randomBackgroundColor();
    const gColor = randomBackgroundColor();
    const bColor = randomBackgroundColor();

    return `rgb(${rColor}, ${gColor}, ${bColor})`;
};

const chatUsers = createReducer(initialState.chatUsers, {
    [actions.manager.gotChatUserList]: ({ allIds, byId }, { payload: chatUsers }) => {
        const chatUsersWithBgColor = chatUsers.map((item) => {
            return { bgColor: getRandomBackgroundColor(), ...item };
        });

        const newAllIds = chatUsersWithBgColor.map((chat) => chat.id);
        const newById = keyBy(chatUsersWithBgColor, (chat) => chat.id);
        const joinedByIds = { ...byId, ...newById };
        const joinedAllIds = union(allIds, newAllIds);

        return { byId: joinedByIds, allIds: joinedAllIds };
    },
    [actions.manager.banChatSuccess]: (state, { payload }) => {
        const { chatUsers, ownerId } = payload;
        const newAllIds = chatUsers.allIds.filter((item) => item !== ownerId);
        const newById = omit(chatUsers.byId, ownerId);

        return { byId: newById, allIds: newAllIds };
    }
});

export default chatUsers;
