import { put, getContext, all, select } from 'redux-saga/effects';
import { get, isEmpty, uniq, difference } from 'lodash';
import * as actions from 'Store/actions';
import { MANAGER_REQUESTS, SERVICE_NAMES } from 'Constants';
import logger from 'Utils/logger';

const logError = logger('Error:Saga:Chats:');

export function* getChatList() {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);

        if (managerService.isOpening || managerService.isOpened) {
            yield put(actions.manager.getChatListPending());
            const response = yield managerService.request(MANAGER_REQUESTS.GET_CHAT_LIST, {});
            const responseChats = get(response, 'body.chat', []);

            // Request info about chat users in the chat list
            // before adding chat list
            yield requestChatUserIds(responseChats);

            yield put(actions.manager.getChatListSuccess(responseChats));
        }
    } catch (err) {
        console.log(err);
        yield put(actions.manager.getChatListFailure(err.message));
    }
}

export function* handleChat({ body }) {
    try {
        // Request info about chat users in the chat
        // before adding a chat
        yield requestChatUserIds([body]);
        yield put(actions.manager.gotChat(body));
    } catch (err) {
        logError(err.message);
    }
}

function* requestChatUserIds(chats) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        const chatUsers = yield select((state) => state.chatUsers);
        const currentChatUserIds = get(chatUsers, 'allIds', []);
        const receivedChatUserIds = uniq(
            chats.reduce((acc, chat) => {
                return [...acc, ...chat.client.chatUserId];
            }, [])
        );

        const chatUserIds = isEmpty(currentChatUserIds)
            ? receivedChatUserIds
            : difference(receivedChatUserIds, currentChatUserIds);

        if (isEmpty(chatUserIds)) {
            return;
        }

        const requestChatUserId = (chatUserId) => {
            return managerService.request(MANAGER_REQUESTS.GET_CHAT_USER, { byId: { chatUserId } });
        };
        const requestChatUserPromises = chatUserIds.map((id) => requestChatUserId(id));
        const responseChatUserList = yield all(requestChatUserPromises);
        const chatUserList = responseChatUserList.reduce((acc, userItem) => {
            return [...acc, { ...userItem.body }];
        }, []);

        yield put(actions.manager.gotChatUserList(chatUserList));
    } catch (err) {
        logError(err.message);
    }
}

export function* banChat({ payload }) {
    const { chatId, ownerId } = payload;

    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        const chatUsers = yield select((state) => state.chatUsers);
        yield put(actions.manager.banChatPending());
        yield managerService.request(MANAGER_REQUESTS.BAN_CHAT, { chatId });
        yield put(actions.manager.banChatSuccess({ ownerId, chatUsers }));
    } catch (err) {
        console.log(err);
        yield put(actions.manager.banChatFailure(err.message));
    }
}
