class GrecaptchaService {
    ACTIONS = {
        LOGIN: 'login'
    };

    constructor(key) {
        this._grecaptchaKey = key;
        this._isGrecaptchaReady = false;
        this._isGrecaptchaLoaded = false;
        this._init();
    }

    _init() {
        if (this._isGrecaptchaLoaded) {
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${this._grecaptchaKey}`;

        script.addEventListener('load', () => {
            this._isGrecaptchaLoaded = true;

            if (!window.grecaptcha) {
                return (this._isGrecaptchaReady = false);
            }

            window.grecaptcha.ready(() => {
                this._isGrecaptchaReady = true;
            });
        });

        document.body.appendChild(script);
    }

    get isReady() {
        return this._isGrecaptchaReady;
    }

    execute(options) {
        return new Promise((resolve, reject) => {
            if (!window.grecaptcha) {
                reject(Error('Grecaptcha is not defined!'));
            }

            window.grecaptcha.execute(this._grecaptchaKey, options).then(
                (token) => {
                    resolve({ token, action: options.action });
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    addQueryParamsToUrl(token, action) {
        return (url) => {
            const tokenParam = `recaptcha_token=${encodeURIComponent(token)}`;
            const actionParam = `recaptcha_action=${encodeURIComponent(action)}`;

            const separator = url.indexOf('?') !== -1 ? '&' : '?';

            return `${url}${separator}${tokenParam}&${actionParam}`;
        };
    }
}

export default GrecaptchaService;
