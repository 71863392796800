import { createAction } from '@reduxjs/toolkit';
import { SERVICE_NAMES, RESPONSE_STATUSES } from 'Constants';

export const requestLoginSuccess = createAction('AUTH_REQUEST_LOGIN_SUCCESS');
export const requestLoginFailure = createAction('AUTH_REQUEST_LOGIN_FAILURE');

export const requestLogoutPending = createAction('AUTH_REQUEST_LOGOUT_PENDING');
export const requestLogoutSuccess = createAction('AUTH_REQUEST_LOGOUT_SUCCESS');
export const requestLogoutFailure = createAction('AUTH_REQUEST_LOGOUT_FAILURE');

export const requestRefreshPending = createAction('AUTH_REQUEST_REFRESH_PENDING');
export const requestRefreshSuccess = createAction('AUTH_REQUEST_REFRESH_SUCCESS');
export const requestRefreshFailure = createAction('AUTH_REQUEST_REFRESH_FAILURE');

export const requestCheckPending = createAction('AUTH_REQUEST_CHECK_PENDING');
export const requestCheckSuccess = createAction('AUTH_REQUEST_CHECK_SUCCESS');
export const requestCheckFailure = createAction('AUTH_REQUEST_CHECK_FAILURE');

export const requestRegistrationPending = createAction('AUTH_REQUEST_REGISTRATION_PENDING');
export const requestRegistrationSuccess = createAction('AUTH_REQUEST_REGISTRATION_SUCCESS');
export const requestRegistrationFailure = createAction('AUTH_REQUEST_REGISTRATION_FAILURE');

export const requestActivationPending = createAction('AUTH_REQUEST_ACTIVATION_PENDING');
export const requestActivationSuccess = createAction('AUTH_REQUEST_ACTIVATION_SUCCESS');
export const requestActivationFailure = createAction('AUTH_REQUEST_ACTIVATION_FAILURE');

export const requestResetPasswordPending = createAction('AUTH_REQUEST_RESET_PASSWORD_PENDING');
export const requestResetPasswordSuccess = createAction('AUTH_REQUEST_RESET_PASSWORD_SUCCESS');
export const requestResetPasswordFailure = createAction('AUTH_REQUEST_RESET_PASSWORD_FAILURE');

// -----------------------------------------------------------------------------
// THUNKS
// -----------------------------------------------------------------------------
export const requestLogin = (params) => async (dispatch, getState, services) => {
    try {
        const response = await services[SERVICE_NAMES.AUTH].login(params);

        return { error: null, result: response };
    } catch (error) {
        return { error };
    }
};

export const requestRegistration = (params) => async (dispatch, getState, services) => {
    dispatch(requestRegistrationPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].register({ ...params });

        dispatch(requestRegistrationSuccess(response));

        return response;
    } catch (err) {
        dispatch(requestRegistrationFailure(err.message));
    }
};

export const requestLogout = (...args) => async (dispatch, getState, services) => {
    dispatch(requestLogoutPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].logout();

        if (response.status !== 200) {
            throw new Error(`Unexpected logout response status: ${response.status}`);
        }

        dispatch(requestLogoutSuccess(response));
        return response;
    } catch (err) {
        dispatch(requestLogoutFailure(err.message));
        window.location.reload();
    }
};

export const requestRefresh = () => async (dispatch, getState, services) => {
    dispatch(requestRefreshPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].refresh();
        dispatch(requestRefreshSuccess(response));
        return response;
    } catch (err) {
        dispatch(requestRefreshFailure(err.message));
        throw err;
    }
};

export const requestCheck = () => async (dispatch, getState, services) => {
    dispatch(requestCheckPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].check();
        dispatch(requestCheckSuccess(response));
        return response.isAuthenticated;
    } catch (err) {
        dispatch(requestCheckFailure(err.message));
        throw err;
    }
};

export const checkIsGrecaptchaReady = () => (dispatch, getState, services) =>
    services[SERVICE_NAMES.GRECAPTCHA] && services[SERVICE_NAMES.GRECAPTCHA].isReady;

export const executeGrecaptcha = () => async (dispatch, getState, services) => {
    try {
        if (!services[SERVICE_NAMES.GRECAPTCHA].isReady) {
            return { error: new Error('Grecaptcha service is not ready') };
        }

        const grecaptcha = await services[SERVICE_NAMES.GRECAPTCHA].execute({
            action: services[SERVICE_NAMES.GRECAPTCHA].ACTIONS.LOGIN
        });

        const addGrecaptchaToUrl = services[SERVICE_NAMES.GRECAPTCHA].addQueryParamsToUrl(
            grecaptcha.token,
            grecaptcha.action
        );

        return { error: null, result: addGrecaptchaToUrl };
    } catch (error) {
        return { error };
    }
};

export const requestActivation = (id) => async (dispatch, getState, services) => {
    dispatch(requestActivationPending());

    try {
        const response = await services[SERVICE_NAMES.AUTH].activate(id);

        if (response.status === RESPONSE_STATUSES.SUCCESS) {
            dispatch(requestActivationSuccess());
        } else {
            dispatch(requestActivationFailure());
        }

        return response;
    } catch (err) {
        dispatch(requestActivationFailure(err));
    }
};

export const requestResetPassword = (params) => async (dispatch, getState, services) => {
    dispatch(requestResetPasswordPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].resetPassword({ ...params });

        dispatch(requestResetPasswordSuccess(response));

        return response;
    } catch (err) {
        dispatch(requestResetPasswordFailure(err.message));
    }
};
