import { createReducer, combineReducers } from '@reduxjs/toolkit';
import initialState from '../initialState';
import * as actions from '../actions';
import { CONNECTION_STATUSES } from 'Constants';

const connectionsStatuses = combineReducers({
    manager: createReducer(initialState.connectionsStatuses.manager, {
        [actions.manager.WSConnectionConnecting]: () => CONNECTION_STATUSES.CONNECTING,
        [actions.manager.WSConnectionOpen]: () => CONNECTION_STATUSES.OPEN,
        [actions.manager.WSConnectionOnError]: () => CONNECTION_STATUSES.ON_ERROR,
        [actions.manager.WSConnectionClose]: () => CONNECTION_STATUSES.CLOSE,
        [actions.auth.requestLoginPending]: () => initialState.connectionsStatuses.manager
    })
});

export default connectionsStatuses;
