import { get, isUndefined } from 'lodash';
import WebSocketService from './WebSocketService';
import { realtalk } from '../../../submodules/protocol/lib/bundle.pb';
import {
    SERVICE_NAMES,
    MANAGER_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
    EVENTS,
    RESULT_CODES,
    EMPTY_STRING
} from 'Constants';

class ManagerWebSocketService extends WebSocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAMES.MANAGER,
            protocolVersion: MANAGER_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
            protocolEncoder: realtalk.manager.ClientMessage,
            protocolDecoder: realtalk.manager.ServerMessage,
            ...customOptions
        });

        if (!ManagerWebSocketService.instance) {
            ManagerWebSocketService.instance = this;
        }

        return ManagerWebSocketService.instance;
    }

    send = (method, data) => {
        const rawData = {
            id: ++this._lastRequestId,
            [method]: data
        };

        const preparedData = this._encoder.fromObject(rawData);

        if (!this._isValidInputData(rawData, preparedData) || !this._isValidRequest(preparedData)) {
            throw new Error(`${this._serviceName}: Send content is not valid`);
        }

        if (!this.isOpened) {
            this._logError('Connection not open!');
            throw new Error(`${this._serviceName}: Connection not open!`);
        }

        this._log('SEND:#%o:%o %O', preparedData.id, this._getRequestLogName(preparedData), preparedData);
        this._send(this._encoder.encode(preparedData).finish());
    };

    _onmessage = (evt) => {
        try {
            const message = this._decoder.decode(new Uint8Array(evt.data));
            const messageKey = message.body;

            this._log('MESSAGE:#%o:%o %O', message.id, this._getMessageLogName(message), message[messageKey]);

            const currentMessagePromise = this._promisesMap.get(message.id);
            this._promisesMap.delete(message.id);

            const messageHandled = this._decoder.toObject(message);
            const messageBody = messageHandled[messageKey];

            this.emit(EVENTS[this._name].MESSAGE, { id: messageHandled.id, body: messageBody });
            this.emit(messageKey, { id: messageHandled.id, body: messageBody });

            if (!currentMessagePromise) {
                return;
            }

            const messageCode = get(messageBody, 'code');
            if (
                messageKey === EVENTS.MANAGER.RESULT &&
                !isUndefined(messageCode) &&
                messageCode !== RESULT_CODES.SUCCESS
            ) {
                const messageCodeDescription = get(messageBody, 'description', EMPTY_STRING);
                currentMessagePromise.reject(new Error(messageCodeDescription));
                return;
            }

            currentMessagePromise.resolve({ id: messageHandled.id, body: messageBody });
        } catch (err) {
            this._onerror(err);
        }
    };

    get lastRequestId() {
        return this._lastRequestId;
    }
}

export default ManagerWebSocketService;
