import { createReducer } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import * as actions from '../actions';
import initialState from '../initialState';

const avatars = createReducer(initialState.avatars, {
    [actions.file.resetAvatar]: () => initialState.avatars,
    [actions.file.requestDownloadAvatarSuccess]: ({ allIds, byId }, { payload }) => {
        return {
            byId: { ...byId, [payload.id]: payload.response?.url },
            allIds: uniq(concat(allIds, payload.id))
        };
    }
});

export default avatars;
