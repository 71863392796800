import { put, getContext } from 'redux-saga/effects';
import { MANAGER_REQUESTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';

export function* getCurrentOrganization() {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.getCurrentOrganizationPending());
        const { body } = yield managerService.request(MANAGER_REQUESTS.GET_CURRENT_ORGANIZATION, {});
        yield put(actions.manager.getCurrentOrganizationSuccess(body));
    } catch (err) {
        yield put(actions.manager.getCurrentOrganizationFailure(err.message));
    }
}

export function* addOrganizationDomain({ payload }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.addOrganizationDomainPending());
        yield managerService.request(MANAGER_REQUESTS.ADD_ORGANIZATION_DOMAIN, payload);
        yield put(actions.manager.addOrganizationDomainSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.addOrganizationDomainFailure(err.message));
    }
}

export function* removeOrganizationDomain({ payload }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.removeOrganizationDomainPending());
        yield managerService.request(MANAGER_REQUESTS.REMOVE_ORGANIZATION_DOMAIN, payload);
        yield put(actions.manager.removeOrganizationDomainSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.removeOrganizationDomainFailure(err.message));
    }
}

export function* updateOrganization({ payload }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.updateOrganizationPending());
        yield managerService.request(MANAGER_REQUESTS.UPDATE_ORGANIZATION, payload);
        yield put(actions.manager.updateOrganizationSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.updateOrganizationFailure(err.message));
    }
}
