import { get, isUndefined, omitBy } from 'lodash';
import { RESPONSE_STATUSES, FILE_ACTION_TYPES, REQUEST_OPTIONS, getFileRoutes } from 'Constants';

import logger from 'Utils/logger';

const FILE_REQUEST_OPTIONS = {
    ...REQUEST_OPTIONS
};

const FILE_DOWNLOAD_RERQUEST_OPTIONS = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
};

const log = logger('FileService:');
const logError = logger('Error:FileService:');

const isSuccess = (responseStatus) => {
    const { SUCCESS } = RESPONSE_STATUSES;

    return responseStatus === SUCCESS;
};

const handleResponseErrors = (response) => {
    const { SUCCESS } = RESPONSE_STATUSES;
    const { status, statusText } = response;

    if (status !== SUCCESS) {
        const errorMessage = `type: FileError, status: ${status}, statusText: ${statusText}`;
        throw new Error(errorMessage);
    }

    return response;
};

const createUrlWithQueryParams = (baseUrlString, params = {}) => {
    const query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
    return baseUrlString + '?' + query;
};

class FileService {
    constructor(fileApiDomain = '', loader = fetch) {
        this._fetch = (...args) => loader(...args);
        this._getRoute = getFileRoutes(fileApiDomain);
    }

    upload({ file, appId, userId }) {
        let url = this._getRoute(FILE_ACTION_TYPES.UPLOAD);
        const queryParams = omitBy({ name: file.name, app_id: appId, user_id: userId }, isUndefined);
        url = createUrlWithQueryParams(url, queryParams);

        if (!url) {
            throw new Error('File Upload URL is invalid');
        }

        log('File Upload: url: %o', url);

        const options = {
            ...FILE_REQUEST_OPTIONS,
            body: file
        };

        return this._fetch(url, options)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;

                return response.json().then((body) => ({
                    status,
                    body
                }));
            })
            .then((response) => {
                const isFileUploaded = isSuccess(response.status);
                const fileId = get(response, 'body.file_id');

                if (isUndefined(fileId)) {
                    throw new Error('Failed to get file_id');
                }

                log('Got file upload response: isFileUploaded: %o', isFileUploaded);

                const info = {
                    action: FILE_ACTION_TYPES.UPLOAD,
                    status: response.status,
                    fileId,
                    isFileUploaded
                };

                return info;
            })
            .catch((err) => {
                logError('File upload error: %o', err.message);
                throw new Error(err);
            });
    }

    getUrl({ fileId, appId, userId }) {
        let url = this._getRoute(FILE_ACTION_TYPES.DOWNLOAD);
        const queryParams = omitBy({ file_id: fileId, app_id: appId, user_id: userId }, isUndefined);
        return createUrlWithQueryParams(url, queryParams);
    }

    download(fileId) {
        let url = this._getRoute(FILE_ACTION_TYPES.DOWNLOAD);
        const queryParams = omitBy({ file_id: fileId }, isUndefined);
        url = createUrlWithQueryParams(url, queryParams);

        if (!url) {
            throw new Error('File Download URL is invalid');
        }

        log('File Download: url: %o', url);

        return this._fetch(url, FILE_DOWNLOAD_RERQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isFileDownloaded = isSuccess(response.status);

                const info = {
                    action: FILE_ACTION_TYPES.DOWNLOAD,
                    status: response.status,
                    url: response.url,
                    isFileDownloaded
                };

                return info;
            })
            .catch((err) => {
                logError('File download error: %o', err.message);
                throw new Error(err);
            });
    }
}

export default FileService;
