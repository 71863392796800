import { getContext, fork, take, takeEvery, call, delay, takeLatest, cancel, all } from 'redux-saga/effects';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import getEventChannelFactory from 'Store/sagas/utils/getEventChannelFactory';
import WSEventsToActionsDispatch from 'Store/sagas/utils/WSEventsToActionsDispatch.saga';
import { serverHealthCheck } from 'Store/sagas/health';
import {
    getCurrentOperator,
    getOperatorList,
    createOperator,
    editProfile,
    removeOperator,
    editOperator
} from 'Store/sagas/manager/operators.saga';
import {
    getCurrentOrganization,
    addOrganizationDomain,
    removeOrganizationDomain,
    updateOrganization
} from 'Store/sagas/manager/organization.saga';
import { getChatList, handleChat, banChat } from 'Store/sagas/manager/chats.saga';
import {
    getMessageList,
    getMoreMessages,
    handleMessage,
    sendMessage,
    getNumberUnreadMessages,
    markMessageAsRead
} from 'Store/sagas/manager/messages.saga';
import logger from 'Utils/logger';

const log = logger('ManagerMainSaga:');
const logError = logger('Error:ManagerMainSaga:');

const eventsToActionsMap = [
    { event: EVENTS.MANAGER.OPEN, actionCreator: actions.manager.WSConnectionOpen },
    { event: EVENTS.MANAGER.CLOSE, actionCreator: actions.manager.WSConnectionClose },
    { event: EVENTS.MANAGER.ERROR, actionCreator: actions.manager.WSConnectionOnError }
];

export default function* managerSaga() {
    const managerService = yield getContext(SERVICE_NAMES.MANAGER);
    const createDefaultEventChannel = getEventChannelFactory(managerService);

    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, eventsToActionsMap);

    yield takeEvery(actions.setIsAuthenticated, openWSConnection, managerService);
    yield takeLatest(actions.auth.requestLogoutPending, closeWSConnection, managerService);

    yield takeLatest(actions.manager.WSConnectionOpen, runManagerTasks, managerService);
    yield takeLatest(actions.manager.WSConnectionClose, serverHealthCheck);

    yield takeEvery(actions.manager.getChatList, getChatList);

    yield takeEvery(actions.manager.getMessageList, getMessageList);
    yield takeEvery(actions.manager.getMoreMessages, getMoreMessages);
    yield takeLatest(actions.manager.sendMessage, sendMessage);

    yield takeLatest(actions.manager.createOperator, createOperator);
    yield takeEvery(actions.manager.getOperatorList, getOperatorList);
    yield takeLatest(actions.manager.editProfile, editProfile);
    yield takeLatest(actions.manager.getCurrentOperator, getCurrentOperator);
    yield takeLatest(actions.manager.addOrganizationDomain, addOrganizationDomain);
    yield takeLatest(actions.manager.getCurrentOrganization, getCurrentOrganization);
    yield takeLatest(actions.manager.removeOrganizationDomain, removeOrganizationDomain);
    yield takeLatest(actions.manager.removeOperator, removeOperator);
    yield takeLatest(actions.manager.editOperator, editOperator);
    yield takeLatest(actions.manager.banChat, banChat);
    yield takeLatest(actions.manager.updateOrganization, updateOrganization);
    yield takeLatest(actions.manager.markMessageAsRead, markMessageAsRead);
    yield takeEvery(actions.manager.getNumberUnreadMessages, getNumberUnreadMessages);
}

function* openWSConnection(managerService, { payload }) {
    if (payload !== true) {
        return;
    }

    if (managerService.isOpening || managerService.isOpened) {
        return;
    }

    try {
        yield call([managerService, managerService.connect]);
    } catch (err) {
        logError('runManagerService error: %O', err);
        // TODO add logic to up on error
        yield call([managerService, managerService.disconnect]);
        yield delay(3000);
        yield call([managerService, managerService.connect]);
    }
}

function* closeWSConnection(managerService) {
    try {
        yield call([managerService, managerService.disconnect]);
    } catch (err) {
        logError('Close Manager WS connection error: %O', err);
    }
}

function* runManagerTasks(managerService) {
    log('Manager WS: OPEN. Run tasks');
    try {
        const commonWSListenersTask = yield fork(commonWSListeners, managerService);

        const getInitDataTask = yield fork(function* () {
            yield getCurrentOperator();
            yield getCurrentOrganization();
        });

        yield fork(function* () {
            yield take(actions.manager.WSConnectionClose);
            log('Manager WS: CLOSE. Stop tasks.');

            yield cancel(commonWSListenersTask);
            yield cancel(getInitDataTask);
        });
    } catch (err) {
        logError('runManagerTasks error: %O', err);
        yield call([managerService, managerService.disconnect]);
        yield delay(3000);
        yield call([managerService, managerService.connect]);
    }
}

function* commonWSListeners(tradeService) {
    // event listeners will be removed from WebSocket handlers on the EVENTS.MANAGER.CLOSE event
    const createEventChannelWithClosing = getEventChannelFactory(tradeService, EVENTS.MANAGER.CLOSE);

    const [chatChannel, messageChannel] = yield all([
        call(createEventChannelWithClosing, EVENTS.MANAGER.CHAT),
        call(createEventChannelWithClosing, EVENTS.MANAGER.MANAGER_MESSAGE)
    ]);

    yield takeEvery(chatChannel, handleChat);
    yield takeEvery(messageChannel, handleMessage);
}
