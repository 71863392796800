import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const operators = createReducer(initialState.operators, {
    [actions.manager.getOperatorListSuccess]: (state, { payload: operators }) => {
        const allIds = operators.map((chat) => chat.id);
        const byId = keyBy(operators, (chat) => chat.id);
        return { byId, allIds };
    }
});

export default operators;
