import { createReducer } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const lastMessages = createReducer(initialState.lastMessages, {
    [actions.manager.getLastMessagesSuccess]: ({ allIds, byId }, { payload }) => {
        const { toChatId, text, timestamp, attachment } = payload;
        const attachmentFile = isUndefined(attachment) ? null : last(attachment);

        return {
            byId: { ...byId, [toChatId]: { text, timestamp, attachmentFile } },
            allIds: uniq(concat(toChatId, allIds))
        };
    },
    [actions.manager.gotLastMessage]: ({ allIds, byId }, { payload }) => {
        const { toChatId, text, timestamp, attachment } = payload;
        const attachmentFile = isUndefined(attachment) ? null : last(attachment);

        return {
            byId: { ...byId, [toChatId]: { text, timestamp, attachmentFile } },
            allIds: uniq(concat(toChatId, allIds))
        };
    }
});

export default lastMessages;
