import { all } from 'redux-saga/effects';

// root sagas
import authSaga from './auth';
import appSaga from './app';
import managerSaga from './manager';

export const rootSaga = function* () {
    yield all([authSaga(), managerSaga(), appSaga()]);
};

export default rootSaga;
