import { createAction } from '@reduxjs/toolkit';
import * as authActions from './auth';
import * as managerActions from './manager';
import * as fileActions from './file';

export const auth = authActions;
export const manager = managerActions;
export const file = fileActions;

export const setIsAuthenticated = createAction('SET_IS_AUTHENTICATED');
export const setIsMainMenuOpened = createAction('SET_IS_MAIN_MENU_OPENED');
export const setAppError = createAction('SET_APP_ERROR');
export const resetAppError = createAction('RESET_APP_ERROR');
// Actions for locales
export const setLocale = createAction('SET_LOCALE');
export const setLocaleInStore = createAction('SET_LOCALE_IN_STORE');
export const gotLocale = createAction('GOT_LOCALE');
export const setLocaleAndSendIt = createAction('SET_LOCALE_AND_SEND_IT');

// A special action used in the root reducer
export const resetStore = createAction('RESET_STORE');
