import { put, getContext, select } from 'redux-saga/effects';
import { get, first, last, take, takeRight, difference, isEmpty, sortBy } from 'lodash';
import * as actions from 'Store/actions';
import { MANAGER_REQUESTS, SERVICE_NAMES } from 'Constants';
import { isPendingRequestStatus, isEmptyRequestStatus } from 'Utils/statuses';
import logger from 'Utils/logger';

const logError = logger('Error:Saga:Messages:');

const DEFAULT_MESSAGE_LIST_LIMIT = 100;
const GET_MORE_MESSAGES_LIMIT = 50;

const prepareMessage = (message) => {
    const unfinished = get(message, 'unfinished', false);
    const unread = get(message, 'unread', false);

    return {
        ...message,
        unfinished,
        unread
    };
};

function* requestMessages(params, isGetLastMessage) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        const { messageAllIds, messageById } = yield select(
            ({ messages: { allIds: messageAllIds, byId: messageById } }) => ({
                messageAllIds,
                messageById
            })
        );

        const oldestMessageId = first(messageAllIds);
        const firstTimestampTo = get(messageById, `${oldestMessageId}.timestamp`);

        if (isGetLastMessage) {
            yield put(actions.manager.getLastMessagesPending());
        }

        yield put(actions.manager.getMessageListPending());

        const response = yield managerService.request(MANAGER_REQUESTS.GET_MESSAGE_LIST, params);
        const responseMessages = get(response, 'body.message', []);

        if (isEmpty(responseMessages)) {
            yield put(actions.manager.messageListIsEmpty());
            return;
        }

        const sortedMessages = sortBy(responseMessages, 'timestamp');
        const lastTimestampTo = get(last(sortedMessages), 'timestamp');

        const currentMessagesIdsHead = take(messageAllIds, GET_MORE_MESSAGES_LIMIT);
        const messagesIdsTail = takeRight(
            sortedMessages.map((n) => n.id),
            GET_MORE_MESSAGES_LIMIT
        );
        const hasNoNewMessages =
            !isEmpty(currentMessagesIdsHead) && isEmpty(difference(currentMessagesIdsHead, messagesIdsTail));

        if (lastTimestampTo > firstTimestampTo || hasNoNewMessages) {
            yield put(actions.manager.messageListIsEmpty());
            return;
        }

        const preparedMessages = sortedMessages.map(prepareMessage);

        if (isGetLastMessage) {
            yield put(actions.manager.getLastMessagesSuccess(last(preparedMessages)));
        }

        yield put(actions.manager.getMessageListSuccess(preparedMessages));
    } catch (err) {
        yield put(actions.manager.getMessageListFailure(err.message));
        if (isGetLastMessage) {
            yield put(actions.manager.getLastMessagesFailure());
        }
    }
}

export function* getMessageList({ payload }) {
    const { currentId, isGetLastMessage } = payload;
    try {
        yield put(actions.manager.resetMessages());
        yield requestMessages({ chatId: currentId, count: DEFAULT_MESSAGE_LIST_LIMIT }, isGetLastMessage);
    } catch (err) {
        yield put(actions.manager.getMessageListFailure(err.message));
    }
}

export function* getMoreMessages() {
    try {
        const { activeChatId, requestStatus, messageAllIds } = yield select(
            ({ activeChatId, requestStatuses: { messages: requestStatus }, messages: { allIds: messageAllIds } }) => ({
                activeChatId,
                requestStatus,
                messageAllIds
            })
        );

        if (isPendingRequestStatus(requestStatus) || isEmptyRequestStatus(requestStatus)) {
            return;
        }

        const lastMessageId = first(messageAllIds);

        yield requestMessages({ chatId: activeChatId, count: GET_MORE_MESSAGES_LIMIT, lastMessageId });
    } catch (err) {
        yield put(actions.manager.getMessageListFailure(err.message));
    }
}

export function* handleMessage({ body }) {
    try {
        const message = prepareMessage(body);
        yield getNumberUnreadMessages({ payload: message.toChatId });
        yield put(actions.manager.gotLastMessage(body));
        yield put(actions.manager.gotMessage(message));
    } catch (err) {
        logError(err.message);
    }
}

export function* sendMessage({ payload: message }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);

        yield put(actions.manager.sendMessagePending());
        yield managerService.request(MANAGER_REQUESTS.MESSAGE, message);
        yield put(actions.manager.sendMessageSuccess());
    } catch (err) {
        yield put(actions.manager.sendMessageFailure(err.message));
    }
}

export function* getNumberUnreadMessages({ payload }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.getNumberUnreadMessagesPending());
        const { body } = yield managerService.request(MANAGER_REQUESTS.GET_NUMBER_UNREAD_MESSAGES, { chatId: payload });
        yield put(actions.manager.getNumberUnreadMessagesSuccess(body));
    } catch (err) {
        yield put(actions.manager.getNumberUnreadMessagesFailure(err.message));
    }
}

export function* markMessageAsRead({ payload }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.markMessageAsReadPending());
        yield managerService.request(MANAGER_REQUESTS.MARK_MESSAGE_AS_READ, payload);
        yield put(actions.manager.markMessageAsReadSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.markMessageAsReadFailure(err.message));
    }
}
