import React from 'react';

const AppPreloader = () => {
    return (
        <div className='app-preloader'>
            <span className='app-preloader__dot'></span>
            <span className='app-preloader__dot'></span>
            <span className='app-preloader__dot'></span>
            <span className='app-preloader__dot'></span>
        </div>
    );
};

export default AppPreloader;
