import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const currentOperator = createReducer(initialState.currentOperator, {
    [actions.manager.getCurrentOperatorSuccess]: (state, { payload }) => {
        return payload;
    }
});

export default currentOperator;
