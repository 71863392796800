import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppPreloader from 'Components/AppPreloader';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
    // Temporarily disabled isAuthenticated check
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    // const isAuthenticated = true;

    // pre-load the authenticated side in the background while the user's
    // filling out the login form.
    useEffect(() => {
        loadAuthenticatedApp();
    }, []);

    return (
        <React.Suspense fallback={<AppPreloader />}>
            {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
    );
};

export default App;
