import debug from 'debug';
import { ENV_VARIABLES } from 'Constants';

const APP_NAME = 'real-talk';

const shouldInitDebug = ENV_VARIABLES.DEBUG !== 'false';

const logger = (namespace) => {
    return debug(`${APP_NAME}:${namespace}`);
};

export const init = () => {
    if (shouldInitDebug) {
        localStorage.debug = `${APP_NAME}:*`;
    } else {
        localStorage.debug = '';
    }
};

export default logger;
