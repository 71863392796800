import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from 'AppDir/i18n';
import SvgIcon, { ICON_NAMES } from 'Components/Tools/SvgIcon';
import { ReactComponent as ChatError } from 'Media/icons/chat-error.svg';
import { ERRORS, ROUTES } from 'Constants';
import { resetAppError } from 'Store/actions';
import isEmptyString from 'Utils/isEmptyString';

const getError = (name) => {
    switch (name) {
        case ERRORS.NOT_FOUND:
            return {
                code: 404,
                message: i18n.t('common:AppError.message.404'),
                link: {
                    url: ROUTES.ROOT,
                    iconName: ICON_NAMES.ARROW_LEFT,
                    text: i18n.t('common:AppError.link.toHome')
                }
            };
        case ERRORS.SERVER:
            return {
                code: 500,
                message: i18n.t('common:AppError.message.500'),
                link: {
                    url: '',
                    iconName: ICON_NAMES.ARROW_REFRESH,
                    text: i18n.t('common:AppError.link.reload')
                }
            };
        case ERRORS.FORBIDDEN:
            return {
                code: 403,
                message: i18n.t('common:AppError.message.403'),
                link: {
                    url: ROUTES.ROOT,
                    iconName: ICON_NAMES.ARROW_LEFT,
                    text: i18n.t('common:AppError.link.toHome')
                }
            };
        case ERRORS.UNKNOWN:
        default:
            return {
                code: '000',
                message: i18n.t('common:AppError.message.unknown'),
                link: {
                    url: '',
                    iconName: ICON_NAMES.ARROW_REFRESH,
                    text: i18n.t('common:AppError.link.reload')
                }
            };
    }
};

const onClickForceReload = (evt) => {
    evt.preventDefault();

    window.location.reload();
};

const LinkWrapper = (props) => {
    const { className, url } = props;
    const dispatch = useDispatch();
    const resetServerError = useCallback(() => dispatch(resetAppError()), [dispatch]);

    if (!isEmptyString(url.trim())) {
        return (
            <Link className={className} to={url} onClick={resetServerError}>
                {props.children}
            </Link>
        );
    }

    return (
        <button className={className} type='button' onClick={onClickForceReload}>
            {props.children}
        </button>
    );
};

const AppError = (props) => {
    const { name } = props;
    const {
        code,
        message,
        link: { url, iconName, text: linkText }
    } = getError(name);

    return (
        <div className='app-error'>
            <div className='app-error__inner'>
                <div className='app-error__columns'>
                    <div className='app-error__link-column'>
                        <div className='app-error__icon'>
                            <ChatError className='app-error__icon-svg svg-icon' />
                        </div>
                        <LinkWrapper className='app-error__return btn' errorName={name} url={url}>
                            <SvgIcon name={iconName} className='app-error__return-icon svg-icon' />
                            <span className='app-error__return-text'>{linkText}</span>
                        </LinkWrapper>
                    </div>
                    <div className='app-error__message-column'>
                        <p className='app-error__code'>{code}</p>
                        <p className='app-error__message' dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

AppError.propTypes = {
    name: PropTypes.string
};

export default AppError;
