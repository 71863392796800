const AUTH = {
    ERROR: 'AUTH:ERROR',
    LOGIN_REQUEST: 'AUTH:LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH:LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH:LOGIN_FAILURE',
    LOGOUT_REQUEST: 'AUTH:LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH:LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH:LOGOUT_FAILURE',
    REFRESH_REQUEST: 'AUTH:REFRESH_REQUEST',
    REFRESH_SUCCESS: 'AUTH:REFRESH_SUCCESS',
    REFRESH_FAILURE: 'AUTH:REFRESH_FAILURE',
    CHECK_REQUEST: 'AUTH:CHECK_REQUEST',
    CHECK_SUCCESS: 'AUTH:CHECK_SUCCESS',
    CHECK_FAILURE: 'AUTH:CHECK_FAILURE'
};

const MANAGER = {
    OPEN: 'MANAGER:OPEN',
    CLOSE: 'MANAGER:CLOSE',
    CONNECTING: 'MANAGER:CONNECTING',
    RECONNECTING: 'MANAGER:RECONNECTING',
    RECONNECT_NOT_ALLOWED: 'MANAGER:RECONNECT_NOT_ALLOWED',
    MESSAGE: 'MANAGER:MESSAGE',
    TIMEOUT: 'MANAGER:TIMEOUT',
    ERROR: 'MANAGER:ERROR',
    RESULT: 'result',
    OPERATOR: 'operator',
    OPERATOR_LIST: 'operatorList',
    MANAGER_MESSAGE: 'message',
    MANAGER_MESSAGE_LIST: 'messageList',
    CHAT: 'chat',
    CHAT_LIST: 'chatList',
    CHAT_USER: 'chatUser',
    LINE: 'line',
    LINE_LIST: 'lineList',
    MESSAGE_RATING: 'messageRating',
    OPERATOR_REPORT_LIST: 'operatorReportList',
    ORGANIZATION: 'organization'
};

const STORE = {
    RESET: 'STORE:RESET'
};

const WEB_SOCKET = {
    OPEN: 'open',
    CLOSE: 'close',
    CONNECTING: 'connecting',
    MESSAGE: 'message',
    ERROR: 'error'
};

const BROWSER = {
    MOUSEDOWN: 'mousedown',
    KEYDOWN: 'keydown'
};

export const EVENTS = {
    AUTH,
    STORE,
    WEB_SOCKET,
    BROWSER,
    MANAGER
};
