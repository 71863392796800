/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.realtalk = (function() {

    /**
     * Namespace realtalk.
     * @exports realtalk
     * @namespace
     */
    var realtalk = {};

    realtalk.manager = (function() {

        /**
         * Namespace manager.
         * @memberof realtalk
         * @namespace
         */
        var manager = {};

        manager.AddOperatorRoom = (function() {

            /**
             * Properties of an AddOperatorRoom.
             * @memberof realtalk.manager
             * @interface IAddOperatorRoom
             * @property {string|null} [name] AddOperatorRoom name
             */

            /**
             * Constructs a new AddOperatorRoom.
             * @memberof realtalk.manager
             * @classdesc Represents an AddOperatorRoom.
             * @implements IAddOperatorRoom
             * @constructor
             * @param {realtalk.manager.IAddOperatorRoom=} [properties] Properties to set
             */
            function AddOperatorRoom(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddOperatorRoom name.
             * @member {string} name
             * @memberof realtalk.manager.AddOperatorRoom
             * @instance
             */
            AddOperatorRoom.prototype.name = "";

            /**
             * Creates a new AddOperatorRoom instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {realtalk.manager.IAddOperatorRoom=} [properties] Properties to set
             * @returns {realtalk.manager.AddOperatorRoom} AddOperatorRoom instance
             */
            AddOperatorRoom.create = function create(properties) {
                return new AddOperatorRoom(properties);
            };

            /**
             * Encodes the specified AddOperatorRoom message. Does not implicitly {@link realtalk.manager.AddOperatorRoom.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {realtalk.manager.IAddOperatorRoom} message AddOperatorRoom message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddOperatorRoom.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                return writer;
            };

            /**
             * Decodes an AddOperatorRoom message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.AddOperatorRoom} AddOperatorRoom
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddOperatorRoom.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddOperatorRoom();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AddOperatorRoom message.
             * @function verify
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddOperatorRoom.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an AddOperatorRoom message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.AddOperatorRoom} AddOperatorRoom
             */
            AddOperatorRoom.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.AddOperatorRoom)
                    return object;
                var message = new $root.realtalk.manager.AddOperatorRoom();
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an AddOperatorRoom message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.AddOperatorRoom
             * @static
             * @param {realtalk.manager.AddOperatorRoom} message AddOperatorRoom
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddOperatorRoom.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.name = "";
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this AddOperatorRoom to JSON.
             * @function toJSON
             * @memberof realtalk.manager.AddOperatorRoom
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddOperatorRoom.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddOperatorRoom;
        })();

        manager.AddOrGetP2PChat = (function() {

            /**
             * Properties of an AddOrGetP2PChat.
             * @memberof realtalk.manager
             * @interface IAddOrGetP2PChat
             * @property {number|Long|null} [chatUserId] AddOrGetP2PChat chatUserId
             */

            /**
             * Constructs a new AddOrGetP2PChat.
             * @memberof realtalk.manager
             * @classdesc Represents an AddOrGetP2PChat.
             * @implements IAddOrGetP2PChat
             * @constructor
             * @param {realtalk.manager.IAddOrGetP2PChat=} [properties] Properties to set
             */
            function AddOrGetP2PChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddOrGetP2PChat chatUserId.
             * @member {number|Long} chatUserId
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @instance
             */
            AddOrGetP2PChat.prototype.chatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new AddOrGetP2PChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {realtalk.manager.IAddOrGetP2PChat=} [properties] Properties to set
             * @returns {realtalk.manager.AddOrGetP2PChat} AddOrGetP2PChat instance
             */
            AddOrGetP2PChat.create = function create(properties) {
                return new AddOrGetP2PChat(properties);
            };

            /**
             * Encodes the specified AddOrGetP2PChat message. Does not implicitly {@link realtalk.manager.AddOrGetP2PChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {realtalk.manager.IAddOrGetP2PChat} message AddOrGetP2PChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddOrGetP2PChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatUserId != null && Object.hasOwnProperty.call(message, "chatUserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatUserId);
                return writer;
            };

            /**
             * Decodes an AddOrGetP2PChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.AddOrGetP2PChat} AddOrGetP2PChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddOrGetP2PChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddOrGetP2PChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatUserId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AddOrGetP2PChat message.
             * @function verify
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddOrGetP2PChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (!$util.isInteger(message.chatUserId) && !(message.chatUserId && $util.isInteger(message.chatUserId.low) && $util.isInteger(message.chatUserId.high)))
                        return "chatUserId: integer|Long expected";
                return null;
            };

            /**
             * Creates an AddOrGetP2PChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.AddOrGetP2PChat} AddOrGetP2PChat
             */
            AddOrGetP2PChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.AddOrGetP2PChat)
                    return object;
                var message = new $root.realtalk.manager.AddOrGetP2PChat();
                if (object.chatUserId != null)
                    if ($util.Long)
                        (message.chatUserId = $util.Long.fromValue(object.chatUserId)).unsigned = true;
                    else if (typeof object.chatUserId === "string")
                        message.chatUserId = parseInt(object.chatUserId, 10);
                    else if (typeof object.chatUserId === "number")
                        message.chatUserId = object.chatUserId;
                    else if (typeof object.chatUserId === "object")
                        message.chatUserId = new $util.LongBits(object.chatUserId.low >>> 0, object.chatUserId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an AddOrGetP2PChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @static
             * @param {realtalk.manager.AddOrGetP2PChat} message AddOrGetP2PChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddOrGetP2PChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatUserId = options.longs === String ? "0" : 0;
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (typeof message.chatUserId === "number")
                        object.chatUserId = options.longs === String ? String(message.chatUserId) : message.chatUserId;
                    else
                        object.chatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId) : options.longs === Number ? new $util.LongBits(message.chatUserId.low >>> 0, message.chatUserId.high >>> 0).toNumber(true) : message.chatUserId;
                return object;
            };

            /**
             * Converts this AddOrGetP2PChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.AddOrGetP2PChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddOrGetP2PChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddOrGetP2PChat;
        })();

        manager.AddOrganizationDomain = (function() {

            /**
             * Properties of an AddOrganizationDomain.
             * @memberof realtalk.manager
             * @interface IAddOrganizationDomain
             * @property {string|null} [domain] AddOrganizationDomain domain
             */

            /**
             * Constructs a new AddOrganizationDomain.
             * @memberof realtalk.manager
             * @classdesc Represents an AddOrganizationDomain.
             * @implements IAddOrganizationDomain
             * @constructor
             * @param {realtalk.manager.IAddOrganizationDomain=} [properties] Properties to set
             */
            function AddOrganizationDomain(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddOrganizationDomain domain.
             * @member {string} domain
             * @memberof realtalk.manager.AddOrganizationDomain
             * @instance
             */
            AddOrganizationDomain.prototype.domain = "";

            /**
             * Creates a new AddOrganizationDomain instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {realtalk.manager.IAddOrganizationDomain=} [properties] Properties to set
             * @returns {realtalk.manager.AddOrganizationDomain} AddOrganizationDomain instance
             */
            AddOrganizationDomain.create = function create(properties) {
                return new AddOrganizationDomain(properties);
            };

            /**
             * Encodes the specified AddOrganizationDomain message. Does not implicitly {@link realtalk.manager.AddOrganizationDomain.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {realtalk.manager.IAddOrganizationDomain} message AddOrganizationDomain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddOrganizationDomain.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.domain);
                return writer;
            };

            /**
             * Decodes an AddOrganizationDomain message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.AddOrganizationDomain} AddOrganizationDomain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddOrganizationDomain.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddOrganizationDomain();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.domain = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AddOrganizationDomain message.
             * @function verify
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddOrganizationDomain.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    if (!$util.isString(message.domain))
                        return "domain: string expected";
                return null;
            };

            /**
             * Creates an AddOrganizationDomain message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.AddOrganizationDomain} AddOrganizationDomain
             */
            AddOrganizationDomain.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.AddOrganizationDomain)
                    return object;
                var message = new $root.realtalk.manager.AddOrganizationDomain();
                if (object.domain != null)
                    message.domain = String(object.domain);
                return message;
            };

            /**
             * Creates a plain object from an AddOrganizationDomain message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.AddOrganizationDomain
             * @static
             * @param {realtalk.manager.AddOrganizationDomain} message AddOrganizationDomain
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddOrganizationDomain.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.domain = "";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = message.domain;
                return object;
            };

            /**
             * Converts this AddOrganizationDomain to JSON.
             * @function toJSON
             * @memberof realtalk.manager.AddOrganizationDomain
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddOrganizationDomain.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddOrganizationDomain;
        })();

        manager.AddToChat = (function() {

            /**
             * Properties of an AddToChat.
             * @memberof realtalk.manager
             * @interface IAddToChat
             * @property {number|Long|null} [chatId] AddToChat chatId
             * @property {realtalk.manager.AddToChat.IByChatUserID|null} [byChatUserId] AddToChat byChatUserId
             * @property {realtalk.manager.AddToChat.IByExternalID|null} [byExternalId] AddToChat byExternalId
             */

            /**
             * Constructs a new AddToChat.
             * @memberof realtalk.manager
             * @classdesc Represents an AddToChat.
             * @implements IAddToChat
             * @constructor
             * @param {realtalk.manager.IAddToChat=} [properties] Properties to set
             */
            function AddToChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddToChat chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.AddToChat
             * @instance
             */
            AddToChat.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * AddToChat byChatUserId.
             * @member {realtalk.manager.AddToChat.IByChatUserID|null|undefined} byChatUserId
             * @memberof realtalk.manager.AddToChat
             * @instance
             */
            AddToChat.prototype.byChatUserId = null;

            /**
             * AddToChat byExternalId.
             * @member {realtalk.manager.AddToChat.IByExternalID|null|undefined} byExternalId
             * @memberof realtalk.manager.AddToChat
             * @instance
             */
            AddToChat.prototype.byExternalId = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * AddToChat type.
             * @member {"byChatUserId"|"byExternalId"|undefined} type
             * @memberof realtalk.manager.AddToChat
             * @instance
             */
            Object.defineProperty(AddToChat.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["byChatUserId", "byExternalId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AddToChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {realtalk.manager.IAddToChat=} [properties] Properties to set
             * @returns {realtalk.manager.AddToChat} AddToChat instance
             */
            AddToChat.create = function create(properties) {
                return new AddToChat(properties);
            };

            /**
             * Encodes the specified AddToChat message. Does not implicitly {@link realtalk.manager.AddToChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {realtalk.manager.IAddToChat} message AddToChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddToChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.byChatUserId != null && Object.hasOwnProperty.call(message, "byChatUserId"))
                    $root.realtalk.manager.AddToChat.ByChatUserID.encode(message.byChatUserId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.byExternalId != null && Object.hasOwnProperty.call(message, "byExternalId"))
                    $root.realtalk.manager.AddToChat.ByExternalID.encode(message.byExternalId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an AddToChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.AddToChat} AddToChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddToChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddToChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.byChatUserId = $root.realtalk.manager.AddToChat.ByChatUserID.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.byExternalId = $root.realtalk.manager.AddToChat.ByExternalID.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AddToChat message.
             * @function verify
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddToChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.byChatUserId != null && message.hasOwnProperty("byChatUserId")) {
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.AddToChat.ByChatUserID.verify(message.byChatUserId);
                        if (error)
                            return "byChatUserId." + error;
                    }
                }
                if (message.byExternalId != null && message.hasOwnProperty("byExternalId")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.AddToChat.ByExternalID.verify(message.byExternalId);
                        if (error)
                            return "byExternalId." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AddToChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.AddToChat} AddToChat
             */
            AddToChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.AddToChat)
                    return object;
                var message = new $root.realtalk.manager.AddToChat();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.byChatUserId != null) {
                    if (typeof object.byChatUserId !== "object")
                        throw TypeError(".realtalk.manager.AddToChat.byChatUserId: object expected");
                    message.byChatUserId = $root.realtalk.manager.AddToChat.ByChatUserID.fromObject(object.byChatUserId);
                }
                if (object.byExternalId != null) {
                    if (typeof object.byExternalId !== "object")
                        throw TypeError(".realtalk.manager.AddToChat.byExternalId: object expected");
                    message.byExternalId = $root.realtalk.manager.AddToChat.ByExternalID.fromObject(object.byExternalId);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddToChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.AddToChat
             * @static
             * @param {realtalk.manager.AddToChat} message AddToChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddToChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.byChatUserId != null && message.hasOwnProperty("byChatUserId")) {
                    object.byChatUserId = $root.realtalk.manager.AddToChat.ByChatUserID.toObject(message.byChatUserId, options);
                    if (options.oneofs)
                        object.type = "byChatUserId";
                }
                if (message.byExternalId != null && message.hasOwnProperty("byExternalId")) {
                    object.byExternalId = $root.realtalk.manager.AddToChat.ByExternalID.toObject(message.byExternalId, options);
                    if (options.oneofs)
                        object.type = "byExternalId";
                }
                return object;
            };

            /**
             * Converts this AddToChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.AddToChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddToChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            AddToChat.ByChatUserID = (function() {

                /**
                 * Properties of a ByChatUserID.
                 * @memberof realtalk.manager.AddToChat
                 * @interface IByChatUserID
                 * @property {Array.<number|Long>|null} [chatUserId] ByChatUserID chatUserId
                 */

                /**
                 * Constructs a new ByChatUserID.
                 * @memberof realtalk.manager.AddToChat
                 * @classdesc Represents a ByChatUserID.
                 * @implements IByChatUserID
                 * @constructor
                 * @param {realtalk.manager.AddToChat.IByChatUserID=} [properties] Properties to set
                 */
                function ByChatUserID(properties) {
                    this.chatUserId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByChatUserID chatUserId.
                 * @member {Array.<number|Long>} chatUserId
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @instance
                 */
                ByChatUserID.prototype.chatUserId = $util.emptyArray;

                /**
                 * Creates a new ByChatUserID instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {realtalk.manager.AddToChat.IByChatUserID=} [properties] Properties to set
                 * @returns {realtalk.manager.AddToChat.ByChatUserID} ByChatUserID instance
                 */
                ByChatUserID.create = function create(properties) {
                    return new ByChatUserID(properties);
                };

                /**
                 * Encodes the specified ByChatUserID message. Does not implicitly {@link realtalk.manager.AddToChat.ByChatUserID.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {realtalk.manager.AddToChat.IByChatUserID} message ByChatUserID message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByChatUserID.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.chatUserId != null && message.chatUserId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.chatUserId.length; ++i)
                            writer.uint64(message.chatUserId[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Decodes a ByChatUserID message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.AddToChat.ByChatUserID} ByChatUserID
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByChatUserID.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddToChat.ByChatUserID();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.chatUserId && message.chatUserId.length))
                                message.chatUserId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.chatUserId.push(reader.uint64());
                            } else
                                message.chatUserId.push(reader.uint64());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByChatUserID message.
                 * @function verify
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByChatUserID.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.chatUserId != null && message.hasOwnProperty("chatUserId")) {
                        if (!Array.isArray(message.chatUserId))
                            return "chatUserId: array expected";
                        for (var i = 0; i < message.chatUserId.length; ++i)
                            if (!$util.isInteger(message.chatUserId[i]) && !(message.chatUserId[i] && $util.isInteger(message.chatUserId[i].low) && $util.isInteger(message.chatUserId[i].high)))
                                return "chatUserId: integer|Long[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ByChatUserID message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.AddToChat.ByChatUserID} ByChatUserID
                 */
                ByChatUserID.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.AddToChat.ByChatUserID)
                        return object;
                    var message = new $root.realtalk.manager.AddToChat.ByChatUserID();
                    if (object.chatUserId) {
                        if (!Array.isArray(object.chatUserId))
                            throw TypeError(".realtalk.manager.AddToChat.ByChatUserID.chatUserId: array expected");
                        message.chatUserId = [];
                        for (var i = 0; i < object.chatUserId.length; ++i)
                            if ($util.Long)
                                (message.chatUserId[i] = $util.Long.fromValue(object.chatUserId[i])).unsigned = true;
                            else if (typeof object.chatUserId[i] === "string")
                                message.chatUserId[i] = parseInt(object.chatUserId[i], 10);
                            else if (typeof object.chatUserId[i] === "number")
                                message.chatUserId[i] = object.chatUserId[i];
                            else if (typeof object.chatUserId[i] === "object")
                                message.chatUserId[i] = new $util.LongBits(object.chatUserId[i].low >>> 0, object.chatUserId[i].high >>> 0).toNumber(true);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ByChatUserID message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @static
                 * @param {realtalk.manager.AddToChat.ByChatUserID} message ByChatUserID
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByChatUserID.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.chatUserId = [];
                    if (message.chatUserId && message.chatUserId.length) {
                        object.chatUserId = [];
                        for (var j = 0; j < message.chatUserId.length; ++j)
                            if (typeof message.chatUserId[j] === "number")
                                object.chatUserId[j] = options.longs === String ? String(message.chatUserId[j]) : message.chatUserId[j];
                            else
                                object.chatUserId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId[j]) : options.longs === Number ? new $util.LongBits(message.chatUserId[j].low >>> 0, message.chatUserId[j].high >>> 0).toNumber(true) : message.chatUserId[j];
                    }
                    return object;
                };

                /**
                 * Converts this ByChatUserID to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.AddToChat.ByChatUserID
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByChatUserID.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByChatUserID;
            })();

            AddToChat.ByExternalID = (function() {

                /**
                 * Properties of a ByExternalID.
                 * @memberof realtalk.manager.AddToChat
                 * @interface IByExternalID
                 * @property {Array.<string>|null} [externalId] ByExternalID externalId
                 * @property {boolean|null} [includingUnregistered] ByExternalID includingUnregistered
                 */

                /**
                 * Constructs a new ByExternalID.
                 * @memberof realtalk.manager.AddToChat
                 * @classdesc Represents a ByExternalID.
                 * @implements IByExternalID
                 * @constructor
                 * @param {realtalk.manager.AddToChat.IByExternalID=} [properties] Properties to set
                 */
                function ByExternalID(properties) {
                    this.externalId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByExternalID externalId.
                 * @member {Array.<string>} externalId
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @instance
                 */
                ByExternalID.prototype.externalId = $util.emptyArray;

                /**
                 * ByExternalID includingUnregistered.
                 * @member {boolean} includingUnregistered
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @instance
                 */
                ByExternalID.prototype.includingUnregistered = false;

                /**
                 * Creates a new ByExternalID instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {realtalk.manager.AddToChat.IByExternalID=} [properties] Properties to set
                 * @returns {realtalk.manager.AddToChat.ByExternalID} ByExternalID instance
                 */
                ByExternalID.create = function create(properties) {
                    return new ByExternalID(properties);
                };

                /**
                 * Encodes the specified ByExternalID message. Does not implicitly {@link realtalk.manager.AddToChat.ByExternalID.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {realtalk.manager.AddToChat.IByExternalID} message ByExternalID message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByExternalID.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.externalId != null && message.externalId.length)
                        for (var i = 0; i < message.externalId.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalId[i]);
                    if (message.includingUnregistered != null && Object.hasOwnProperty.call(message, "includingUnregistered"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includingUnregistered);
                    return writer;
                };

                /**
                 * Decodes a ByExternalID message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.AddToChat.ByExternalID} ByExternalID
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByExternalID.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddToChat.ByExternalID();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.externalId && message.externalId.length))
                                message.externalId = [];
                            message.externalId.push(reader.string());
                            break;
                        case 2:
                            message.includingUnregistered = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByExternalID message.
                 * @function verify
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByExternalID.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.externalId != null && message.hasOwnProperty("externalId")) {
                        if (!Array.isArray(message.externalId))
                            return "externalId: array expected";
                        for (var i = 0; i < message.externalId.length; ++i)
                            if (!$util.isString(message.externalId[i]))
                                return "externalId: string[] expected";
                    }
                    if (message.includingUnregistered != null && message.hasOwnProperty("includingUnregistered"))
                        if (typeof message.includingUnregistered !== "boolean")
                            return "includingUnregistered: boolean expected";
                    return null;
                };

                /**
                 * Creates a ByExternalID message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.AddToChat.ByExternalID} ByExternalID
                 */
                ByExternalID.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.AddToChat.ByExternalID)
                        return object;
                    var message = new $root.realtalk.manager.AddToChat.ByExternalID();
                    if (object.externalId) {
                        if (!Array.isArray(object.externalId))
                            throw TypeError(".realtalk.manager.AddToChat.ByExternalID.externalId: array expected");
                        message.externalId = [];
                        for (var i = 0; i < object.externalId.length; ++i)
                            message.externalId[i] = String(object.externalId[i]);
                    }
                    if (object.includingUnregistered != null)
                        message.includingUnregistered = Boolean(object.includingUnregistered);
                    return message;
                };

                /**
                 * Creates a plain object from a ByExternalID message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @static
                 * @param {realtalk.manager.AddToChat.ByExternalID} message ByExternalID
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByExternalID.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.externalId = [];
                    if (options.defaults)
                        object.includingUnregistered = false;
                    if (message.externalId && message.externalId.length) {
                        object.externalId = [];
                        for (var j = 0; j < message.externalId.length; ++j)
                            object.externalId[j] = message.externalId[j];
                    }
                    if (message.includingUnregistered != null && message.hasOwnProperty("includingUnregistered"))
                        object.includingUnregistered = message.includingUnregistered;
                    return object;
                };

                /**
                 * Converts this ByExternalID to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.AddToChat.ByExternalID
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByExternalID.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByExternalID;
            })();

            return AddToChat;
        })();

        manager.AddToLine = (function() {

            /**
             * Properties of an AddToLine.
             * @memberof realtalk.manager
             * @interface IAddToLine
             * @property {number|Long|null} [operatorId] AddToLine operatorId
             * @property {number|Long|null} [position] AddToLine position
             */

            /**
             * Constructs a new AddToLine.
             * @memberof realtalk.manager
             * @classdesc Represents an AddToLine.
             * @implements IAddToLine
             * @constructor
             * @param {realtalk.manager.IAddToLine=} [properties] Properties to set
             */
            function AddToLine(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddToLine operatorId.
             * @member {number|Long} operatorId
             * @memberof realtalk.manager.AddToLine
             * @instance
             */
            AddToLine.prototype.operatorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * AddToLine position.
             * @member {number|Long} position
             * @memberof realtalk.manager.AddToLine
             * @instance
             */
            AddToLine.prototype.position = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new AddToLine instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {realtalk.manager.IAddToLine=} [properties] Properties to set
             * @returns {realtalk.manager.AddToLine} AddToLine instance
             */
            AddToLine.create = function create(properties) {
                return new AddToLine(properties);
            };

            /**
             * Encodes the specified AddToLine message. Does not implicitly {@link realtalk.manager.AddToLine.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {realtalk.manager.IAddToLine} message AddToLine message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddToLine.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operatorId != null && Object.hasOwnProperty.call(message, "operatorId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorId);
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.position);
                return writer;
            };

            /**
             * Decodes an AddToLine message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.AddToLine} AddToLine
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddToLine.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.AddToLine();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.operatorId = reader.uint64();
                        break;
                    case 2:
                        message.position = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AddToLine message.
             * @function verify
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddToLine.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (!$util.isInteger(message.operatorId) && !(message.operatorId && $util.isInteger(message.operatorId.low) && $util.isInteger(message.operatorId.high)))
                        return "operatorId: integer|Long expected";
                if (message.position != null && message.hasOwnProperty("position"))
                    if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                        return "position: integer|Long expected";
                return null;
            };

            /**
             * Creates an AddToLine message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.AddToLine} AddToLine
             */
            AddToLine.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.AddToLine)
                    return object;
                var message = new $root.realtalk.manager.AddToLine();
                if (object.operatorId != null)
                    if ($util.Long)
                        (message.operatorId = $util.Long.fromValue(object.operatorId)).unsigned = true;
                    else if (typeof object.operatorId === "string")
                        message.operatorId = parseInt(object.operatorId, 10);
                    else if (typeof object.operatorId === "number")
                        message.operatorId = object.operatorId;
                    else if (typeof object.operatorId === "object")
                        message.operatorId = new $util.LongBits(object.operatorId.low >>> 0, object.operatorId.high >>> 0).toNumber(true);
                if (object.position != null)
                    if ($util.Long)
                        (message.position = $util.Long.fromValue(object.position)).unsigned = true;
                    else if (typeof object.position === "string")
                        message.position = parseInt(object.position, 10);
                    else if (typeof object.position === "number")
                        message.position = object.position;
                    else if (typeof object.position === "object")
                        message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an AddToLine message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.AddToLine
             * @static
             * @param {realtalk.manager.AddToLine} message AddToLine
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddToLine.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.operatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.operatorId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.position = options.longs === String ? "0" : 0;
                }
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (typeof message.operatorId === "number")
                        object.operatorId = options.longs === String ? String(message.operatorId) : message.operatorId;
                    else
                        object.operatorId = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId) : options.longs === Number ? new $util.LongBits(message.operatorId.low >>> 0, message.operatorId.high >>> 0).toNumber(true) : message.operatorId;
                if (message.position != null && message.hasOwnProperty("position"))
                    if (typeof message.position === "number")
                        object.position = options.longs === String ? String(message.position) : message.position;
                    else
                        object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber(true) : message.position;
                return object;
            };

            /**
             * Converts this AddToLine to JSON.
             * @function toJSON
             * @memberof realtalk.manager.AddToLine
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddToLine.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddToLine;
        })();

        manager.Attachment = (function() {

            /**
             * Properties of an Attachment.
             * @memberof realtalk.manager
             * @interface IAttachment
             * @property {string|null} [id] Attachment id
             * @property {string|null} [name] Attachment name
             */

            /**
             * Constructs a new Attachment.
             * @memberof realtalk.manager
             * @classdesc Represents an Attachment.
             * @implements IAttachment
             * @constructor
             * @param {realtalk.manager.IAttachment=} [properties] Properties to set
             */
            function Attachment(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Attachment id.
             * @member {string} id
             * @memberof realtalk.manager.Attachment
             * @instance
             */
            Attachment.prototype.id = "";

            /**
             * Attachment name.
             * @member {string} name
             * @memberof realtalk.manager.Attachment
             * @instance
             */
            Attachment.prototype.name = "";

            /**
             * Creates a new Attachment instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {realtalk.manager.IAttachment=} [properties] Properties to set
             * @returns {realtalk.manager.Attachment} Attachment instance
             */
            Attachment.create = function create(properties) {
                return new Attachment(properties);
            };

            /**
             * Encodes the specified Attachment message. Does not implicitly {@link realtalk.manager.Attachment.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {realtalk.manager.IAttachment} message Attachment message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Attachment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an Attachment message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Attachment} Attachment
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Attachment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Attachment();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Attachment message.
             * @function verify
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Attachment.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an Attachment message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Attachment} Attachment
             */
            Attachment.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Attachment)
                    return object;
                var message = new $root.realtalk.manager.Attachment();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an Attachment message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Attachment
             * @static
             * @param {realtalk.manager.Attachment} message Attachment
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Attachment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this Attachment to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Attachment
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Attachment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Attachment;
        })();

        manager.BanChat = (function() {

            /**
             * Properties of a BanChat.
             * @memberof realtalk.manager
             * @interface IBanChat
             * @property {number|Long|null} [chatId] BanChat chatId
             */

            /**
             * Constructs a new BanChat.
             * @memberof realtalk.manager
             * @classdesc Represents a BanChat.
             * @implements IBanChat
             * @constructor
             * @param {realtalk.manager.IBanChat=} [properties] Properties to set
             */
            function BanChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BanChat chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.BanChat
             * @instance
             */
            BanChat.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new BanChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {realtalk.manager.IBanChat=} [properties] Properties to set
             * @returns {realtalk.manager.BanChat} BanChat instance
             */
            BanChat.create = function create(properties) {
                return new BanChat(properties);
            };

            /**
             * Encodes the specified BanChat message. Does not implicitly {@link realtalk.manager.BanChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {realtalk.manager.IBanChat} message BanChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BanChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a BanChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.BanChat} BanChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BanChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.BanChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a BanChat message.
             * @function verify
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BanChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BanChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.BanChat} BanChat
             */
            BanChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.BanChat)
                    return object;
                var message = new $root.realtalk.manager.BanChat();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a BanChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.BanChat
             * @static
             * @param {realtalk.manager.BanChat} message BanChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BanChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this BanChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.BanChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BanChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BanChat;
        })();

        manager.ChatUser = (function() {

            /**
             * Properties of a ChatUser.
             * @memberof realtalk.manager
             * @interface IChatUser
             * @property {number|Long|null} [id] ChatUser id
             * @property {string|null} [name] ChatUser name
             * @property {string|null} [avatar] ChatUser avatar
             */

            /**
             * Constructs a new ChatUser.
             * @memberof realtalk.manager
             * @classdesc Represents a ChatUser.
             * @implements IChatUser
             * @constructor
             * @param {realtalk.manager.IChatUser=} [properties] Properties to set
             */
            function ChatUser(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatUser id.
             * @member {number|Long} id
             * @memberof realtalk.manager.ChatUser
             * @instance
             */
            ChatUser.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChatUser name.
             * @member {string} name
             * @memberof realtalk.manager.ChatUser
             * @instance
             */
            ChatUser.prototype.name = "";

            /**
             * ChatUser avatar.
             * @member {string} avatar
             * @memberof realtalk.manager.ChatUser
             * @instance
             */
            ChatUser.prototype.avatar = "";

            /**
             * Creates a new ChatUser instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {realtalk.manager.IChatUser=} [properties] Properties to set
             * @returns {realtalk.manager.ChatUser} ChatUser instance
             */
            ChatUser.create = function create(properties) {
                return new ChatUser(properties);
            };

            /**
             * Encodes the specified ChatUser message. Does not implicitly {@link realtalk.manager.ChatUser.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {realtalk.manager.IChatUser} message ChatUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.avatar);
                return writer;
            };

            /**
             * Decodes a ChatUser message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.ChatUser} ChatUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.ChatUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.avatar = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChatUser message.
             * @function verify
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                return null;
            };

            /**
             * Creates a ChatUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.ChatUser} ChatUser
             */
            ChatUser.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.ChatUser)
                    return object;
                var message = new $root.realtalk.manager.ChatUser();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                return message;
            };

            /**
             * Creates a plain object from a ChatUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.ChatUser
             * @static
             * @param {realtalk.manager.ChatUser} message ChatUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.avatar = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                return object;
            };

            /**
             * Converts this ChatUser to JSON.
             * @function toJSON
             * @memberof realtalk.manager.ChatUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChatUser;
        })();

        manager.Chat = (function() {

            /**
             * Properties of a Chat.
             * @memberof realtalk.manager
             * @interface IChat
             * @property {number|Long|null} [id] Chat id
             * @property {realtalk.manager.Chat.IP2P|null} [p2p] Chat p2p
             * @property {realtalk.manager.Chat.IOperatorRoom|null} [operatorRoom] Chat operatorRoom
             * @property {realtalk.manager.Chat.IClient|null} [client] Chat client
             */

            /**
             * Constructs a new Chat.
             * @memberof realtalk.manager
             * @classdesc Represents a Chat.
             * @implements IChat
             * @constructor
             * @param {realtalk.manager.IChat=} [properties] Properties to set
             */
            function Chat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Chat id.
             * @member {number|Long} id
             * @memberof realtalk.manager.Chat
             * @instance
             */
            Chat.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Chat p2p.
             * @member {realtalk.manager.Chat.IP2P|null|undefined} p2p
             * @memberof realtalk.manager.Chat
             * @instance
             */
            Chat.prototype.p2p = null;

            /**
             * Chat operatorRoom.
             * @member {realtalk.manager.Chat.IOperatorRoom|null|undefined} operatorRoom
             * @memberof realtalk.manager.Chat
             * @instance
             */
            Chat.prototype.operatorRoom = null;

            /**
             * Chat client.
             * @member {realtalk.manager.Chat.IClient|null|undefined} client
             * @memberof realtalk.manager.Chat
             * @instance
             */
            Chat.prototype.client = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Chat type.
             * @member {"p2p"|"operatorRoom"|"client"|undefined} type
             * @memberof realtalk.manager.Chat
             * @instance
             */
            Object.defineProperty(Chat.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["p2p", "operatorRoom", "client"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Chat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Chat
             * @static
             * @param {realtalk.manager.IChat=} [properties] Properties to set
             * @returns {realtalk.manager.Chat} Chat instance
             */
            Chat.create = function create(properties) {
                return new Chat(properties);
            };

            /**
             * Encodes the specified Chat message. Does not implicitly {@link realtalk.manager.Chat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Chat
             * @static
             * @param {realtalk.manager.IChat} message Chat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Chat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.p2p != null && Object.hasOwnProperty.call(message, "p2p"))
                    $root.realtalk.manager.Chat.P2P.encode(message.p2p, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.operatorRoom != null && Object.hasOwnProperty.call(message, "operatorRoom"))
                    $root.realtalk.manager.Chat.OperatorRoom.encode(message.operatorRoom, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.client != null && Object.hasOwnProperty.call(message, "client"))
                    $root.realtalk.manager.Chat.Client.encode(message.client, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Chat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Chat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Chat} Chat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Chat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Chat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.p2p = $root.realtalk.manager.Chat.P2P.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.operatorRoom = $root.realtalk.manager.Chat.OperatorRoom.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.client = $root.realtalk.manager.Chat.Client.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Chat message.
             * @function verify
             * @memberof realtalk.manager.Chat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Chat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.p2p != null && message.hasOwnProperty("p2p")) {
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.Chat.P2P.verify(message.p2p);
                        if (error)
                            return "p2p." + error;
                    }
                }
                if (message.operatorRoom != null && message.hasOwnProperty("operatorRoom")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.Chat.OperatorRoom.verify(message.operatorRoom);
                        if (error)
                            return "operatorRoom." + error;
                    }
                }
                if (message.client != null && message.hasOwnProperty("client")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.Chat.Client.verify(message.client);
                        if (error)
                            return "client." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Chat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Chat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Chat} Chat
             */
            Chat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Chat)
                    return object;
                var message = new $root.realtalk.manager.Chat();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.p2p != null) {
                    if (typeof object.p2p !== "object")
                        throw TypeError(".realtalk.manager.Chat.p2p: object expected");
                    message.p2p = $root.realtalk.manager.Chat.P2P.fromObject(object.p2p);
                }
                if (object.operatorRoom != null) {
                    if (typeof object.operatorRoom !== "object")
                        throw TypeError(".realtalk.manager.Chat.operatorRoom: object expected");
                    message.operatorRoom = $root.realtalk.manager.Chat.OperatorRoom.fromObject(object.operatorRoom);
                }
                if (object.client != null) {
                    if (typeof object.client !== "object")
                        throw TypeError(".realtalk.manager.Chat.client: object expected");
                    message.client = $root.realtalk.manager.Chat.Client.fromObject(object.client);
                }
                return message;
            };

            /**
             * Creates a plain object from a Chat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Chat
             * @static
             * @param {realtalk.manager.Chat} message Chat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Chat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.p2p != null && message.hasOwnProperty("p2p")) {
                    object.p2p = $root.realtalk.manager.Chat.P2P.toObject(message.p2p, options);
                    if (options.oneofs)
                        object.type = "p2p";
                }
                if (message.operatorRoom != null && message.hasOwnProperty("operatorRoom")) {
                    object.operatorRoom = $root.realtalk.manager.Chat.OperatorRoom.toObject(message.operatorRoom, options);
                    if (options.oneofs)
                        object.type = "operatorRoom";
                }
                if (message.client != null && message.hasOwnProperty("client")) {
                    object.client = $root.realtalk.manager.Chat.Client.toObject(message.client, options);
                    if (options.oneofs)
                        object.type = "client";
                }
                return object;
            };

            /**
             * Converts this Chat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Chat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Chat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Chat.P2P = (function() {

                /**
                 * Properties of a P2P.
                 * @memberof realtalk.manager.Chat
                 * @interface IP2P
                 * @property {number|Long|null} [ownerId] P2P ownerId
                 * @property {boolean|null} [visible] P2P visible
                 */

                /**
                 * Constructs a new P2P.
                 * @memberof realtalk.manager.Chat
                 * @classdesc Represents a P2P.
                 * @implements IP2P
                 * @constructor
                 * @param {realtalk.manager.Chat.IP2P=} [properties] Properties to set
                 */
                function P2P(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * P2P ownerId.
                 * @member {number|Long} ownerId
                 * @memberof realtalk.manager.Chat.P2P
                 * @instance
                 */
                P2P.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * P2P visible.
                 * @member {boolean} visible
                 * @memberof realtalk.manager.Chat.P2P
                 * @instance
                 */
                P2P.prototype.visible = false;

                /**
                 * Creates a new P2P instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {realtalk.manager.Chat.IP2P=} [properties] Properties to set
                 * @returns {realtalk.manager.Chat.P2P} P2P instance
                 */
                P2P.create = function create(properties) {
                    return new P2P(properties);
                };

                /**
                 * Encodes the specified P2P message. Does not implicitly {@link realtalk.manager.Chat.P2P.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {realtalk.manager.Chat.IP2P} message P2P message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                P2P.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ownerId);
                    if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.visible);
                    return writer;
                };

                /**
                 * Decodes a P2P message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.Chat.P2P} P2P
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                P2P.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Chat.P2P();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ownerId = reader.uint64();
                            break;
                        case 2:
                            message.visible = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a P2P message.
                 * @function verify
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                P2P.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                            return "ownerId: integer|Long expected";
                    if (message.visible != null && message.hasOwnProperty("visible"))
                        if (typeof message.visible !== "boolean")
                            return "visible: boolean expected";
                    return null;
                };

                /**
                 * Creates a P2P message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.Chat.P2P} P2P
                 */
                P2P.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.Chat.P2P)
                        return object;
                    var message = new $root.realtalk.manager.Chat.P2P();
                    if (object.ownerId != null)
                        if ($util.Long)
                            (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = true;
                        else if (typeof object.ownerId === "string")
                            message.ownerId = parseInt(object.ownerId, 10);
                        else if (typeof object.ownerId === "number")
                            message.ownerId = object.ownerId;
                        else if (typeof object.ownerId === "object")
                            message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber(true);
                    if (object.visible != null)
                        message.visible = Boolean(object.visible);
                    return message;
                };

                /**
                 * Creates a plain object from a P2P message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.Chat.P2P
                 * @static
                 * @param {realtalk.manager.Chat.P2P} message P2P
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                P2P.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.ownerId = options.longs === String ? "0" : 0;
                        object.visible = false;
                    }
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (typeof message.ownerId === "number")
                            object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                        else
                            object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber(true) : message.ownerId;
                    if (message.visible != null && message.hasOwnProperty("visible"))
                        object.visible = message.visible;
                    return object;
                };

                /**
                 * Converts this P2P to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.Chat.P2P
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                P2P.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return P2P;
            })();

            Chat.OperatorRoom = (function() {

                /**
                 * Properties of an OperatorRoom.
                 * @memberof realtalk.manager.Chat
                 * @interface IOperatorRoom
                 * @property {number|Long|null} [ownerId] OperatorRoom ownerId
                 * @property {string|null} [name] OperatorRoom name
                 * @property {Array.<number|Long>|null} [operatorId] OperatorRoom operatorId
                 * @property {boolean|null} [deleted] OperatorRoom deleted
                 */

                /**
                 * Constructs a new OperatorRoom.
                 * @memberof realtalk.manager.Chat
                 * @classdesc Represents an OperatorRoom.
                 * @implements IOperatorRoom
                 * @constructor
                 * @param {realtalk.manager.Chat.IOperatorRoom=} [properties] Properties to set
                 */
                function OperatorRoom(properties) {
                    this.operatorId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OperatorRoom ownerId.
                 * @member {number|Long} ownerId
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @instance
                 */
                OperatorRoom.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * OperatorRoom name.
                 * @member {string} name
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @instance
                 */
                OperatorRoom.prototype.name = "";

                /**
                 * OperatorRoom operatorId.
                 * @member {Array.<number|Long>} operatorId
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @instance
                 */
                OperatorRoom.prototype.operatorId = $util.emptyArray;

                /**
                 * OperatorRoom deleted.
                 * @member {boolean} deleted
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @instance
                 */
                OperatorRoom.prototype.deleted = false;

                /**
                 * Creates a new OperatorRoom instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {realtalk.manager.Chat.IOperatorRoom=} [properties] Properties to set
                 * @returns {realtalk.manager.Chat.OperatorRoom} OperatorRoom instance
                 */
                OperatorRoom.create = function create(properties) {
                    return new OperatorRoom(properties);
                };

                /**
                 * Encodes the specified OperatorRoom message. Does not implicitly {@link realtalk.manager.Chat.OperatorRoom.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {realtalk.manager.Chat.IOperatorRoom} message OperatorRoom message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorRoom.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ownerId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.operatorId != null && message.operatorId.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.operatorId.length; ++i)
                            writer.uint64(message.operatorId[i]);
                        writer.ldelim();
                    }
                    if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.deleted);
                    return writer;
                };

                /**
                 * Decodes an OperatorRoom message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.Chat.OperatorRoom} OperatorRoom
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorRoom.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Chat.OperatorRoom();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ownerId = reader.uint64();
                            break;
                        case 2:
                            message.name = reader.string();
                            break;
                        case 3:
                            if (!(message.operatorId && message.operatorId.length))
                                message.operatorId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.operatorId.push(reader.uint64());
                            } else
                                message.operatorId.push(reader.uint64());
                            break;
                        case 4:
                            message.deleted = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an OperatorRoom message.
                 * @function verify
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorRoom.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                            return "ownerId: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.operatorId != null && message.hasOwnProperty("operatorId")) {
                        if (!Array.isArray(message.operatorId))
                            return "operatorId: array expected";
                        for (var i = 0; i < message.operatorId.length; ++i)
                            if (!$util.isInteger(message.operatorId[i]) && !(message.operatorId[i] && $util.isInteger(message.operatorId[i].low) && $util.isInteger(message.operatorId[i].high)))
                                return "operatorId: integer|Long[] expected";
                    }
                    if (message.deleted != null && message.hasOwnProperty("deleted"))
                        if (typeof message.deleted !== "boolean")
                            return "deleted: boolean expected";
                    return null;
                };

                /**
                 * Creates an OperatorRoom message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.Chat.OperatorRoom} OperatorRoom
                 */
                OperatorRoom.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.Chat.OperatorRoom)
                        return object;
                    var message = new $root.realtalk.manager.Chat.OperatorRoom();
                    if (object.ownerId != null)
                        if ($util.Long)
                            (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = true;
                        else if (typeof object.ownerId === "string")
                            message.ownerId = parseInt(object.ownerId, 10);
                        else if (typeof object.ownerId === "number")
                            message.ownerId = object.ownerId;
                        else if (typeof object.ownerId === "object")
                            message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber(true);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.operatorId) {
                        if (!Array.isArray(object.operatorId))
                            throw TypeError(".realtalk.manager.Chat.OperatorRoom.operatorId: array expected");
                        message.operatorId = [];
                        for (var i = 0; i < object.operatorId.length; ++i)
                            if ($util.Long)
                                (message.operatorId[i] = $util.Long.fromValue(object.operatorId[i])).unsigned = true;
                            else if (typeof object.operatorId[i] === "string")
                                message.operatorId[i] = parseInt(object.operatorId[i], 10);
                            else if (typeof object.operatorId[i] === "number")
                                message.operatorId[i] = object.operatorId[i];
                            else if (typeof object.operatorId[i] === "object")
                                message.operatorId[i] = new $util.LongBits(object.operatorId[i].low >>> 0, object.operatorId[i].high >>> 0).toNumber(true);
                    }
                    if (object.deleted != null)
                        message.deleted = Boolean(object.deleted);
                    return message;
                };

                /**
                 * Creates a plain object from an OperatorRoom message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @static
                 * @param {realtalk.manager.Chat.OperatorRoom} message OperatorRoom
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorRoom.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.ownerId = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.deleted = false;
                    }
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (typeof message.ownerId === "number")
                            object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                        else
                            object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber(true) : message.ownerId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.operatorId && message.operatorId.length) {
                        object.operatorId = [];
                        for (var j = 0; j < message.operatorId.length; ++j)
                            if (typeof message.operatorId[j] === "number")
                                object.operatorId[j] = options.longs === String ? String(message.operatorId[j]) : message.operatorId[j];
                            else
                                object.operatorId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId[j]) : options.longs === Number ? new $util.LongBits(message.operatorId[j].low >>> 0, message.operatorId[j].high >>> 0).toNumber(true) : message.operatorId[j];
                    }
                    if (message.deleted != null && message.hasOwnProperty("deleted"))
                        object.deleted = message.deleted;
                    return object;
                };

                /**
                 * Converts this OperatorRoom to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.Chat.OperatorRoom
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorRoom.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OperatorRoom;
            })();

            Chat.Client = (function() {

                /**
                 * Properties of a Client.
                 * @memberof realtalk.manager.Chat
                 * @interface IClient
                 * @property {number|Long|null} [ownerId] Client ownerId
                 * @property {Array.<number|Long>|null} [chatUserId] Client chatUserId
                 * @property {number|Long|null} [lastClosedIssueMessageId] Client lastClosedIssueMessageId
                 * @property {number|Long|null} [assigneeChatUserId] Client assigneeChatUserId
                 */

                /**
                 * Constructs a new Client.
                 * @memberof realtalk.manager.Chat
                 * @classdesc Represents a Client.
                 * @implements IClient
                 * @constructor
                 * @param {realtalk.manager.Chat.IClient=} [properties] Properties to set
                 */
                function Client(properties) {
                    this.chatUserId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Client ownerId.
                 * @member {number|Long} ownerId
                 * @memberof realtalk.manager.Chat.Client
                 * @instance
                 */
                Client.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Client chatUserId.
                 * @member {Array.<number|Long>} chatUserId
                 * @memberof realtalk.manager.Chat.Client
                 * @instance
                 */
                Client.prototype.chatUserId = $util.emptyArray;

                /**
                 * Client lastClosedIssueMessageId.
                 * @member {number|Long} lastClosedIssueMessageId
                 * @memberof realtalk.manager.Chat.Client
                 * @instance
                 */
                Client.prototype.lastClosedIssueMessageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Client assigneeChatUserId.
                 * @member {number|Long} assigneeChatUserId
                 * @memberof realtalk.manager.Chat.Client
                 * @instance
                 */
                Client.prototype.assigneeChatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new Client instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {realtalk.manager.Chat.IClient=} [properties] Properties to set
                 * @returns {realtalk.manager.Chat.Client} Client instance
                 */
                Client.create = function create(properties) {
                    return new Client(properties);
                };

                /**
                 * Encodes the specified Client message. Does not implicitly {@link realtalk.manager.Chat.Client.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {realtalk.manager.Chat.IClient} message Client message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Client.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ownerId);
                    if (message.chatUserId != null && message.chatUserId.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.chatUserId.length; ++i)
                            writer.uint64(message.chatUserId[i]);
                        writer.ldelim();
                    }
                    if (message.lastClosedIssueMessageId != null && Object.hasOwnProperty.call(message, "lastClosedIssueMessageId"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.lastClosedIssueMessageId);
                    if (message.assigneeChatUserId != null && Object.hasOwnProperty.call(message, "assigneeChatUserId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.assigneeChatUserId);
                    return writer;
                };

                /**
                 * Decodes a Client message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.Chat.Client} Client
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Client.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Chat.Client();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ownerId = reader.uint64();
                            break;
                        case 2:
                            if (!(message.chatUserId && message.chatUserId.length))
                                message.chatUserId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.chatUserId.push(reader.uint64());
                            } else
                                message.chatUserId.push(reader.uint64());
                            break;
                        case 3:
                            message.lastClosedIssueMessageId = reader.uint64();
                            break;
                        case 4:
                            message.assigneeChatUserId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Client message.
                 * @function verify
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Client.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                            return "ownerId: integer|Long expected";
                    if (message.chatUserId != null && message.hasOwnProperty("chatUserId")) {
                        if (!Array.isArray(message.chatUserId))
                            return "chatUserId: array expected";
                        for (var i = 0; i < message.chatUserId.length; ++i)
                            if (!$util.isInteger(message.chatUserId[i]) && !(message.chatUserId[i] && $util.isInteger(message.chatUserId[i].low) && $util.isInteger(message.chatUserId[i].high)))
                                return "chatUserId: integer|Long[] expected";
                    }
                    if (message.lastClosedIssueMessageId != null && message.hasOwnProperty("lastClosedIssueMessageId"))
                        if (!$util.isInteger(message.lastClosedIssueMessageId) && !(message.lastClosedIssueMessageId && $util.isInteger(message.lastClosedIssueMessageId.low) && $util.isInteger(message.lastClosedIssueMessageId.high)))
                            return "lastClosedIssueMessageId: integer|Long expected";
                    if (message.assigneeChatUserId != null && message.hasOwnProperty("assigneeChatUserId"))
                        if (!$util.isInteger(message.assigneeChatUserId) && !(message.assigneeChatUserId && $util.isInteger(message.assigneeChatUserId.low) && $util.isInteger(message.assigneeChatUserId.high)))
                            return "assigneeChatUserId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Client message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.Chat.Client} Client
                 */
                Client.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.Chat.Client)
                        return object;
                    var message = new $root.realtalk.manager.Chat.Client();
                    if (object.ownerId != null)
                        if ($util.Long)
                            (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = true;
                        else if (typeof object.ownerId === "string")
                            message.ownerId = parseInt(object.ownerId, 10);
                        else if (typeof object.ownerId === "number")
                            message.ownerId = object.ownerId;
                        else if (typeof object.ownerId === "object")
                            message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber(true);
                    if (object.chatUserId) {
                        if (!Array.isArray(object.chatUserId))
                            throw TypeError(".realtalk.manager.Chat.Client.chatUserId: array expected");
                        message.chatUserId = [];
                        for (var i = 0; i < object.chatUserId.length; ++i)
                            if ($util.Long)
                                (message.chatUserId[i] = $util.Long.fromValue(object.chatUserId[i])).unsigned = true;
                            else if (typeof object.chatUserId[i] === "string")
                                message.chatUserId[i] = parseInt(object.chatUserId[i], 10);
                            else if (typeof object.chatUserId[i] === "number")
                                message.chatUserId[i] = object.chatUserId[i];
                            else if (typeof object.chatUserId[i] === "object")
                                message.chatUserId[i] = new $util.LongBits(object.chatUserId[i].low >>> 0, object.chatUserId[i].high >>> 0).toNumber(true);
                    }
                    if (object.lastClosedIssueMessageId != null)
                        if ($util.Long)
                            (message.lastClosedIssueMessageId = $util.Long.fromValue(object.lastClosedIssueMessageId)).unsigned = true;
                        else if (typeof object.lastClosedIssueMessageId === "string")
                            message.lastClosedIssueMessageId = parseInt(object.lastClosedIssueMessageId, 10);
                        else if (typeof object.lastClosedIssueMessageId === "number")
                            message.lastClosedIssueMessageId = object.lastClosedIssueMessageId;
                        else if (typeof object.lastClosedIssueMessageId === "object")
                            message.lastClosedIssueMessageId = new $util.LongBits(object.lastClosedIssueMessageId.low >>> 0, object.lastClosedIssueMessageId.high >>> 0).toNumber(true);
                    if (object.assigneeChatUserId != null)
                        if ($util.Long)
                            (message.assigneeChatUserId = $util.Long.fromValue(object.assigneeChatUserId)).unsigned = true;
                        else if (typeof object.assigneeChatUserId === "string")
                            message.assigneeChatUserId = parseInt(object.assigneeChatUserId, 10);
                        else if (typeof object.assigneeChatUserId === "number")
                            message.assigneeChatUserId = object.assigneeChatUserId;
                        else if (typeof object.assigneeChatUserId === "object")
                            message.assigneeChatUserId = new $util.LongBits(object.assigneeChatUserId.low >>> 0, object.assigneeChatUserId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a Client message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.Chat.Client
                 * @static
                 * @param {realtalk.manager.Chat.Client} message Client
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Client.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.chatUserId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.ownerId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.lastClosedIssueMessageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastClosedIssueMessageId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.assigneeChatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.assigneeChatUserId = options.longs === String ? "0" : 0;
                    }
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (typeof message.ownerId === "number")
                            object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                        else
                            object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber(true) : message.ownerId;
                    if (message.chatUserId && message.chatUserId.length) {
                        object.chatUserId = [];
                        for (var j = 0; j < message.chatUserId.length; ++j)
                            if (typeof message.chatUserId[j] === "number")
                                object.chatUserId[j] = options.longs === String ? String(message.chatUserId[j]) : message.chatUserId[j];
                            else
                                object.chatUserId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId[j]) : options.longs === Number ? new $util.LongBits(message.chatUserId[j].low >>> 0, message.chatUserId[j].high >>> 0).toNumber(true) : message.chatUserId[j];
                    }
                    if (message.lastClosedIssueMessageId != null && message.hasOwnProperty("lastClosedIssueMessageId"))
                        if (typeof message.lastClosedIssueMessageId === "number")
                            object.lastClosedIssueMessageId = options.longs === String ? String(message.lastClosedIssueMessageId) : message.lastClosedIssueMessageId;
                        else
                            object.lastClosedIssueMessageId = options.longs === String ? $util.Long.prototype.toString.call(message.lastClosedIssueMessageId) : options.longs === Number ? new $util.LongBits(message.lastClosedIssueMessageId.low >>> 0, message.lastClosedIssueMessageId.high >>> 0).toNumber(true) : message.lastClosedIssueMessageId;
                    if (message.assigneeChatUserId != null && message.hasOwnProperty("assigneeChatUserId"))
                        if (typeof message.assigneeChatUserId === "number")
                            object.assigneeChatUserId = options.longs === String ? String(message.assigneeChatUserId) : message.assigneeChatUserId;
                        else
                            object.assigneeChatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.assigneeChatUserId) : options.longs === Number ? new $util.LongBits(message.assigneeChatUserId.low >>> 0, message.assigneeChatUserId.high >>> 0).toNumber(true) : message.assigneeChatUserId;
                    return object;
                };

                /**
                 * Converts this Client to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.Chat.Client
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Client.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Client;
            })();

            return Chat;
        })();

        manager.ChatList = (function() {

            /**
             * Properties of a ChatList.
             * @memberof realtalk.manager
             * @interface IChatList
             * @property {Array.<realtalk.manager.IChat>|null} [chat] ChatList chat
             */

            /**
             * Constructs a new ChatList.
             * @memberof realtalk.manager
             * @classdesc Represents a ChatList.
             * @implements IChatList
             * @constructor
             * @param {realtalk.manager.IChatList=} [properties] Properties to set
             */
            function ChatList(properties) {
                this.chat = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatList chat.
             * @member {Array.<realtalk.manager.IChat>} chat
             * @memberof realtalk.manager.ChatList
             * @instance
             */
            ChatList.prototype.chat = $util.emptyArray;

            /**
             * Creates a new ChatList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {realtalk.manager.IChatList=} [properties] Properties to set
             * @returns {realtalk.manager.ChatList} ChatList instance
             */
            ChatList.create = function create(properties) {
                return new ChatList(properties);
            };

            /**
             * Encodes the specified ChatList message. Does not implicitly {@link realtalk.manager.ChatList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {realtalk.manager.IChatList} message ChatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chat != null && message.chat.length)
                    for (var i = 0; i < message.chat.length; ++i)
                        $root.realtalk.manager.Chat.encode(message.chat[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ChatList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.ChatList} ChatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.ChatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.chat && message.chat.length))
                            message.chat = [];
                        message.chat.push($root.realtalk.manager.Chat.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChatList message.
             * @function verify
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chat != null && message.hasOwnProperty("chat")) {
                    if (!Array.isArray(message.chat))
                        return "chat: array expected";
                    for (var i = 0; i < message.chat.length; ++i) {
                        var error = $root.realtalk.manager.Chat.verify(message.chat[i]);
                        if (error)
                            return "chat." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ChatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.ChatList} ChatList
             */
            ChatList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.ChatList)
                    return object;
                var message = new $root.realtalk.manager.ChatList();
                if (object.chat) {
                    if (!Array.isArray(object.chat))
                        throw TypeError(".realtalk.manager.ChatList.chat: array expected");
                    message.chat = [];
                    for (var i = 0; i < object.chat.length; ++i) {
                        if (typeof object.chat[i] !== "object")
                            throw TypeError(".realtalk.manager.ChatList.chat: object expected");
                        message.chat[i] = $root.realtalk.manager.Chat.fromObject(object.chat[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ChatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.ChatList
             * @static
             * @param {realtalk.manager.ChatList} message ChatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.chat = [];
                if (message.chat && message.chat.length) {
                    object.chat = [];
                    for (var j = 0; j < message.chat.length; ++j)
                        object.chat[j] = $root.realtalk.manager.Chat.toObject(message.chat[j], options);
                }
                return object;
            };

            /**
             * Converts this ChatList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.ChatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChatList;
        })();

        manager.ClientMessage = (function() {

            /**
             * Properties of a ClientMessage.
             * @memberof realtalk.manager
             * @interface IClientMessage
             * @property {number|Long|null} [id] ClientMessage id
             * @property {realtalk.manager.IOperator|null} [operator] ClientMessage operator
             * @property {realtalk.manager.IMessage|null} [message] ClientMessage message
             * @property {realtalk.manager.ISendToNextLine|null} [sendToNextLine] ClientMessage sendToNextLine
             * @property {realtalk.manager.ILeaveChat|null} [leaveChat] ClientMessage leaveChat
             * @property {realtalk.manager.IGetChatList|null} [getChatList] ClientMessage getChatList
             * @property {realtalk.manager.IGetMessageList|null} [getMessageList] ClientMessage getMessageList
             * @property {realtalk.manager.IGetChatUser|null} [getChatUser] ClientMessage getChatUser
             * @property {realtalk.manager.ICloseIssue|null} [closeIssue] ClientMessage closeIssue
             * @property {realtalk.manager.IMarkMessageAsRead|null} [markMessageAsRead] ClientMessage markMessageAsRead
             * @property {realtalk.manager.IAddToLine|null} [addToLine] ClientMessage addToLine
             * @property {realtalk.manager.IRemoveFromLine|null} [removeFromLine] ClientMessage removeFromLine
             * @property {realtalk.manager.IGetLineList|null} [getLineList] ClientMessage getLineList
             * @property {realtalk.manager.IGetOperatorReportList|null} [getOperatorReportList] ClientMessage getOperatorReportList
             * @property {realtalk.manager.IGetCurrentOrganization|null} [getCurrentOrganization] ClientMessage getCurrentOrganization
             * @property {realtalk.manager.IGetCurrentOperator|null} [getCurrentOperator] ClientMessage getCurrentOperator
             * @property {realtalk.manager.IGetOperatorList|null} [getOperatorList] ClientMessage getOperatorList
             * @property {realtalk.manager.IBanChat|null} [banChat] ClientMessage banChat
             * @property {realtalk.manager.IAddOrganizationDomain|null} [addOrganizationDomain] ClientMessage addOrganizationDomain
             * @property {realtalk.manager.IRemoveOrganizationDomain|null} [removeOrganizationDomain] ClientMessage removeOrganizationDomain
             * @property {realtalk.manager.IAddOperatorRoom|null} [addOperatorRoom] ClientMessage addOperatorRoom
             * @property {realtalk.manager.IRemoveOperatorRoom|null} [removeOperatorRoom] ClientMessage removeOperatorRoom
             * @property {realtalk.manager.IJoinToChat|null} [joinToChat] ClientMessage joinToChat
             * @property {realtalk.manager.IUpdateOperatorRoom|null} [updateOperatorRoom] ClientMessage updateOperatorRoom
             * @property {realtalk.manager.IAddOrGetP2PChat|null} [addOrGetP2pChat] ClientMessage addOrGetP2pChat
             * @property {realtalk.manager.IUpdateP2PChat|null} [updateP2pChat] ClientMessage updateP2pChat
             * @property {realtalk.manager.IUpdateOrganization|null} [updateOrganization] ClientMessage updateOrganization
             * @property {realtalk.manager.IJwtKey|null} [jwtKey] ClientMessage jwtKey
             * @property {realtalk.manager.IGetJwtKeyList|null} [getJwtKeyList] ClientMessage getJwtKeyList
             * @property {realtalk.manager.IGetLastActivity|null} [getLastActivity] ClientMessage getLastActivity
             * @property {realtalk.manager.IGetNumberUnreadMessages|null} [getNumberUnreadMessages] ClientMessage getNumberUnreadMessages
             * @property {realtalk.manager.IAddToChat|null} [addToChat] ClientMessage addToChat
             * @property {realtalk.manager.IGetMessageList2|null} [getMessageList2] ClientMessage getMessageList2
             * @property {realtalk.manager.IGetOperatorList2|null} [getOperatorList2] ClientMessage getOperatorList2
             */

            /**
             * Constructs a new ClientMessage.
             * @memberof realtalk.manager
             * @classdesc Represents a ClientMessage.
             * @implements IClientMessage
             * @constructor
             * @param {realtalk.manager.IClientMessage=} [properties] Properties to set
             */
            function ClientMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientMessage id.
             * @member {number|Long} id
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ClientMessage operator.
             * @member {realtalk.manager.IOperator|null|undefined} operator
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.operator = null;

            /**
             * ClientMessage message.
             * @member {realtalk.manager.IMessage|null|undefined} message
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.message = null;

            /**
             * ClientMessage sendToNextLine.
             * @member {realtalk.manager.ISendToNextLine|null|undefined} sendToNextLine
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.sendToNextLine = null;

            /**
             * ClientMessage leaveChat.
             * @member {realtalk.manager.ILeaveChat|null|undefined} leaveChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.leaveChat = null;

            /**
             * ClientMessage getChatList.
             * @member {realtalk.manager.IGetChatList|null|undefined} getChatList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getChatList = null;

            /**
             * ClientMessage getMessageList.
             * @member {realtalk.manager.IGetMessageList|null|undefined} getMessageList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getMessageList = null;

            /**
             * ClientMessage getChatUser.
             * @member {realtalk.manager.IGetChatUser|null|undefined} getChatUser
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getChatUser = null;

            /**
             * ClientMessage closeIssue.
             * @member {realtalk.manager.ICloseIssue|null|undefined} closeIssue
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.closeIssue = null;

            /**
             * ClientMessage markMessageAsRead.
             * @member {realtalk.manager.IMarkMessageAsRead|null|undefined} markMessageAsRead
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.markMessageAsRead = null;

            /**
             * ClientMessage addToLine.
             * @member {realtalk.manager.IAddToLine|null|undefined} addToLine
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.addToLine = null;

            /**
             * ClientMessage removeFromLine.
             * @member {realtalk.manager.IRemoveFromLine|null|undefined} removeFromLine
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.removeFromLine = null;

            /**
             * ClientMessage getLineList.
             * @member {realtalk.manager.IGetLineList|null|undefined} getLineList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLineList = null;

            /**
             * ClientMessage getOperatorReportList.
             * @member {realtalk.manager.IGetOperatorReportList|null|undefined} getOperatorReportList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOperatorReportList = null;

            /**
             * ClientMessage getCurrentOrganization.
             * @member {realtalk.manager.IGetCurrentOrganization|null|undefined} getCurrentOrganization
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentOrganization = null;

            /**
             * ClientMessage getCurrentOperator.
             * @member {realtalk.manager.IGetCurrentOperator|null|undefined} getCurrentOperator
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentOperator = null;

            /**
             * ClientMessage getOperatorList.
             * @member {realtalk.manager.IGetOperatorList|null|undefined} getOperatorList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOperatorList = null;

            /**
             * ClientMessage banChat.
             * @member {realtalk.manager.IBanChat|null|undefined} banChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.banChat = null;

            /**
             * ClientMessage addOrganizationDomain.
             * @member {realtalk.manager.IAddOrganizationDomain|null|undefined} addOrganizationDomain
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.addOrganizationDomain = null;

            /**
             * ClientMessage removeOrganizationDomain.
             * @member {realtalk.manager.IRemoveOrganizationDomain|null|undefined} removeOrganizationDomain
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.removeOrganizationDomain = null;

            /**
             * ClientMessage addOperatorRoom.
             * @member {realtalk.manager.IAddOperatorRoom|null|undefined} addOperatorRoom
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.addOperatorRoom = null;

            /**
             * ClientMessage removeOperatorRoom.
             * @member {realtalk.manager.IRemoveOperatorRoom|null|undefined} removeOperatorRoom
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.removeOperatorRoom = null;

            /**
             * ClientMessage joinToChat.
             * @member {realtalk.manager.IJoinToChat|null|undefined} joinToChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.joinToChat = null;

            /**
             * ClientMessage updateOperatorRoom.
             * @member {realtalk.manager.IUpdateOperatorRoom|null|undefined} updateOperatorRoom
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateOperatorRoom = null;

            /**
             * ClientMessage addOrGetP2pChat.
             * @member {realtalk.manager.IAddOrGetP2PChat|null|undefined} addOrGetP2pChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.addOrGetP2pChat = null;

            /**
             * ClientMessage updateP2pChat.
             * @member {realtalk.manager.IUpdateP2PChat|null|undefined} updateP2pChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateP2pChat = null;

            /**
             * ClientMessage updateOrganization.
             * @member {realtalk.manager.IUpdateOrganization|null|undefined} updateOrganization
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateOrganization = null;

            /**
             * ClientMessage jwtKey.
             * @member {realtalk.manager.IJwtKey|null|undefined} jwtKey
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.jwtKey = null;

            /**
             * ClientMessage getJwtKeyList.
             * @member {realtalk.manager.IGetJwtKeyList|null|undefined} getJwtKeyList
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getJwtKeyList = null;

            /**
             * ClientMessage getLastActivity.
             * @member {realtalk.manager.IGetLastActivity|null|undefined} getLastActivity
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLastActivity = null;

            /**
             * ClientMessage getNumberUnreadMessages.
             * @member {realtalk.manager.IGetNumberUnreadMessages|null|undefined} getNumberUnreadMessages
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getNumberUnreadMessages = null;

            /**
             * ClientMessage addToChat.
             * @member {realtalk.manager.IAddToChat|null|undefined} addToChat
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.addToChat = null;

            /**
             * ClientMessage getMessageList2.
             * @member {realtalk.manager.IGetMessageList2|null|undefined} getMessageList2
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getMessageList2 = null;

            /**
             * ClientMessage getOperatorList2.
             * @member {realtalk.manager.IGetOperatorList2|null|undefined} getOperatorList2
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOperatorList2 = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ClientMessage body.
             * @member {"operator"|"message"|"sendToNextLine"|"leaveChat"|"getChatList"|"getMessageList"|"getChatUser"|"closeIssue"|"markMessageAsRead"|"addToLine"|"removeFromLine"|"getLineList"|"getOperatorReportList"|"getCurrentOrganization"|"getCurrentOperator"|"getOperatorList"|"banChat"|"addOrganizationDomain"|"removeOrganizationDomain"|"addOperatorRoom"|"removeOperatorRoom"|"joinToChat"|"updateOperatorRoom"|"addOrGetP2pChat"|"updateP2pChat"|"updateOrganization"|"jwtKey"|"getJwtKeyList"|"getLastActivity"|"getNumberUnreadMessages"|"addToChat"|"getMessageList2"|"getOperatorList2"|undefined} body
             * @memberof realtalk.manager.ClientMessage
             * @instance
             */
            Object.defineProperty(ClientMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["operator", "message", "sendToNextLine", "leaveChat", "getChatList", "getMessageList", "getChatUser", "closeIssue", "markMessageAsRead", "addToLine", "removeFromLine", "getLineList", "getOperatorReportList", "getCurrentOrganization", "getCurrentOperator", "getOperatorList", "banChat", "addOrganizationDomain", "removeOrganizationDomain", "addOperatorRoom", "removeOperatorRoom", "joinToChat", "updateOperatorRoom", "addOrGetP2pChat", "updateP2pChat", "updateOrganization", "jwtKey", "getJwtKeyList", "getLastActivity", "getNumberUnreadMessages", "addToChat", "getMessageList2", "getOperatorList2"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ClientMessage instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {realtalk.manager.IClientMessage=} [properties] Properties to set
             * @returns {realtalk.manager.ClientMessage} ClientMessage instance
             */
            ClientMessage.create = function create(properties) {
                return new ClientMessage(properties);
            };

            /**
             * Encodes the specified ClientMessage message. Does not implicitly {@link realtalk.manager.ClientMessage.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {realtalk.manager.IClientMessage} message ClientMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                    $root.realtalk.manager.Operator.encode(message.operator, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    $root.realtalk.manager.Message.encode(message.message, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.sendToNextLine != null && Object.hasOwnProperty.call(message, "sendToNextLine"))
                    $root.realtalk.manager.SendToNextLine.encode(message.sendToNextLine, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.leaveChat != null && Object.hasOwnProperty.call(message, "leaveChat"))
                    $root.realtalk.manager.LeaveChat.encode(message.leaveChat, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.getChatList != null && Object.hasOwnProperty.call(message, "getChatList"))
                    $root.realtalk.manager.GetChatList.encode(message.getChatList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.getMessageList != null && Object.hasOwnProperty.call(message, "getMessageList"))
                    $root.realtalk.manager.GetMessageList.encode(message.getMessageList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.getChatUser != null && Object.hasOwnProperty.call(message, "getChatUser"))
                    $root.realtalk.manager.GetChatUser.encode(message.getChatUser, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.closeIssue != null && Object.hasOwnProperty.call(message, "closeIssue"))
                    $root.realtalk.manager.CloseIssue.encode(message.closeIssue, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.markMessageAsRead != null && Object.hasOwnProperty.call(message, "markMessageAsRead"))
                    $root.realtalk.manager.MarkMessageAsRead.encode(message.markMessageAsRead, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.addToLine != null && Object.hasOwnProperty.call(message, "addToLine"))
                    $root.realtalk.manager.AddToLine.encode(message.addToLine, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.removeFromLine != null && Object.hasOwnProperty.call(message, "removeFromLine"))
                    $root.realtalk.manager.RemoveFromLine.encode(message.removeFromLine, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.getLineList != null && Object.hasOwnProperty.call(message, "getLineList"))
                    $root.realtalk.manager.GetLineList.encode(message.getLineList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.getOperatorReportList != null && Object.hasOwnProperty.call(message, "getOperatorReportList"))
                    $root.realtalk.manager.GetOperatorReportList.encode(message.getOperatorReportList, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.getCurrentOrganization != null && Object.hasOwnProperty.call(message, "getCurrentOrganization"))
                    $root.realtalk.manager.GetCurrentOrganization.encode(message.getCurrentOrganization, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.getCurrentOperator != null && Object.hasOwnProperty.call(message, "getCurrentOperator"))
                    $root.realtalk.manager.GetCurrentOperator.encode(message.getCurrentOperator, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.getOperatorList != null && Object.hasOwnProperty.call(message, "getOperatorList"))
                    $root.realtalk.manager.GetOperatorList.encode(message.getOperatorList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.banChat != null && Object.hasOwnProperty.call(message, "banChat"))
                    $root.realtalk.manager.BanChat.encode(message.banChat, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.addOrganizationDomain != null && Object.hasOwnProperty.call(message, "addOrganizationDomain"))
                    $root.realtalk.manager.AddOrganizationDomain.encode(message.addOrganizationDomain, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.removeOrganizationDomain != null && Object.hasOwnProperty.call(message, "removeOrganizationDomain"))
                    $root.realtalk.manager.RemoveOrganizationDomain.encode(message.removeOrganizationDomain, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.addOperatorRoom != null && Object.hasOwnProperty.call(message, "addOperatorRoom"))
                    $root.realtalk.manager.AddOperatorRoom.encode(message.addOperatorRoom, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.removeOperatorRoom != null && Object.hasOwnProperty.call(message, "removeOperatorRoom"))
                    $root.realtalk.manager.RemoveOperatorRoom.encode(message.removeOperatorRoom, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.joinToChat != null && Object.hasOwnProperty.call(message, "joinToChat"))
                    $root.realtalk.manager.JoinToChat.encode(message.joinToChat, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.updateOperatorRoom != null && Object.hasOwnProperty.call(message, "updateOperatorRoom"))
                    $root.realtalk.manager.UpdateOperatorRoom.encode(message.updateOperatorRoom, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.addOrGetP2pChat != null && Object.hasOwnProperty.call(message, "addOrGetP2pChat"))
                    $root.realtalk.manager.AddOrGetP2PChat.encode(message.addOrGetP2pChat, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.updateP2pChat != null && Object.hasOwnProperty.call(message, "updateP2pChat"))
                    $root.realtalk.manager.UpdateP2PChat.encode(message.updateP2pChat, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.updateOrganization != null && Object.hasOwnProperty.call(message, "updateOrganization"))
                    $root.realtalk.manager.UpdateOrganization.encode(message.updateOrganization, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.jwtKey != null && Object.hasOwnProperty.call(message, "jwtKey"))
                    $root.realtalk.manager.JwtKey.encode(message.jwtKey, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.getJwtKeyList != null && Object.hasOwnProperty.call(message, "getJwtKeyList"))
                    $root.realtalk.manager.GetJwtKeyList.encode(message.getJwtKeyList, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.getLastActivity != null && Object.hasOwnProperty.call(message, "getLastActivity"))
                    $root.realtalk.manager.GetLastActivity.encode(message.getLastActivity, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.getNumberUnreadMessages != null && Object.hasOwnProperty.call(message, "getNumberUnreadMessages"))
                    $root.realtalk.manager.GetNumberUnreadMessages.encode(message.getNumberUnreadMessages, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.addToChat != null && Object.hasOwnProperty.call(message, "addToChat"))
                    $root.realtalk.manager.AddToChat.encode(message.addToChat, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.getMessageList2 != null && Object.hasOwnProperty.call(message, "getMessageList2"))
                    $root.realtalk.manager.GetMessageList2.encode(message.getMessageList2, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.getOperatorList2 != null && Object.hasOwnProperty.call(message, "getOperatorList2"))
                    $root.realtalk.manager.GetOperatorList2.encode(message.getOperatorList2, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ClientMessage message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.ClientMessage} ClientMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.ClientMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 3:
                        message.operator = $root.realtalk.manager.Operator.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.message = $root.realtalk.manager.Message.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.sendToNextLine = $root.realtalk.manager.SendToNextLine.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.leaveChat = $root.realtalk.manager.LeaveChat.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.getChatList = $root.realtalk.manager.GetChatList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.getMessageList = $root.realtalk.manager.GetMessageList.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.getChatUser = $root.realtalk.manager.GetChatUser.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.closeIssue = $root.realtalk.manager.CloseIssue.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.markMessageAsRead = $root.realtalk.manager.MarkMessageAsRead.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.addToLine = $root.realtalk.manager.AddToLine.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.removeFromLine = $root.realtalk.manager.RemoveFromLine.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.getLineList = $root.realtalk.manager.GetLineList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.getOperatorReportList = $root.realtalk.manager.GetOperatorReportList.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.getCurrentOrganization = $root.realtalk.manager.GetCurrentOrganization.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.getCurrentOperator = $root.realtalk.manager.GetCurrentOperator.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.getOperatorList = $root.realtalk.manager.GetOperatorList.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.banChat = $root.realtalk.manager.BanChat.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.addOrganizationDomain = $root.realtalk.manager.AddOrganizationDomain.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.removeOrganizationDomain = $root.realtalk.manager.RemoveOrganizationDomain.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.addOperatorRoom = $root.realtalk.manager.AddOperatorRoom.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.removeOperatorRoom = $root.realtalk.manager.RemoveOperatorRoom.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.joinToChat = $root.realtalk.manager.JoinToChat.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.updateOperatorRoom = $root.realtalk.manager.UpdateOperatorRoom.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.addOrGetP2pChat = $root.realtalk.manager.AddOrGetP2PChat.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.updateP2pChat = $root.realtalk.manager.UpdateP2PChat.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.updateOrganization = $root.realtalk.manager.UpdateOrganization.decode(reader, reader.uint32());
                        break;
                    case 29:
                        message.jwtKey = $root.realtalk.manager.JwtKey.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.getJwtKeyList = $root.realtalk.manager.GetJwtKeyList.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.getLastActivity = $root.realtalk.manager.GetLastActivity.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.getNumberUnreadMessages = $root.realtalk.manager.GetNumberUnreadMessages.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.addToChat = $root.realtalk.manager.AddToChat.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.getMessageList2 = $root.realtalk.manager.GetMessageList2.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.getOperatorList2 = $root.realtalk.manager.GetOperatorList2.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ClientMessage message.
             * @function verify
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.operator != null && message.hasOwnProperty("operator")) {
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Operator.verify(message.operator);
                        if (error)
                            return "operator." + error;
                    }
                }
                if (message.message != null && message.hasOwnProperty("message")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Message.verify(message.message);
                        if (error)
                            return "message." + error;
                    }
                }
                if (message.sendToNextLine != null && message.hasOwnProperty("sendToNextLine")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.SendToNextLine.verify(message.sendToNextLine);
                        if (error)
                            return "sendToNextLine." + error;
                    }
                }
                if (message.leaveChat != null && message.hasOwnProperty("leaveChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.LeaveChat.verify(message.leaveChat);
                        if (error)
                            return "leaveChat." + error;
                    }
                }
                if (message.getChatList != null && message.hasOwnProperty("getChatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetChatList.verify(message.getChatList);
                        if (error)
                            return "getChatList." + error;
                    }
                }
                if (message.getMessageList != null && message.hasOwnProperty("getMessageList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetMessageList.verify(message.getMessageList);
                        if (error)
                            return "getMessageList." + error;
                    }
                }
                if (message.getChatUser != null && message.hasOwnProperty("getChatUser")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetChatUser.verify(message.getChatUser);
                        if (error)
                            return "getChatUser." + error;
                    }
                }
                if (message.closeIssue != null && message.hasOwnProperty("closeIssue")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.CloseIssue.verify(message.closeIssue);
                        if (error)
                            return "closeIssue." + error;
                    }
                }
                if (message.markMessageAsRead != null && message.hasOwnProperty("markMessageAsRead")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.MarkMessageAsRead.verify(message.markMessageAsRead);
                        if (error)
                            return "markMessageAsRead." + error;
                    }
                }
                if (message.addToLine != null && message.hasOwnProperty("addToLine")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.AddToLine.verify(message.addToLine);
                        if (error)
                            return "addToLine." + error;
                    }
                }
                if (message.removeFromLine != null && message.hasOwnProperty("removeFromLine")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.RemoveFromLine.verify(message.removeFromLine);
                        if (error)
                            return "removeFromLine." + error;
                    }
                }
                if (message.getLineList != null && message.hasOwnProperty("getLineList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetLineList.verify(message.getLineList);
                        if (error)
                            return "getLineList." + error;
                    }
                }
                if (message.getOperatorReportList != null && message.hasOwnProperty("getOperatorReportList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorReportList.verify(message.getOperatorReportList);
                        if (error)
                            return "getOperatorReportList." + error;
                    }
                }
                if (message.getCurrentOrganization != null && message.hasOwnProperty("getCurrentOrganization")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetCurrentOrganization.verify(message.getCurrentOrganization);
                        if (error)
                            return "getCurrentOrganization." + error;
                    }
                }
                if (message.getCurrentOperator != null && message.hasOwnProperty("getCurrentOperator")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetCurrentOperator.verify(message.getCurrentOperator);
                        if (error)
                            return "getCurrentOperator." + error;
                    }
                }
                if (message.getOperatorList != null && message.hasOwnProperty("getOperatorList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorList.verify(message.getOperatorList);
                        if (error)
                            return "getOperatorList." + error;
                    }
                }
                if (message.banChat != null && message.hasOwnProperty("banChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.BanChat.verify(message.banChat);
                        if (error)
                            return "banChat." + error;
                    }
                }
                if (message.addOrganizationDomain != null && message.hasOwnProperty("addOrganizationDomain")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.AddOrganizationDomain.verify(message.addOrganizationDomain);
                        if (error)
                            return "addOrganizationDomain." + error;
                    }
                }
                if (message.removeOrganizationDomain != null && message.hasOwnProperty("removeOrganizationDomain")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.RemoveOrganizationDomain.verify(message.removeOrganizationDomain);
                        if (error)
                            return "removeOrganizationDomain." + error;
                    }
                }
                if (message.addOperatorRoom != null && message.hasOwnProperty("addOperatorRoom")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.AddOperatorRoom.verify(message.addOperatorRoom);
                        if (error)
                            return "addOperatorRoom." + error;
                    }
                }
                if (message.removeOperatorRoom != null && message.hasOwnProperty("removeOperatorRoom")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.RemoveOperatorRoom.verify(message.removeOperatorRoom);
                        if (error)
                            return "removeOperatorRoom." + error;
                    }
                }
                if (message.joinToChat != null && message.hasOwnProperty("joinToChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.JoinToChat.verify(message.joinToChat);
                        if (error)
                            return "joinToChat." + error;
                    }
                }
                if (message.updateOperatorRoom != null && message.hasOwnProperty("updateOperatorRoom")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.UpdateOperatorRoom.verify(message.updateOperatorRoom);
                        if (error)
                            return "updateOperatorRoom." + error;
                    }
                }
                if (message.addOrGetP2pChat != null && message.hasOwnProperty("addOrGetP2pChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.AddOrGetP2PChat.verify(message.addOrGetP2pChat);
                        if (error)
                            return "addOrGetP2pChat." + error;
                    }
                }
                if (message.updateP2pChat != null && message.hasOwnProperty("updateP2pChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.UpdateP2PChat.verify(message.updateP2pChat);
                        if (error)
                            return "updateP2pChat." + error;
                    }
                }
                if (message.updateOrganization != null && message.hasOwnProperty("updateOrganization")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.UpdateOrganization.verify(message.updateOrganization);
                        if (error)
                            return "updateOrganization." + error;
                    }
                }
                if (message.jwtKey != null && message.hasOwnProperty("jwtKey")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.JwtKey.verify(message.jwtKey);
                        if (error)
                            return "jwtKey." + error;
                    }
                }
                if (message.getJwtKeyList != null && message.hasOwnProperty("getJwtKeyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetJwtKeyList.verify(message.getJwtKeyList);
                        if (error)
                            return "getJwtKeyList." + error;
                    }
                }
                if (message.getLastActivity != null && message.hasOwnProperty("getLastActivity")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetLastActivity.verify(message.getLastActivity);
                        if (error)
                            return "getLastActivity." + error;
                    }
                }
                if (message.getNumberUnreadMessages != null && message.hasOwnProperty("getNumberUnreadMessages")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetNumberUnreadMessages.verify(message.getNumberUnreadMessages);
                        if (error)
                            return "getNumberUnreadMessages." + error;
                    }
                }
                if (message.addToChat != null && message.hasOwnProperty("addToChat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.AddToChat.verify(message.addToChat);
                        if (error)
                            return "addToChat." + error;
                    }
                }
                if (message.getMessageList2 != null && message.hasOwnProperty("getMessageList2")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetMessageList2.verify(message.getMessageList2);
                        if (error)
                            return "getMessageList2." + error;
                    }
                }
                if (message.getOperatorList2 != null && message.hasOwnProperty("getOperatorList2")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorList2.verify(message.getOperatorList2);
                        if (error)
                            return "getOperatorList2." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ClientMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.ClientMessage} ClientMessage
             */
            ClientMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.ClientMessage)
                    return object;
                var message = new $root.realtalk.manager.ClientMessage();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.operator != null) {
                    if (typeof object.operator !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.operator: object expected");
                    message.operator = $root.realtalk.manager.Operator.fromObject(object.operator);
                }
                if (object.message != null) {
                    if (typeof object.message !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.message: object expected");
                    message.message = $root.realtalk.manager.Message.fromObject(object.message);
                }
                if (object.sendToNextLine != null) {
                    if (typeof object.sendToNextLine !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.sendToNextLine: object expected");
                    message.sendToNextLine = $root.realtalk.manager.SendToNextLine.fromObject(object.sendToNextLine);
                }
                if (object.leaveChat != null) {
                    if (typeof object.leaveChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.leaveChat: object expected");
                    message.leaveChat = $root.realtalk.manager.LeaveChat.fromObject(object.leaveChat);
                }
                if (object.getChatList != null) {
                    if (typeof object.getChatList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getChatList: object expected");
                    message.getChatList = $root.realtalk.manager.GetChatList.fromObject(object.getChatList);
                }
                if (object.getMessageList != null) {
                    if (typeof object.getMessageList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getMessageList: object expected");
                    message.getMessageList = $root.realtalk.manager.GetMessageList.fromObject(object.getMessageList);
                }
                if (object.getChatUser != null) {
                    if (typeof object.getChatUser !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getChatUser: object expected");
                    message.getChatUser = $root.realtalk.manager.GetChatUser.fromObject(object.getChatUser);
                }
                if (object.closeIssue != null) {
                    if (typeof object.closeIssue !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.closeIssue: object expected");
                    message.closeIssue = $root.realtalk.manager.CloseIssue.fromObject(object.closeIssue);
                }
                if (object.markMessageAsRead != null) {
                    if (typeof object.markMessageAsRead !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.markMessageAsRead: object expected");
                    message.markMessageAsRead = $root.realtalk.manager.MarkMessageAsRead.fromObject(object.markMessageAsRead);
                }
                if (object.addToLine != null) {
                    if (typeof object.addToLine !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.addToLine: object expected");
                    message.addToLine = $root.realtalk.manager.AddToLine.fromObject(object.addToLine);
                }
                if (object.removeFromLine != null) {
                    if (typeof object.removeFromLine !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.removeFromLine: object expected");
                    message.removeFromLine = $root.realtalk.manager.RemoveFromLine.fromObject(object.removeFromLine);
                }
                if (object.getLineList != null) {
                    if (typeof object.getLineList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getLineList: object expected");
                    message.getLineList = $root.realtalk.manager.GetLineList.fromObject(object.getLineList);
                }
                if (object.getOperatorReportList != null) {
                    if (typeof object.getOperatorReportList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getOperatorReportList: object expected");
                    message.getOperatorReportList = $root.realtalk.manager.GetOperatorReportList.fromObject(object.getOperatorReportList);
                }
                if (object.getCurrentOrganization != null) {
                    if (typeof object.getCurrentOrganization !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getCurrentOrganization: object expected");
                    message.getCurrentOrganization = $root.realtalk.manager.GetCurrentOrganization.fromObject(object.getCurrentOrganization);
                }
                if (object.getCurrentOperator != null) {
                    if (typeof object.getCurrentOperator !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getCurrentOperator: object expected");
                    message.getCurrentOperator = $root.realtalk.manager.GetCurrentOperator.fromObject(object.getCurrentOperator);
                }
                if (object.getOperatorList != null) {
                    if (typeof object.getOperatorList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getOperatorList: object expected");
                    message.getOperatorList = $root.realtalk.manager.GetOperatorList.fromObject(object.getOperatorList);
                }
                if (object.banChat != null) {
                    if (typeof object.banChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.banChat: object expected");
                    message.banChat = $root.realtalk.manager.BanChat.fromObject(object.banChat);
                }
                if (object.addOrganizationDomain != null) {
                    if (typeof object.addOrganizationDomain !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.addOrganizationDomain: object expected");
                    message.addOrganizationDomain = $root.realtalk.manager.AddOrganizationDomain.fromObject(object.addOrganizationDomain);
                }
                if (object.removeOrganizationDomain != null) {
                    if (typeof object.removeOrganizationDomain !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.removeOrganizationDomain: object expected");
                    message.removeOrganizationDomain = $root.realtalk.manager.RemoveOrganizationDomain.fromObject(object.removeOrganizationDomain);
                }
                if (object.addOperatorRoom != null) {
                    if (typeof object.addOperatorRoom !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.addOperatorRoom: object expected");
                    message.addOperatorRoom = $root.realtalk.manager.AddOperatorRoom.fromObject(object.addOperatorRoom);
                }
                if (object.removeOperatorRoom != null) {
                    if (typeof object.removeOperatorRoom !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.removeOperatorRoom: object expected");
                    message.removeOperatorRoom = $root.realtalk.manager.RemoveOperatorRoom.fromObject(object.removeOperatorRoom);
                }
                if (object.joinToChat != null) {
                    if (typeof object.joinToChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.joinToChat: object expected");
                    message.joinToChat = $root.realtalk.manager.JoinToChat.fromObject(object.joinToChat);
                }
                if (object.updateOperatorRoom != null) {
                    if (typeof object.updateOperatorRoom !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.updateOperatorRoom: object expected");
                    message.updateOperatorRoom = $root.realtalk.manager.UpdateOperatorRoom.fromObject(object.updateOperatorRoom);
                }
                if (object.addOrGetP2pChat != null) {
                    if (typeof object.addOrGetP2pChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.addOrGetP2pChat: object expected");
                    message.addOrGetP2pChat = $root.realtalk.manager.AddOrGetP2PChat.fromObject(object.addOrGetP2pChat);
                }
                if (object.updateP2pChat != null) {
                    if (typeof object.updateP2pChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.updateP2pChat: object expected");
                    message.updateP2pChat = $root.realtalk.manager.UpdateP2PChat.fromObject(object.updateP2pChat);
                }
                if (object.updateOrganization != null) {
                    if (typeof object.updateOrganization !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.updateOrganization: object expected");
                    message.updateOrganization = $root.realtalk.manager.UpdateOrganization.fromObject(object.updateOrganization);
                }
                if (object.jwtKey != null) {
                    if (typeof object.jwtKey !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.jwtKey: object expected");
                    message.jwtKey = $root.realtalk.manager.JwtKey.fromObject(object.jwtKey);
                }
                if (object.getJwtKeyList != null) {
                    if (typeof object.getJwtKeyList !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getJwtKeyList: object expected");
                    message.getJwtKeyList = $root.realtalk.manager.GetJwtKeyList.fromObject(object.getJwtKeyList);
                }
                if (object.getLastActivity != null) {
                    if (typeof object.getLastActivity !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getLastActivity: object expected");
                    message.getLastActivity = $root.realtalk.manager.GetLastActivity.fromObject(object.getLastActivity);
                }
                if (object.getNumberUnreadMessages != null) {
                    if (typeof object.getNumberUnreadMessages !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getNumberUnreadMessages: object expected");
                    message.getNumberUnreadMessages = $root.realtalk.manager.GetNumberUnreadMessages.fromObject(object.getNumberUnreadMessages);
                }
                if (object.addToChat != null) {
                    if (typeof object.addToChat !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.addToChat: object expected");
                    message.addToChat = $root.realtalk.manager.AddToChat.fromObject(object.addToChat);
                }
                if (object.getMessageList2 != null) {
                    if (typeof object.getMessageList2 !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getMessageList2: object expected");
                    message.getMessageList2 = $root.realtalk.manager.GetMessageList2.fromObject(object.getMessageList2);
                }
                if (object.getOperatorList2 != null) {
                    if (typeof object.getOperatorList2 !== "object")
                        throw TypeError(".realtalk.manager.ClientMessage.getOperatorList2: object expected");
                    message.getOperatorList2 = $root.realtalk.manager.GetOperatorList2.fromObject(object.getOperatorList2);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.ClientMessage
             * @static
             * @param {realtalk.manager.ClientMessage} message ClientMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.operator != null && message.hasOwnProperty("operator")) {
                    object.operator = $root.realtalk.manager.Operator.toObject(message.operator, options);
                    if (options.oneofs)
                        object.body = "operator";
                }
                if (message.message != null && message.hasOwnProperty("message")) {
                    object.message = $root.realtalk.manager.Message.toObject(message.message, options);
                    if (options.oneofs)
                        object.body = "message";
                }
                if (message.sendToNextLine != null && message.hasOwnProperty("sendToNextLine")) {
                    object.sendToNextLine = $root.realtalk.manager.SendToNextLine.toObject(message.sendToNextLine, options);
                    if (options.oneofs)
                        object.body = "sendToNextLine";
                }
                if (message.leaveChat != null && message.hasOwnProperty("leaveChat")) {
                    object.leaveChat = $root.realtalk.manager.LeaveChat.toObject(message.leaveChat, options);
                    if (options.oneofs)
                        object.body = "leaveChat";
                }
                if (message.getChatList != null && message.hasOwnProperty("getChatList")) {
                    object.getChatList = $root.realtalk.manager.GetChatList.toObject(message.getChatList, options);
                    if (options.oneofs)
                        object.body = "getChatList";
                }
                if (message.getMessageList != null && message.hasOwnProperty("getMessageList")) {
                    object.getMessageList = $root.realtalk.manager.GetMessageList.toObject(message.getMessageList, options);
                    if (options.oneofs)
                        object.body = "getMessageList";
                }
                if (message.getChatUser != null && message.hasOwnProperty("getChatUser")) {
                    object.getChatUser = $root.realtalk.manager.GetChatUser.toObject(message.getChatUser, options);
                    if (options.oneofs)
                        object.body = "getChatUser";
                }
                if (message.closeIssue != null && message.hasOwnProperty("closeIssue")) {
                    object.closeIssue = $root.realtalk.manager.CloseIssue.toObject(message.closeIssue, options);
                    if (options.oneofs)
                        object.body = "closeIssue";
                }
                if (message.markMessageAsRead != null && message.hasOwnProperty("markMessageAsRead")) {
                    object.markMessageAsRead = $root.realtalk.manager.MarkMessageAsRead.toObject(message.markMessageAsRead, options);
                    if (options.oneofs)
                        object.body = "markMessageAsRead";
                }
                if (message.addToLine != null && message.hasOwnProperty("addToLine")) {
                    object.addToLine = $root.realtalk.manager.AddToLine.toObject(message.addToLine, options);
                    if (options.oneofs)
                        object.body = "addToLine";
                }
                if (message.removeFromLine != null && message.hasOwnProperty("removeFromLine")) {
                    object.removeFromLine = $root.realtalk.manager.RemoveFromLine.toObject(message.removeFromLine, options);
                    if (options.oneofs)
                        object.body = "removeFromLine";
                }
                if (message.getLineList != null && message.hasOwnProperty("getLineList")) {
                    object.getLineList = $root.realtalk.manager.GetLineList.toObject(message.getLineList, options);
                    if (options.oneofs)
                        object.body = "getLineList";
                }
                if (message.getOperatorReportList != null && message.hasOwnProperty("getOperatorReportList")) {
                    object.getOperatorReportList = $root.realtalk.manager.GetOperatorReportList.toObject(message.getOperatorReportList, options);
                    if (options.oneofs)
                        object.body = "getOperatorReportList";
                }
                if (message.getCurrentOrganization != null && message.hasOwnProperty("getCurrentOrganization")) {
                    object.getCurrentOrganization = $root.realtalk.manager.GetCurrentOrganization.toObject(message.getCurrentOrganization, options);
                    if (options.oneofs)
                        object.body = "getCurrentOrganization";
                }
                if (message.getCurrentOperator != null && message.hasOwnProperty("getCurrentOperator")) {
                    object.getCurrentOperator = $root.realtalk.manager.GetCurrentOperator.toObject(message.getCurrentOperator, options);
                    if (options.oneofs)
                        object.body = "getCurrentOperator";
                }
                if (message.getOperatorList != null && message.hasOwnProperty("getOperatorList")) {
                    object.getOperatorList = $root.realtalk.manager.GetOperatorList.toObject(message.getOperatorList, options);
                    if (options.oneofs)
                        object.body = "getOperatorList";
                }
                if (message.banChat != null && message.hasOwnProperty("banChat")) {
                    object.banChat = $root.realtalk.manager.BanChat.toObject(message.banChat, options);
                    if (options.oneofs)
                        object.body = "banChat";
                }
                if (message.addOrganizationDomain != null && message.hasOwnProperty("addOrganizationDomain")) {
                    object.addOrganizationDomain = $root.realtalk.manager.AddOrganizationDomain.toObject(message.addOrganizationDomain, options);
                    if (options.oneofs)
                        object.body = "addOrganizationDomain";
                }
                if (message.removeOrganizationDomain != null && message.hasOwnProperty("removeOrganizationDomain")) {
                    object.removeOrganizationDomain = $root.realtalk.manager.RemoveOrganizationDomain.toObject(message.removeOrganizationDomain, options);
                    if (options.oneofs)
                        object.body = "removeOrganizationDomain";
                }
                if (message.addOperatorRoom != null && message.hasOwnProperty("addOperatorRoom")) {
                    object.addOperatorRoom = $root.realtalk.manager.AddOperatorRoom.toObject(message.addOperatorRoom, options);
                    if (options.oneofs)
                        object.body = "addOperatorRoom";
                }
                if (message.removeOperatorRoom != null && message.hasOwnProperty("removeOperatorRoom")) {
                    object.removeOperatorRoom = $root.realtalk.manager.RemoveOperatorRoom.toObject(message.removeOperatorRoom, options);
                    if (options.oneofs)
                        object.body = "removeOperatorRoom";
                }
                if (message.joinToChat != null && message.hasOwnProperty("joinToChat")) {
                    object.joinToChat = $root.realtalk.manager.JoinToChat.toObject(message.joinToChat, options);
                    if (options.oneofs)
                        object.body = "joinToChat";
                }
                if (message.updateOperatorRoom != null && message.hasOwnProperty("updateOperatorRoom")) {
                    object.updateOperatorRoom = $root.realtalk.manager.UpdateOperatorRoom.toObject(message.updateOperatorRoom, options);
                    if (options.oneofs)
                        object.body = "updateOperatorRoom";
                }
                if (message.addOrGetP2pChat != null && message.hasOwnProperty("addOrGetP2pChat")) {
                    object.addOrGetP2pChat = $root.realtalk.manager.AddOrGetP2PChat.toObject(message.addOrGetP2pChat, options);
                    if (options.oneofs)
                        object.body = "addOrGetP2pChat";
                }
                if (message.updateP2pChat != null && message.hasOwnProperty("updateP2pChat")) {
                    object.updateP2pChat = $root.realtalk.manager.UpdateP2PChat.toObject(message.updateP2pChat, options);
                    if (options.oneofs)
                        object.body = "updateP2pChat";
                }
                if (message.updateOrganization != null && message.hasOwnProperty("updateOrganization")) {
                    object.updateOrganization = $root.realtalk.manager.UpdateOrganization.toObject(message.updateOrganization, options);
                    if (options.oneofs)
                        object.body = "updateOrganization";
                }
                if (message.jwtKey != null && message.hasOwnProperty("jwtKey")) {
                    object.jwtKey = $root.realtalk.manager.JwtKey.toObject(message.jwtKey, options);
                    if (options.oneofs)
                        object.body = "jwtKey";
                }
                if (message.getJwtKeyList != null && message.hasOwnProperty("getJwtKeyList")) {
                    object.getJwtKeyList = $root.realtalk.manager.GetJwtKeyList.toObject(message.getJwtKeyList, options);
                    if (options.oneofs)
                        object.body = "getJwtKeyList";
                }
                if (message.getLastActivity != null && message.hasOwnProperty("getLastActivity")) {
                    object.getLastActivity = $root.realtalk.manager.GetLastActivity.toObject(message.getLastActivity, options);
                    if (options.oneofs)
                        object.body = "getLastActivity";
                }
                if (message.getNumberUnreadMessages != null && message.hasOwnProperty("getNumberUnreadMessages")) {
                    object.getNumberUnreadMessages = $root.realtalk.manager.GetNumberUnreadMessages.toObject(message.getNumberUnreadMessages, options);
                    if (options.oneofs)
                        object.body = "getNumberUnreadMessages";
                }
                if (message.addToChat != null && message.hasOwnProperty("addToChat")) {
                    object.addToChat = $root.realtalk.manager.AddToChat.toObject(message.addToChat, options);
                    if (options.oneofs)
                        object.body = "addToChat";
                }
                if (message.getMessageList2 != null && message.hasOwnProperty("getMessageList2")) {
                    object.getMessageList2 = $root.realtalk.manager.GetMessageList2.toObject(message.getMessageList2, options);
                    if (options.oneofs)
                        object.body = "getMessageList2";
                }
                if (message.getOperatorList2 != null && message.hasOwnProperty("getOperatorList2")) {
                    object.getOperatorList2 = $root.realtalk.manager.GetOperatorList2.toObject(message.getOperatorList2, options);
                    if (options.oneofs)
                        object.body = "getOperatorList2";
                }
                return object;
            };

            /**
             * Converts this ClientMessage to JSON.
             * @function toJSON
             * @memberof realtalk.manager.ClientMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClientMessage;
        })();

        manager.CloseIssue = (function() {

            /**
             * Properties of a CloseIssue.
             * @memberof realtalk.manager
             * @interface ICloseIssue
             * @property {number|Long|null} [chatId] CloseIssue chatId
             * @property {number|Long|null} [messageId] CloseIssue messageId
             */

            /**
             * Constructs a new CloseIssue.
             * @memberof realtalk.manager
             * @classdesc Represents a CloseIssue.
             * @implements ICloseIssue
             * @constructor
             * @param {realtalk.manager.ICloseIssue=} [properties] Properties to set
             */
            function CloseIssue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CloseIssue chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.CloseIssue
             * @instance
             */
            CloseIssue.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CloseIssue messageId.
             * @member {number|Long} messageId
             * @memberof realtalk.manager.CloseIssue
             * @instance
             */
            CloseIssue.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CloseIssue instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {realtalk.manager.ICloseIssue=} [properties] Properties to set
             * @returns {realtalk.manager.CloseIssue} CloseIssue instance
             */
            CloseIssue.create = function create(properties) {
                return new CloseIssue(properties);
            };

            /**
             * Encodes the specified CloseIssue message. Does not implicitly {@link realtalk.manager.CloseIssue.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {realtalk.manager.ICloseIssue} message CloseIssue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CloseIssue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.messageId);
                return writer;
            };

            /**
             * Decodes a CloseIssue message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.CloseIssue} CloseIssue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CloseIssue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.CloseIssue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.messageId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CloseIssue message.
             * @function verify
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CloseIssue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                        return "messageId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CloseIssue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.CloseIssue} CloseIssue
             */
            CloseIssue.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.CloseIssue)
                    return object;
                var message = new $root.realtalk.manager.CloseIssue();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.messageId != null)
                    if ($util.Long)
                        (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = true;
                    else if (typeof object.messageId === "string")
                        message.messageId = parseInt(object.messageId, 10);
                    else if (typeof object.messageId === "number")
                        message.messageId = object.messageId;
                    else if (typeof object.messageId === "object")
                        message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CloseIssue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.CloseIssue
             * @static
             * @param {realtalk.manager.CloseIssue} message CloseIssue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CloseIssue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.messageId = options.longs === String ? "0" : 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (typeof message.messageId === "number")
                        object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                    else
                        object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber(true) : message.messageId;
                return object;
            };

            /**
             * Converts this CloseIssue to JSON.
             * @function toJSON
             * @memberof realtalk.manager.CloseIssue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CloseIssue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CloseIssue;
        })();

        manager.DateTime = (function() {

            /**
             * Properties of a DateTime.
             * @memberof realtalk.manager
             * @interface IDateTime
             * @property {number|Long|null} [millisecondsFromEpoch] DateTime millisecondsFromEpoch
             */

            /**
             * Constructs a new DateTime.
             * @memberof realtalk.manager
             * @classdesc Represents a DateTime.
             * @implements IDateTime
             * @constructor
             * @param {realtalk.manager.IDateTime=} [properties] Properties to set
             */
            function DateTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateTime millisecondsFromEpoch.
             * @member {number|Long} millisecondsFromEpoch
             * @memberof realtalk.manager.DateTime
             * @instance
             */
            DateTime.prototype.millisecondsFromEpoch = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new DateTime instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {realtalk.manager.IDateTime=} [properties] Properties to set
             * @returns {realtalk.manager.DateTime} DateTime instance
             */
            DateTime.create = function create(properties) {
                return new DateTime(properties);
            };

            /**
             * Encodes the specified DateTime message. Does not implicitly {@link realtalk.manager.DateTime.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {realtalk.manager.IDateTime} message DateTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.millisecondsFromEpoch != null && Object.hasOwnProperty.call(message, "millisecondsFromEpoch"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.millisecondsFromEpoch);
                return writer;
            };

            /**
             * Decodes a DateTime message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.DateTime} DateTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.DateTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.millisecondsFromEpoch = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DateTime message.
             * @function verify
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.millisecondsFromEpoch != null && message.hasOwnProperty("millisecondsFromEpoch"))
                    if (!$util.isInteger(message.millisecondsFromEpoch) && !(message.millisecondsFromEpoch && $util.isInteger(message.millisecondsFromEpoch.low) && $util.isInteger(message.millisecondsFromEpoch.high)))
                        return "millisecondsFromEpoch: integer|Long expected";
                return null;
            };

            /**
             * Creates a DateTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.DateTime} DateTime
             */
            DateTime.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.DateTime)
                    return object;
                var message = new $root.realtalk.manager.DateTime();
                if (object.millisecondsFromEpoch != null)
                    if ($util.Long)
                        (message.millisecondsFromEpoch = $util.Long.fromValue(object.millisecondsFromEpoch)).unsigned = false;
                    else if (typeof object.millisecondsFromEpoch === "string")
                        message.millisecondsFromEpoch = parseInt(object.millisecondsFromEpoch, 10);
                    else if (typeof object.millisecondsFromEpoch === "number")
                        message.millisecondsFromEpoch = object.millisecondsFromEpoch;
                    else if (typeof object.millisecondsFromEpoch === "object")
                        message.millisecondsFromEpoch = new $util.LongBits(object.millisecondsFromEpoch.low >>> 0, object.millisecondsFromEpoch.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a DateTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.DateTime
             * @static
             * @param {realtalk.manager.DateTime} message DateTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.millisecondsFromEpoch = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.millisecondsFromEpoch = options.longs === String ? "0" : 0;
                if (message.millisecondsFromEpoch != null && message.hasOwnProperty("millisecondsFromEpoch"))
                    if (typeof message.millisecondsFromEpoch === "number")
                        object.millisecondsFromEpoch = options.longs === String ? String(message.millisecondsFromEpoch) : message.millisecondsFromEpoch;
                    else
                        object.millisecondsFromEpoch = options.longs === String ? $util.Long.prototype.toString.call(message.millisecondsFromEpoch) : options.longs === Number ? new $util.LongBits(message.millisecondsFromEpoch.low >>> 0, message.millisecondsFromEpoch.high >>> 0).toNumber() : message.millisecondsFromEpoch;
                return object;
            };

            /**
             * Converts this DateTime to JSON.
             * @function toJSON
             * @memberof realtalk.manager.DateTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DateTime;
        })();

        manager.GetChatList = (function() {

            /**
             * Properties of a GetChatList.
             * @memberof realtalk.manager
             * @interface IGetChatList
             * @property {realtalk.manager.GetChatList.Type|null} [type] GetChatList type
             */

            /**
             * Constructs a new GetChatList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetChatList.
             * @implements IGetChatList
             * @constructor
             * @param {realtalk.manager.IGetChatList=} [properties] Properties to set
             */
            function GetChatList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetChatList type.
             * @member {realtalk.manager.GetChatList.Type} type
             * @memberof realtalk.manager.GetChatList
             * @instance
             */
            GetChatList.prototype.type = 0;

            /**
             * Creates a new GetChatList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {realtalk.manager.IGetChatList=} [properties] Properties to set
             * @returns {realtalk.manager.GetChatList} GetChatList instance
             */
            GetChatList.create = function create(properties) {
                return new GetChatList(properties);
            };

            /**
             * Encodes the specified GetChatList message. Does not implicitly {@link realtalk.manager.GetChatList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {realtalk.manager.IGetChatList} message GetChatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetChatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                return writer;
            };

            /**
             * Decodes a GetChatList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetChatList} GetChatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetChatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetChatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetChatList message.
             * @function verify
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetChatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetChatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetChatList} GetChatList
             */
            GetChatList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetChatList)
                    return object;
                var message = new $root.realtalk.manager.GetChatList();
                switch (object.type) {
                case "WITH_THIS_OPERATOR":
                case 0:
                    message.type = 0;
                    break;
                case "ALL_OPERATOR_ROOMS":
                case 1:
                    message.type = 1;
                    break;
                case "CLIENT_CHATS_WITH_UNREAD_CLIENT_MESSAGES":
                case 2:
                    message.type = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetChatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetChatList
             * @static
             * @param {realtalk.manager.GetChatList} message GetChatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetChatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.type = options.enums === String ? "WITH_THIS_OPERATOR" : 0;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.realtalk.manager.GetChatList.Type[message.type] : message.type;
                return object;
            };

            /**
             * Converts this GetChatList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetChatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetChatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name realtalk.manager.GetChatList.Type
             * @enum {number}
             * @property {number} WITH_THIS_OPERATOR=0 WITH_THIS_OPERATOR value
             * @property {number} ALL_OPERATOR_ROOMS=1 ALL_OPERATOR_ROOMS value
             * @property {number} CLIENT_CHATS_WITH_UNREAD_CLIENT_MESSAGES=2 CLIENT_CHATS_WITH_UNREAD_CLIENT_MESSAGES value
             */
            GetChatList.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WITH_THIS_OPERATOR"] = 0;
                values[valuesById[1] = "ALL_OPERATOR_ROOMS"] = 1;
                values[valuesById[2] = "CLIENT_CHATS_WITH_UNREAD_CLIENT_MESSAGES"] = 2;
                return values;
            })();

            return GetChatList;
        })();

        manager.GetChatUser = (function() {

            /**
             * Properties of a GetChatUser.
             * @memberof realtalk.manager
             * @interface IGetChatUser
             * @property {realtalk.manager.GetChatUser.IById|null} [byId] GetChatUser byId
             */

            /**
             * Constructs a new GetChatUser.
             * @memberof realtalk.manager
             * @classdesc Represents a GetChatUser.
             * @implements IGetChatUser
             * @constructor
             * @param {realtalk.manager.IGetChatUser=} [properties] Properties to set
             */
            function GetChatUser(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetChatUser byId.
             * @member {realtalk.manager.GetChatUser.IById|null|undefined} byId
             * @memberof realtalk.manager.GetChatUser
             * @instance
             */
            GetChatUser.prototype.byId = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetChatUser body.
             * @member {"byId"|undefined} body
             * @memberof realtalk.manager.GetChatUser
             * @instance
             */
            Object.defineProperty(GetChatUser.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["byId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetChatUser instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {realtalk.manager.IGetChatUser=} [properties] Properties to set
             * @returns {realtalk.manager.GetChatUser} GetChatUser instance
             */
            GetChatUser.create = function create(properties) {
                return new GetChatUser(properties);
            };

            /**
             * Encodes the specified GetChatUser message. Does not implicitly {@link realtalk.manager.GetChatUser.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {realtalk.manager.IGetChatUser} message GetChatUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetChatUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.byId != null && Object.hasOwnProperty.call(message, "byId"))
                    $root.realtalk.manager.GetChatUser.ById.encode(message.byId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetChatUser message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetChatUser} GetChatUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetChatUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetChatUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.byId = $root.realtalk.manager.GetChatUser.ById.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetChatUser message.
             * @function verify
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetChatUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.GetChatUser.ById.verify(message.byId);
                        if (error)
                            return "byId." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetChatUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetChatUser} GetChatUser
             */
            GetChatUser.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetChatUser)
                    return object;
                var message = new $root.realtalk.manager.GetChatUser();
                if (object.byId != null) {
                    if (typeof object.byId !== "object")
                        throw TypeError(".realtalk.manager.GetChatUser.byId: object expected");
                    message.byId = $root.realtalk.manager.GetChatUser.ById.fromObject(object.byId);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetChatUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetChatUser
             * @static
             * @param {realtalk.manager.GetChatUser} message GetChatUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetChatUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    object.byId = $root.realtalk.manager.GetChatUser.ById.toObject(message.byId, options);
                    if (options.oneofs)
                        object.body = "byId";
                }
                return object;
            };

            /**
             * Converts this GetChatUser to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetChatUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetChatUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetChatUser.ById = (function() {

                /**
                 * Properties of a ById.
                 * @memberof realtalk.manager.GetChatUser
                 * @interface IById
                 * @property {number|Long|null} [chatUserId] ById chatUserId
                 */

                /**
                 * Constructs a new ById.
                 * @memberof realtalk.manager.GetChatUser
                 * @classdesc Represents a ById.
                 * @implements IById
                 * @constructor
                 * @param {realtalk.manager.GetChatUser.IById=} [properties] Properties to set
                 */
                function ById(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ById chatUserId.
                 * @member {number|Long} chatUserId
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @instance
                 */
                ById.prototype.chatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new ById instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {realtalk.manager.GetChatUser.IById=} [properties] Properties to set
                 * @returns {realtalk.manager.GetChatUser.ById} ById instance
                 */
                ById.create = function create(properties) {
                    return new ById(properties);
                };

                /**
                 * Encodes the specified ById message. Does not implicitly {@link realtalk.manager.GetChatUser.ById.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {realtalk.manager.GetChatUser.IById} message ById message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ById.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.chatUserId != null && Object.hasOwnProperty.call(message, "chatUserId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatUserId);
                    return writer;
                };

                /**
                 * Decodes a ById message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetChatUser.ById} ById
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ById.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetChatUser.ById();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.chatUserId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ById message.
                 * @function verify
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ById.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                        if (!$util.isInteger(message.chatUserId) && !(message.chatUserId && $util.isInteger(message.chatUserId.low) && $util.isInteger(message.chatUserId.high)))
                            return "chatUserId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ById message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetChatUser.ById} ById
                 */
                ById.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetChatUser.ById)
                        return object;
                    var message = new $root.realtalk.manager.GetChatUser.ById();
                    if (object.chatUserId != null)
                        if ($util.Long)
                            (message.chatUserId = $util.Long.fromValue(object.chatUserId)).unsigned = true;
                        else if (typeof object.chatUserId === "string")
                            message.chatUserId = parseInt(object.chatUserId, 10);
                        else if (typeof object.chatUserId === "number")
                            message.chatUserId = object.chatUserId;
                        else if (typeof object.chatUserId === "object")
                            message.chatUserId = new $util.LongBits(object.chatUserId.low >>> 0, object.chatUserId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a ById message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @static
                 * @param {realtalk.manager.GetChatUser.ById} message ById
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ById.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.chatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.chatUserId = options.longs === String ? "0" : 0;
                    if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                        if (typeof message.chatUserId === "number")
                            object.chatUserId = options.longs === String ? String(message.chatUserId) : message.chatUserId;
                        else
                            object.chatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId) : options.longs === Number ? new $util.LongBits(message.chatUserId.low >>> 0, message.chatUserId.high >>> 0).toNumber(true) : message.chatUserId;
                    return object;
                };

                /**
                 * Converts this ById to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetChatUser.ById
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ById.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ById;
            })();

            return GetChatUser;
        })();

        manager.GetCurrentOperator = (function() {

            /**
             * Properties of a GetCurrentOperator.
             * @memberof realtalk.manager
             * @interface IGetCurrentOperator
             */

            /**
             * Constructs a new GetCurrentOperator.
             * @memberof realtalk.manager
             * @classdesc Represents a GetCurrentOperator.
             * @implements IGetCurrentOperator
             * @constructor
             * @param {realtalk.manager.IGetCurrentOperator=} [properties] Properties to set
             */
            function GetCurrentOperator(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentOperator instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {realtalk.manager.IGetCurrentOperator=} [properties] Properties to set
             * @returns {realtalk.manager.GetCurrentOperator} GetCurrentOperator instance
             */
            GetCurrentOperator.create = function create(properties) {
                return new GetCurrentOperator(properties);
            };

            /**
             * Encodes the specified GetCurrentOperator message. Does not implicitly {@link realtalk.manager.GetCurrentOperator.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {realtalk.manager.IGetCurrentOperator} message GetCurrentOperator message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentOperator.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentOperator message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetCurrentOperator} GetCurrentOperator
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentOperator.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetCurrentOperator();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentOperator message.
             * @function verify
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentOperator.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentOperator message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetCurrentOperator} GetCurrentOperator
             */
            GetCurrentOperator.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetCurrentOperator)
                    return object;
                return new $root.realtalk.manager.GetCurrentOperator();
            };

            /**
             * Creates a plain object from a GetCurrentOperator message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetCurrentOperator
             * @static
             * @param {realtalk.manager.GetCurrentOperator} message GetCurrentOperator
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentOperator.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentOperator to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetCurrentOperator
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentOperator.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentOperator;
        })();

        manager.GetCurrentOrganization = (function() {

            /**
             * Properties of a GetCurrentOrganization.
             * @memberof realtalk.manager
             * @interface IGetCurrentOrganization
             */

            /**
             * Constructs a new GetCurrentOrganization.
             * @memberof realtalk.manager
             * @classdesc Represents a GetCurrentOrganization.
             * @implements IGetCurrentOrganization
             * @constructor
             * @param {realtalk.manager.IGetCurrentOrganization=} [properties] Properties to set
             */
            function GetCurrentOrganization(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentOrganization instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {realtalk.manager.IGetCurrentOrganization=} [properties] Properties to set
             * @returns {realtalk.manager.GetCurrentOrganization} GetCurrentOrganization instance
             */
            GetCurrentOrganization.create = function create(properties) {
                return new GetCurrentOrganization(properties);
            };

            /**
             * Encodes the specified GetCurrentOrganization message. Does not implicitly {@link realtalk.manager.GetCurrentOrganization.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {realtalk.manager.IGetCurrentOrganization} message GetCurrentOrganization message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentOrganization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentOrganization message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetCurrentOrganization} GetCurrentOrganization
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentOrganization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetCurrentOrganization();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentOrganization message.
             * @function verify
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentOrganization.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentOrganization message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetCurrentOrganization} GetCurrentOrganization
             */
            GetCurrentOrganization.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetCurrentOrganization)
                    return object;
                return new $root.realtalk.manager.GetCurrentOrganization();
            };

            /**
             * Creates a plain object from a GetCurrentOrganization message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetCurrentOrganization
             * @static
             * @param {realtalk.manager.GetCurrentOrganization} message GetCurrentOrganization
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentOrganization.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentOrganization to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetCurrentOrganization
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentOrganization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentOrganization;
        })();

        manager.GetJwtKeyList = (function() {

            /**
             * Properties of a GetJwtKeyList.
             * @memberof realtalk.manager
             * @interface IGetJwtKeyList
             */

            /**
             * Constructs a new GetJwtKeyList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetJwtKeyList.
             * @implements IGetJwtKeyList
             * @constructor
             * @param {realtalk.manager.IGetJwtKeyList=} [properties] Properties to set
             */
            function GetJwtKeyList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetJwtKeyList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {realtalk.manager.IGetJwtKeyList=} [properties] Properties to set
             * @returns {realtalk.manager.GetJwtKeyList} GetJwtKeyList instance
             */
            GetJwtKeyList.create = function create(properties) {
                return new GetJwtKeyList(properties);
            };

            /**
             * Encodes the specified GetJwtKeyList message. Does not implicitly {@link realtalk.manager.GetJwtKeyList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {realtalk.manager.IGetJwtKeyList} message GetJwtKeyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetJwtKeyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetJwtKeyList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetJwtKeyList} GetJwtKeyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetJwtKeyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetJwtKeyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetJwtKeyList message.
             * @function verify
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetJwtKeyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetJwtKeyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetJwtKeyList} GetJwtKeyList
             */
            GetJwtKeyList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetJwtKeyList)
                    return object;
                return new $root.realtalk.manager.GetJwtKeyList();
            };

            /**
             * Creates a plain object from a GetJwtKeyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetJwtKeyList
             * @static
             * @param {realtalk.manager.GetJwtKeyList} message GetJwtKeyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetJwtKeyList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetJwtKeyList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetJwtKeyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetJwtKeyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetJwtKeyList;
        })();

        manager.GetLastActivity = (function() {

            /**
             * Properties of a GetLastActivity.
             * @memberof realtalk.manager
             * @interface IGetLastActivity
             * @property {number|Long|null} [chatUserId] GetLastActivity chatUserId
             */

            /**
             * Constructs a new GetLastActivity.
             * @memberof realtalk.manager
             * @classdesc Represents a GetLastActivity.
             * @implements IGetLastActivity
             * @constructor
             * @param {realtalk.manager.IGetLastActivity=} [properties] Properties to set
             */
            function GetLastActivity(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLastActivity chatUserId.
             * @member {number|Long} chatUserId
             * @memberof realtalk.manager.GetLastActivity
             * @instance
             */
            GetLastActivity.prototype.chatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetLastActivity instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {realtalk.manager.IGetLastActivity=} [properties] Properties to set
             * @returns {realtalk.manager.GetLastActivity} GetLastActivity instance
             */
            GetLastActivity.create = function create(properties) {
                return new GetLastActivity(properties);
            };

            /**
             * Encodes the specified GetLastActivity message. Does not implicitly {@link realtalk.manager.GetLastActivity.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {realtalk.manager.IGetLastActivity} message GetLastActivity message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLastActivity.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatUserId != null && Object.hasOwnProperty.call(message, "chatUserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatUserId);
                return writer;
            };

            /**
             * Decodes a GetLastActivity message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetLastActivity} GetLastActivity
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLastActivity.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetLastActivity();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatUserId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLastActivity message.
             * @function verify
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLastActivity.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (!$util.isInteger(message.chatUserId) && !(message.chatUserId && $util.isInteger(message.chatUserId.low) && $util.isInteger(message.chatUserId.high)))
                        return "chatUserId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetLastActivity message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetLastActivity} GetLastActivity
             */
            GetLastActivity.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetLastActivity)
                    return object;
                var message = new $root.realtalk.manager.GetLastActivity();
                if (object.chatUserId != null)
                    if ($util.Long)
                        (message.chatUserId = $util.Long.fromValue(object.chatUserId)).unsigned = true;
                    else if (typeof object.chatUserId === "string")
                        message.chatUserId = parseInt(object.chatUserId, 10);
                    else if (typeof object.chatUserId === "number")
                        message.chatUserId = object.chatUserId;
                    else if (typeof object.chatUserId === "object")
                        message.chatUserId = new $util.LongBits(object.chatUserId.low >>> 0, object.chatUserId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetLastActivity message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetLastActivity
             * @static
             * @param {realtalk.manager.GetLastActivity} message GetLastActivity
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLastActivity.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatUserId = options.longs === String ? "0" : 0;
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (typeof message.chatUserId === "number")
                        object.chatUserId = options.longs === String ? String(message.chatUserId) : message.chatUserId;
                    else
                        object.chatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId) : options.longs === Number ? new $util.LongBits(message.chatUserId.low >>> 0, message.chatUserId.high >>> 0).toNumber(true) : message.chatUserId;
                return object;
            };

            /**
             * Converts this GetLastActivity to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetLastActivity
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLastActivity.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLastActivity;
        })();

        manager.GetLineList = (function() {

            /**
             * Properties of a GetLineList.
             * @memberof realtalk.manager
             * @interface IGetLineList
             */

            /**
             * Constructs a new GetLineList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetLineList.
             * @implements IGetLineList
             * @constructor
             * @param {realtalk.manager.IGetLineList=} [properties] Properties to set
             */
            function GetLineList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetLineList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {realtalk.manager.IGetLineList=} [properties] Properties to set
             * @returns {realtalk.manager.GetLineList} GetLineList instance
             */
            GetLineList.create = function create(properties) {
                return new GetLineList(properties);
            };

            /**
             * Encodes the specified GetLineList message. Does not implicitly {@link realtalk.manager.GetLineList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {realtalk.manager.IGetLineList} message GetLineList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLineList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetLineList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetLineList} GetLineList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLineList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetLineList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLineList message.
             * @function verify
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLineList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetLineList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetLineList} GetLineList
             */
            GetLineList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetLineList)
                    return object;
                return new $root.realtalk.manager.GetLineList();
            };

            /**
             * Creates a plain object from a GetLineList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetLineList
             * @static
             * @param {realtalk.manager.GetLineList} message GetLineList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLineList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetLineList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetLineList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLineList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLineList;
        })();

        manager.GetMessageList = (function() {

            /**
             * Properties of a GetMessageList.
             * @memberof realtalk.manager
             * @interface IGetMessageList
             * @property {number|Long|null} [chatId] GetMessageList chatId
             * @property {number|Long|null} [lastMessageId] GetMessageList lastMessageId
             * @property {number|null} [count] GetMessageList count
             */

            /**
             * Constructs a new GetMessageList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetMessageList.
             * @implements IGetMessageList
             * @constructor
             * @param {realtalk.manager.IGetMessageList=} [properties] Properties to set
             */
            function GetMessageList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetMessageList chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.GetMessageList
             * @instance
             */
            GetMessageList.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetMessageList lastMessageId.
             * @member {number|Long} lastMessageId
             * @memberof realtalk.manager.GetMessageList
             * @instance
             */
            GetMessageList.prototype.lastMessageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetMessageList count.
             * @member {number} count
             * @memberof realtalk.manager.GetMessageList
             * @instance
             */
            GetMessageList.prototype.count = 0;

            /**
             * Creates a new GetMessageList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {realtalk.manager.IGetMessageList=} [properties] Properties to set
             * @returns {realtalk.manager.GetMessageList} GetMessageList instance
             */
            GetMessageList.create = function create(properties) {
                return new GetMessageList(properties);
            };

            /**
             * Encodes the specified GetMessageList message. Does not implicitly {@link realtalk.manager.GetMessageList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {realtalk.manager.IGetMessageList} message GetMessageList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMessageList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.lastMessageId != null && Object.hasOwnProperty.call(message, "lastMessageId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.lastMessageId);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.count);
                return writer;
            };

            /**
             * Decodes a GetMessageList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetMessageList} GetMessageList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMessageList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetMessageList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.lastMessageId = reader.uint64();
                        break;
                    case 3:
                        message.count = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetMessageList message.
             * @function verify
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetMessageList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.lastMessageId != null && message.hasOwnProperty("lastMessageId"))
                    if (!$util.isInteger(message.lastMessageId) && !(message.lastMessageId && $util.isInteger(message.lastMessageId.low) && $util.isInteger(message.lastMessageId.high)))
                        return "lastMessageId: integer|Long expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                return null;
            };

            /**
             * Creates a GetMessageList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetMessageList} GetMessageList
             */
            GetMessageList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetMessageList)
                    return object;
                var message = new $root.realtalk.manager.GetMessageList();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.lastMessageId != null)
                    if ($util.Long)
                        (message.lastMessageId = $util.Long.fromValue(object.lastMessageId)).unsigned = true;
                    else if (typeof object.lastMessageId === "string")
                        message.lastMessageId = parseInt(object.lastMessageId, 10);
                    else if (typeof object.lastMessageId === "number")
                        message.lastMessageId = object.lastMessageId;
                    else if (typeof object.lastMessageId === "object")
                        message.lastMessageId = new $util.LongBits(object.lastMessageId.low >>> 0, object.lastMessageId.high >>> 0).toNumber(true);
                if (object.count != null)
                    message.count = object.count >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a GetMessageList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetMessageList
             * @static
             * @param {realtalk.manager.GetMessageList} message GetMessageList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetMessageList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.lastMessageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastMessageId = options.longs === String ? "0" : 0;
                    object.count = 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.lastMessageId != null && message.hasOwnProperty("lastMessageId"))
                    if (typeof message.lastMessageId === "number")
                        object.lastMessageId = options.longs === String ? String(message.lastMessageId) : message.lastMessageId;
                    else
                        object.lastMessageId = options.longs === String ? $util.Long.prototype.toString.call(message.lastMessageId) : options.longs === Number ? new $util.LongBits(message.lastMessageId.low >>> 0, message.lastMessageId.high >>> 0).toNumber(true) : message.lastMessageId;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                return object;
            };

            /**
             * Converts this GetMessageList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetMessageList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetMessageList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetMessageList;
        })();

        manager.GetMessageList2 = (function() {

            /**
             * Properties of a GetMessageList2.
             * @memberof realtalk.manager
             * @interface IGetMessageList2
             * @property {number|Long|null} [chatId] GetMessageList2 chatId
             * @property {number|null} [count] GetMessageList2 count
             * @property {realtalk.manager.GetMessageList2.IFromNewToOld|null} [fromNewToOld] GetMessageList2 fromNewToOld
             * @property {realtalk.manager.GetMessageList2.IFromOldToNew|null} [fromOldToNew] GetMessageList2 fromOldToNew
             * @property {realtalk.manager.GetMessageList2.IFromOldUnreadToNew|null} [fromOldUnreadToNew] GetMessageList2 fromOldUnreadToNew
             */

            /**
             * Constructs a new GetMessageList2.
             * @memberof realtalk.manager
             * @classdesc Represents a GetMessageList2.
             * @implements IGetMessageList2
             * @constructor
             * @param {realtalk.manager.IGetMessageList2=} [properties] Properties to set
             */
            function GetMessageList2(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetMessageList2 chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            GetMessageList2.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetMessageList2 count.
             * @member {number} count
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            GetMessageList2.prototype.count = 0;

            /**
             * GetMessageList2 fromNewToOld.
             * @member {realtalk.manager.GetMessageList2.IFromNewToOld|null|undefined} fromNewToOld
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            GetMessageList2.prototype.fromNewToOld = null;

            /**
             * GetMessageList2 fromOldToNew.
             * @member {realtalk.manager.GetMessageList2.IFromOldToNew|null|undefined} fromOldToNew
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            GetMessageList2.prototype.fromOldToNew = null;

            /**
             * GetMessageList2 fromOldUnreadToNew.
             * @member {realtalk.manager.GetMessageList2.IFromOldUnreadToNew|null|undefined} fromOldUnreadToNew
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            GetMessageList2.prototype.fromOldUnreadToNew = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetMessageList2 type.
             * @member {"fromNewToOld"|"fromOldToNew"|"fromOldUnreadToNew"|undefined} type
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             */
            Object.defineProperty(GetMessageList2.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["fromNewToOld", "fromOldToNew", "fromOldUnreadToNew"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetMessageList2 instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {realtalk.manager.IGetMessageList2=} [properties] Properties to set
             * @returns {realtalk.manager.GetMessageList2} GetMessageList2 instance
             */
            GetMessageList2.create = function create(properties) {
                return new GetMessageList2(properties);
            };

            /**
             * Encodes the specified GetMessageList2 message. Does not implicitly {@link realtalk.manager.GetMessageList2.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {realtalk.manager.IGetMessageList2} message GetMessageList2 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMessageList2.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.count);
                if (message.fromNewToOld != null && Object.hasOwnProperty.call(message, "fromNewToOld"))
                    $root.realtalk.manager.GetMessageList2.FromNewToOld.encode(message.fromNewToOld, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.fromOldToNew != null && Object.hasOwnProperty.call(message, "fromOldToNew"))
                    $root.realtalk.manager.GetMessageList2.FromOldToNew.encode(message.fromOldToNew, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.fromOldUnreadToNew != null && Object.hasOwnProperty.call(message, "fromOldUnreadToNew"))
                    $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew.encode(message.fromOldUnreadToNew, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetMessageList2 message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetMessageList2} GetMessageList2
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMessageList2.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetMessageList2();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.count = reader.uint32();
                        break;
                    case 3:
                        message.fromNewToOld = $root.realtalk.manager.GetMessageList2.FromNewToOld.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.fromOldToNew = $root.realtalk.manager.GetMessageList2.FromOldToNew.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.fromOldUnreadToNew = $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetMessageList2 message.
             * @function verify
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetMessageList2.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.fromNewToOld != null && message.hasOwnProperty("fromNewToOld")) {
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetMessageList2.FromNewToOld.verify(message.fromNewToOld);
                        if (error)
                            return "fromNewToOld." + error;
                    }
                }
                if (message.fromOldToNew != null && message.hasOwnProperty("fromOldToNew")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetMessageList2.FromOldToNew.verify(message.fromOldToNew);
                        if (error)
                            return "fromOldToNew." + error;
                    }
                }
                if (message.fromOldUnreadToNew != null && message.hasOwnProperty("fromOldUnreadToNew")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew.verify(message.fromOldUnreadToNew);
                        if (error)
                            return "fromOldUnreadToNew." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetMessageList2 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetMessageList2} GetMessageList2
             */
            GetMessageList2.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetMessageList2)
                    return object;
                var message = new $root.realtalk.manager.GetMessageList2();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.fromNewToOld != null) {
                    if (typeof object.fromNewToOld !== "object")
                        throw TypeError(".realtalk.manager.GetMessageList2.fromNewToOld: object expected");
                    message.fromNewToOld = $root.realtalk.manager.GetMessageList2.FromNewToOld.fromObject(object.fromNewToOld);
                }
                if (object.fromOldToNew != null) {
                    if (typeof object.fromOldToNew !== "object")
                        throw TypeError(".realtalk.manager.GetMessageList2.fromOldToNew: object expected");
                    message.fromOldToNew = $root.realtalk.manager.GetMessageList2.FromOldToNew.fromObject(object.fromOldToNew);
                }
                if (object.fromOldUnreadToNew != null) {
                    if (typeof object.fromOldUnreadToNew !== "object")
                        throw TypeError(".realtalk.manager.GetMessageList2.fromOldUnreadToNew: object expected");
                    message.fromOldUnreadToNew = $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew.fromObject(object.fromOldUnreadToNew);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetMessageList2 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetMessageList2
             * @static
             * @param {realtalk.manager.GetMessageList2} message GetMessageList2
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetMessageList2.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.count = 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.fromNewToOld != null && message.hasOwnProperty("fromNewToOld")) {
                    object.fromNewToOld = $root.realtalk.manager.GetMessageList2.FromNewToOld.toObject(message.fromNewToOld, options);
                    if (options.oneofs)
                        object.type = "fromNewToOld";
                }
                if (message.fromOldToNew != null && message.hasOwnProperty("fromOldToNew")) {
                    object.fromOldToNew = $root.realtalk.manager.GetMessageList2.FromOldToNew.toObject(message.fromOldToNew, options);
                    if (options.oneofs)
                        object.type = "fromOldToNew";
                }
                if (message.fromOldUnreadToNew != null && message.hasOwnProperty("fromOldUnreadToNew")) {
                    object.fromOldUnreadToNew = $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew.toObject(message.fromOldUnreadToNew, options);
                    if (options.oneofs)
                        object.type = "fromOldUnreadToNew";
                }
                return object;
            };

            /**
             * Converts this GetMessageList2 to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetMessageList2
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetMessageList2.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetMessageList2.FromNewToOld = (function() {

                /**
                 * Properties of a FromNewToOld.
                 * @memberof realtalk.manager.GetMessageList2
                 * @interface IFromNewToOld
                 * @property {number|Long|null} [messageId] FromNewToOld messageId
                 */

                /**
                 * Constructs a new FromNewToOld.
                 * @memberof realtalk.manager.GetMessageList2
                 * @classdesc Represents a FromNewToOld.
                 * @implements IFromNewToOld
                 * @constructor
                 * @param {realtalk.manager.GetMessageList2.IFromNewToOld=} [properties] Properties to set
                 */
                function FromNewToOld(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FromNewToOld messageId.
                 * @member {number|Long} messageId
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @instance
                 */
                FromNewToOld.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new FromNewToOld instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromNewToOld=} [properties] Properties to set
                 * @returns {realtalk.manager.GetMessageList2.FromNewToOld} FromNewToOld instance
                 */
                FromNewToOld.create = function create(properties) {
                    return new FromNewToOld(properties);
                };

                /**
                 * Encodes the specified FromNewToOld message. Does not implicitly {@link realtalk.manager.GetMessageList2.FromNewToOld.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromNewToOld} message FromNewToOld message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FromNewToOld.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.messageId);
                    return writer;
                };

                /**
                 * Decodes a FromNewToOld message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetMessageList2.FromNewToOld} FromNewToOld
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FromNewToOld.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetMessageList2.FromNewToOld();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.messageId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a FromNewToOld message.
                 * @function verify
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FromNewToOld.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageId != null && message.hasOwnProperty("messageId"))
                        if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                            return "messageId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FromNewToOld message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetMessageList2.FromNewToOld} FromNewToOld
                 */
                FromNewToOld.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetMessageList2.FromNewToOld)
                        return object;
                    var message = new $root.realtalk.manager.GetMessageList2.FromNewToOld();
                    if (object.messageId != null)
                        if ($util.Long)
                            (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = true;
                        else if (typeof object.messageId === "string")
                            message.messageId = parseInt(object.messageId, 10);
                        else if (typeof object.messageId === "number")
                            message.messageId = object.messageId;
                        else if (typeof object.messageId === "object")
                            message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a FromNewToOld message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @static
                 * @param {realtalk.manager.GetMessageList2.FromNewToOld} message FromNewToOld
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FromNewToOld.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.messageId = options.longs === String ? "0" : 0;
                    if (message.messageId != null && message.hasOwnProperty("messageId"))
                        if (typeof message.messageId === "number")
                            object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                        else
                            object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber(true) : message.messageId;
                    return object;
                };

                /**
                 * Converts this FromNewToOld to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetMessageList2.FromNewToOld
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FromNewToOld.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FromNewToOld;
            })();

            GetMessageList2.FromOldToNew = (function() {

                /**
                 * Properties of a FromOldToNew.
                 * @memberof realtalk.manager.GetMessageList2
                 * @interface IFromOldToNew
                 * @property {number|Long|null} [messageId] FromOldToNew messageId
                 */

                /**
                 * Constructs a new FromOldToNew.
                 * @memberof realtalk.manager.GetMessageList2
                 * @classdesc Represents a FromOldToNew.
                 * @implements IFromOldToNew
                 * @constructor
                 * @param {realtalk.manager.GetMessageList2.IFromOldToNew=} [properties] Properties to set
                 */
                function FromOldToNew(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FromOldToNew messageId.
                 * @member {number|Long} messageId
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @instance
                 */
                FromOldToNew.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new FromOldToNew instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromOldToNew=} [properties] Properties to set
                 * @returns {realtalk.manager.GetMessageList2.FromOldToNew} FromOldToNew instance
                 */
                FromOldToNew.create = function create(properties) {
                    return new FromOldToNew(properties);
                };

                /**
                 * Encodes the specified FromOldToNew message. Does not implicitly {@link realtalk.manager.GetMessageList2.FromOldToNew.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromOldToNew} message FromOldToNew message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FromOldToNew.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.messageId);
                    return writer;
                };

                /**
                 * Decodes a FromOldToNew message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetMessageList2.FromOldToNew} FromOldToNew
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FromOldToNew.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetMessageList2.FromOldToNew();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.messageId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a FromOldToNew message.
                 * @function verify
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FromOldToNew.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageId != null && message.hasOwnProperty("messageId"))
                        if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                            return "messageId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FromOldToNew message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetMessageList2.FromOldToNew} FromOldToNew
                 */
                FromOldToNew.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetMessageList2.FromOldToNew)
                        return object;
                    var message = new $root.realtalk.manager.GetMessageList2.FromOldToNew();
                    if (object.messageId != null)
                        if ($util.Long)
                            (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = true;
                        else if (typeof object.messageId === "string")
                            message.messageId = parseInt(object.messageId, 10);
                        else if (typeof object.messageId === "number")
                            message.messageId = object.messageId;
                        else if (typeof object.messageId === "object")
                            message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a FromOldToNew message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.FromOldToNew} message FromOldToNew
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FromOldToNew.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.messageId = options.longs === String ? "0" : 0;
                    if (message.messageId != null && message.hasOwnProperty("messageId"))
                        if (typeof message.messageId === "number")
                            object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                        else
                            object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber(true) : message.messageId;
                    return object;
                };

                /**
                 * Converts this FromOldToNew to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetMessageList2.FromOldToNew
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FromOldToNew.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FromOldToNew;
            })();

            GetMessageList2.FromOldUnreadToNew = (function() {

                /**
                 * Properties of a FromOldUnreadToNew.
                 * @memberof realtalk.manager.GetMessageList2
                 * @interface IFromOldUnreadToNew
                 */

                /**
                 * Constructs a new FromOldUnreadToNew.
                 * @memberof realtalk.manager.GetMessageList2
                 * @classdesc Represents a FromOldUnreadToNew.
                 * @implements IFromOldUnreadToNew
                 * @constructor
                 * @param {realtalk.manager.GetMessageList2.IFromOldUnreadToNew=} [properties] Properties to set
                 */
                function FromOldUnreadToNew(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FromOldUnreadToNew instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromOldUnreadToNew=} [properties] Properties to set
                 * @returns {realtalk.manager.GetMessageList2.FromOldUnreadToNew} FromOldUnreadToNew instance
                 */
                FromOldUnreadToNew.create = function create(properties) {
                    return new FromOldUnreadToNew(properties);
                };

                /**
                 * Encodes the specified FromOldUnreadToNew message. Does not implicitly {@link realtalk.manager.GetMessageList2.FromOldUnreadToNew.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.IFromOldUnreadToNew} message FromOldUnreadToNew message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FromOldUnreadToNew.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Decodes a FromOldUnreadToNew message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetMessageList2.FromOldUnreadToNew} FromOldUnreadToNew
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FromOldUnreadToNew.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a FromOldUnreadToNew message.
                 * @function verify
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FromOldUnreadToNew.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FromOldUnreadToNew message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetMessageList2.FromOldUnreadToNew} FromOldUnreadToNew
                 */
                FromOldUnreadToNew.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew)
                        return object;
                    return new $root.realtalk.manager.GetMessageList2.FromOldUnreadToNew();
                };

                /**
                 * Creates a plain object from a FromOldUnreadToNew message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @static
                 * @param {realtalk.manager.GetMessageList2.FromOldUnreadToNew} message FromOldUnreadToNew
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FromOldUnreadToNew.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FromOldUnreadToNew to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetMessageList2.FromOldUnreadToNew
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FromOldUnreadToNew.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FromOldUnreadToNew;
            })();

            return GetMessageList2;
        })();

        manager.GetNumberUnreadMessages = (function() {

            /**
             * Properties of a GetNumberUnreadMessages.
             * @memberof realtalk.manager
             * @interface IGetNumberUnreadMessages
             * @property {number|Long|null} [chatId] GetNumberUnreadMessages chatId
             */

            /**
             * Constructs a new GetNumberUnreadMessages.
             * @memberof realtalk.manager
             * @classdesc Represents a GetNumberUnreadMessages.
             * @implements IGetNumberUnreadMessages
             * @constructor
             * @param {realtalk.manager.IGetNumberUnreadMessages=} [properties] Properties to set
             */
            function GetNumberUnreadMessages(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetNumberUnreadMessages chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @instance
             */
            GetNumberUnreadMessages.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetNumberUnreadMessages instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {realtalk.manager.IGetNumberUnreadMessages=} [properties] Properties to set
             * @returns {realtalk.manager.GetNumberUnreadMessages} GetNumberUnreadMessages instance
             */
            GetNumberUnreadMessages.create = function create(properties) {
                return new GetNumberUnreadMessages(properties);
            };

            /**
             * Encodes the specified GetNumberUnreadMessages message. Does not implicitly {@link realtalk.manager.GetNumberUnreadMessages.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {realtalk.manager.IGetNumberUnreadMessages} message GetNumberUnreadMessages message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetNumberUnreadMessages.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a GetNumberUnreadMessages message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetNumberUnreadMessages} GetNumberUnreadMessages
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetNumberUnreadMessages.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetNumberUnreadMessages();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetNumberUnreadMessages message.
             * @function verify
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetNumberUnreadMessages.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetNumberUnreadMessages message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetNumberUnreadMessages} GetNumberUnreadMessages
             */
            GetNumberUnreadMessages.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetNumberUnreadMessages)
                    return object;
                var message = new $root.realtalk.manager.GetNumberUnreadMessages();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetNumberUnreadMessages message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @static
             * @param {realtalk.manager.GetNumberUnreadMessages} message GetNumberUnreadMessages
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetNumberUnreadMessages.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this GetNumberUnreadMessages to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetNumberUnreadMessages
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetNumberUnreadMessages.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetNumberUnreadMessages;
        })();

        manager.GetOperatorList = (function() {

            /**
             * Properties of a GetOperatorList.
             * @memberof realtalk.manager
             * @interface IGetOperatorList
             * @property {boolean|null} [includingDeleted] GetOperatorList includingDeleted
             */

            /**
             * Constructs a new GetOperatorList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetOperatorList.
             * @implements IGetOperatorList
             * @constructor
             * @param {realtalk.manager.IGetOperatorList=} [properties] Properties to set
             */
            function GetOperatorList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOperatorList includingDeleted.
             * @member {boolean} includingDeleted
             * @memberof realtalk.manager.GetOperatorList
             * @instance
             */
            GetOperatorList.prototype.includingDeleted = false;

            /**
             * Creates a new GetOperatorList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {realtalk.manager.IGetOperatorList=} [properties] Properties to set
             * @returns {realtalk.manager.GetOperatorList} GetOperatorList instance
             */
            GetOperatorList.create = function create(properties) {
                return new GetOperatorList(properties);
            };

            /**
             * Encodes the specified GetOperatorList message. Does not implicitly {@link realtalk.manager.GetOperatorList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {realtalk.manager.IGetOperatorList} message GetOperatorList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOperatorList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.includingDeleted != null && Object.hasOwnProperty.call(message, "includingDeleted"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includingDeleted);
                return writer;
            };

            /**
             * Decodes a GetOperatorList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetOperatorList} GetOperatorList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOperatorList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.includingDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOperatorList message.
             * @function verify
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOperatorList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.includingDeleted != null && message.hasOwnProperty("includingDeleted"))
                    if (typeof message.includingDeleted !== "boolean")
                        return "includingDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetOperatorList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetOperatorList} GetOperatorList
             */
            GetOperatorList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetOperatorList)
                    return object;
                var message = new $root.realtalk.manager.GetOperatorList();
                if (object.includingDeleted != null)
                    message.includingDeleted = Boolean(object.includingDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetOperatorList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetOperatorList
             * @static
             * @param {realtalk.manager.GetOperatorList} message GetOperatorList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOperatorList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.includingDeleted = false;
                if (message.includingDeleted != null && message.hasOwnProperty("includingDeleted"))
                    object.includingDeleted = message.includingDeleted;
                return object;
            };

            /**
             * Converts this GetOperatorList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetOperatorList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOperatorList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOperatorList;
        })();

        manager.GetOperatorList2 = (function() {

            /**
             * Properties of a GetOperatorList2.
             * @memberof realtalk.manager
             * @interface IGetOperatorList2
             * @property {realtalk.manager.GetOperatorList2.IAllOperators|null} [allOperators] GetOperatorList2 allOperators
             * @property {realtalk.manager.GetOperatorList2.IByExternalID|null} [byExternalId] GetOperatorList2 byExternalId
             * @property {realtalk.manager.GetOperatorList2.IByID|null} [byId] GetOperatorList2 byId
             */

            /**
             * Constructs a new GetOperatorList2.
             * @memberof realtalk.manager
             * @classdesc Represents a GetOperatorList2.
             * @implements IGetOperatorList2
             * @constructor
             * @param {realtalk.manager.IGetOperatorList2=} [properties] Properties to set
             */
            function GetOperatorList2(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOperatorList2 allOperators.
             * @member {realtalk.manager.GetOperatorList2.IAllOperators|null|undefined} allOperators
             * @memberof realtalk.manager.GetOperatorList2
             * @instance
             */
            GetOperatorList2.prototype.allOperators = null;

            /**
             * GetOperatorList2 byExternalId.
             * @member {realtalk.manager.GetOperatorList2.IByExternalID|null|undefined} byExternalId
             * @memberof realtalk.manager.GetOperatorList2
             * @instance
             */
            GetOperatorList2.prototype.byExternalId = null;

            /**
             * GetOperatorList2 byId.
             * @member {realtalk.manager.GetOperatorList2.IByID|null|undefined} byId
             * @memberof realtalk.manager.GetOperatorList2
             * @instance
             */
            GetOperatorList2.prototype.byId = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetOperatorList2 type.
             * @member {"allOperators"|"byExternalId"|"byId"|undefined} type
             * @memberof realtalk.manager.GetOperatorList2
             * @instance
             */
            Object.defineProperty(GetOperatorList2.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["allOperators", "byExternalId", "byId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetOperatorList2 instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {realtalk.manager.IGetOperatorList2=} [properties] Properties to set
             * @returns {realtalk.manager.GetOperatorList2} GetOperatorList2 instance
             */
            GetOperatorList2.create = function create(properties) {
                return new GetOperatorList2(properties);
            };

            /**
             * Encodes the specified GetOperatorList2 message. Does not implicitly {@link realtalk.manager.GetOperatorList2.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {realtalk.manager.IGetOperatorList2} message GetOperatorList2 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOperatorList2.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allOperators != null && Object.hasOwnProperty.call(message, "allOperators"))
                    $root.realtalk.manager.GetOperatorList2.AllOperators.encode(message.allOperators, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.byExternalId != null && Object.hasOwnProperty.call(message, "byExternalId"))
                    $root.realtalk.manager.GetOperatorList2.ByExternalID.encode(message.byExternalId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.byId != null && Object.hasOwnProperty.call(message, "byId"))
                    $root.realtalk.manager.GetOperatorList2.ByID.encode(message.byId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetOperatorList2 message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetOperatorList2} GetOperatorList2
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOperatorList2.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorList2();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.allOperators = $root.realtalk.manager.GetOperatorList2.AllOperators.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.byExternalId = $root.realtalk.manager.GetOperatorList2.ByExternalID.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.byId = $root.realtalk.manager.GetOperatorList2.ByID.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOperatorList2 message.
             * @function verify
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOperatorList2.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.allOperators != null && message.hasOwnProperty("allOperators")) {
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorList2.AllOperators.verify(message.allOperators);
                        if (error)
                            return "allOperators." + error;
                    }
                }
                if (message.byExternalId != null && message.hasOwnProperty("byExternalId")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorList2.ByExternalID.verify(message.byExternalId);
                        if (error)
                            return "byExternalId." + error;
                    }
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.realtalk.manager.GetOperatorList2.ByID.verify(message.byId);
                        if (error)
                            return "byId." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetOperatorList2 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetOperatorList2} GetOperatorList2
             */
            GetOperatorList2.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetOperatorList2)
                    return object;
                var message = new $root.realtalk.manager.GetOperatorList2();
                if (object.allOperators != null) {
                    if (typeof object.allOperators !== "object")
                        throw TypeError(".realtalk.manager.GetOperatorList2.allOperators: object expected");
                    message.allOperators = $root.realtalk.manager.GetOperatorList2.AllOperators.fromObject(object.allOperators);
                }
                if (object.byExternalId != null) {
                    if (typeof object.byExternalId !== "object")
                        throw TypeError(".realtalk.manager.GetOperatorList2.byExternalId: object expected");
                    message.byExternalId = $root.realtalk.manager.GetOperatorList2.ByExternalID.fromObject(object.byExternalId);
                }
                if (object.byId != null) {
                    if (typeof object.byId !== "object")
                        throw TypeError(".realtalk.manager.GetOperatorList2.byId: object expected");
                    message.byId = $root.realtalk.manager.GetOperatorList2.ByID.fromObject(object.byId);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOperatorList2 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetOperatorList2
             * @static
             * @param {realtalk.manager.GetOperatorList2} message GetOperatorList2
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOperatorList2.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.allOperators != null && message.hasOwnProperty("allOperators")) {
                    object.allOperators = $root.realtalk.manager.GetOperatorList2.AllOperators.toObject(message.allOperators, options);
                    if (options.oneofs)
                        object.type = "allOperators";
                }
                if (message.byExternalId != null && message.hasOwnProperty("byExternalId")) {
                    object.byExternalId = $root.realtalk.manager.GetOperatorList2.ByExternalID.toObject(message.byExternalId, options);
                    if (options.oneofs)
                        object.type = "byExternalId";
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    object.byId = $root.realtalk.manager.GetOperatorList2.ByID.toObject(message.byId, options);
                    if (options.oneofs)
                        object.type = "byId";
                }
                return object;
            };

            /**
             * Converts this GetOperatorList2 to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetOperatorList2
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOperatorList2.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetOperatorList2.AllOperators = (function() {

                /**
                 * Properties of an AllOperators.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @interface IAllOperators
                 * @property {boolean|null} [includingDeleted] AllOperators includingDeleted
                 */

                /**
                 * Constructs a new AllOperators.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @classdesc Represents an AllOperators.
                 * @implements IAllOperators
                 * @constructor
                 * @param {realtalk.manager.GetOperatorList2.IAllOperators=} [properties] Properties to set
                 */
                function AllOperators(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AllOperators includingDeleted.
                 * @member {boolean} includingDeleted
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @instance
                 */
                AllOperators.prototype.includingDeleted = false;

                /**
                 * Creates a new AllOperators instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IAllOperators=} [properties] Properties to set
                 * @returns {realtalk.manager.GetOperatorList2.AllOperators} AllOperators instance
                 */
                AllOperators.create = function create(properties) {
                    return new AllOperators(properties);
                };

                /**
                 * Encodes the specified AllOperators message. Does not implicitly {@link realtalk.manager.GetOperatorList2.AllOperators.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IAllOperators} message AllOperators message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AllOperators.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.includingDeleted != null && Object.hasOwnProperty.call(message, "includingDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includingDeleted);
                    return writer;
                };

                /**
                 * Decodes an AllOperators message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetOperatorList2.AllOperators} AllOperators
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AllOperators.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorList2.AllOperators();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.includingDeleted = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an AllOperators message.
                 * @function verify
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AllOperators.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.includingDeleted != null && message.hasOwnProperty("includingDeleted"))
                        if (typeof message.includingDeleted !== "boolean")
                            return "includingDeleted: boolean expected";
                    return null;
                };

                /**
                 * Creates an AllOperators message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetOperatorList2.AllOperators} AllOperators
                 */
                AllOperators.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetOperatorList2.AllOperators)
                        return object;
                    var message = new $root.realtalk.manager.GetOperatorList2.AllOperators();
                    if (object.includingDeleted != null)
                        message.includingDeleted = Boolean(object.includingDeleted);
                    return message;
                };

                /**
                 * Creates a plain object from an AllOperators message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.AllOperators} message AllOperators
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AllOperators.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.includingDeleted = false;
                    if (message.includingDeleted != null && message.hasOwnProperty("includingDeleted"))
                        object.includingDeleted = message.includingDeleted;
                    return object;
                };

                /**
                 * Converts this AllOperators to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetOperatorList2.AllOperators
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AllOperators.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AllOperators;
            })();

            GetOperatorList2.ByExternalID = (function() {

                /**
                 * Properties of a ByExternalID.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @interface IByExternalID
                 * @property {Array.<string>|null} [externalId] ByExternalID externalId
                 */

                /**
                 * Constructs a new ByExternalID.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @classdesc Represents a ByExternalID.
                 * @implements IByExternalID
                 * @constructor
                 * @param {realtalk.manager.GetOperatorList2.IByExternalID=} [properties] Properties to set
                 */
                function ByExternalID(properties) {
                    this.externalId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByExternalID externalId.
                 * @member {Array.<string>} externalId
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @instance
                 */
                ByExternalID.prototype.externalId = $util.emptyArray;

                /**
                 * Creates a new ByExternalID instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IByExternalID=} [properties] Properties to set
                 * @returns {realtalk.manager.GetOperatorList2.ByExternalID} ByExternalID instance
                 */
                ByExternalID.create = function create(properties) {
                    return new ByExternalID(properties);
                };

                /**
                 * Encodes the specified ByExternalID message. Does not implicitly {@link realtalk.manager.GetOperatorList2.ByExternalID.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IByExternalID} message ByExternalID message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByExternalID.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.externalId != null && message.externalId.length)
                        for (var i = 0; i < message.externalId.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalId[i]);
                    return writer;
                };

                /**
                 * Decodes a ByExternalID message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetOperatorList2.ByExternalID} ByExternalID
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByExternalID.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorList2.ByExternalID();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.externalId && message.externalId.length))
                                message.externalId = [];
                            message.externalId.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByExternalID message.
                 * @function verify
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByExternalID.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.externalId != null && message.hasOwnProperty("externalId")) {
                        if (!Array.isArray(message.externalId))
                            return "externalId: array expected";
                        for (var i = 0; i < message.externalId.length; ++i)
                            if (!$util.isString(message.externalId[i]))
                                return "externalId: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ByExternalID message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetOperatorList2.ByExternalID} ByExternalID
                 */
                ByExternalID.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetOperatorList2.ByExternalID)
                        return object;
                    var message = new $root.realtalk.manager.GetOperatorList2.ByExternalID();
                    if (object.externalId) {
                        if (!Array.isArray(object.externalId))
                            throw TypeError(".realtalk.manager.GetOperatorList2.ByExternalID.externalId: array expected");
                        message.externalId = [];
                        for (var i = 0; i < object.externalId.length; ++i)
                            message.externalId[i] = String(object.externalId[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ByExternalID message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.ByExternalID} message ByExternalID
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByExternalID.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.externalId = [];
                    if (message.externalId && message.externalId.length) {
                        object.externalId = [];
                        for (var j = 0; j < message.externalId.length; ++j)
                            object.externalId[j] = message.externalId[j];
                    }
                    return object;
                };

                /**
                 * Converts this ByExternalID to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetOperatorList2.ByExternalID
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByExternalID.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByExternalID;
            })();

            GetOperatorList2.ByID = (function() {

                /**
                 * Properties of a ByID.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @interface IByID
                 * @property {Array.<number|Long>|null} [id] ByID id
                 */

                /**
                 * Constructs a new ByID.
                 * @memberof realtalk.manager.GetOperatorList2
                 * @classdesc Represents a ByID.
                 * @implements IByID
                 * @constructor
                 * @param {realtalk.manager.GetOperatorList2.IByID=} [properties] Properties to set
                 */
                function ByID(properties) {
                    this.id = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByID id.
                 * @member {Array.<number|Long>} id
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @instance
                 */
                ByID.prototype.id = $util.emptyArray;

                /**
                 * Creates a new ByID instance using the specified properties.
                 * @function create
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IByID=} [properties] Properties to set
                 * @returns {realtalk.manager.GetOperatorList2.ByID} ByID instance
                 */
                ByID.create = function create(properties) {
                    return new ByID(properties);
                };

                /**
                 * Encodes the specified ByID message. Does not implicitly {@link realtalk.manager.GetOperatorList2.ByID.verify|verify} messages.
                 * @function encode
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.IByID} message ByID message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByID.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && message.id.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.id.length; ++i)
                            writer.uint64(message.id[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Decodes a ByID message from the specified reader or buffer.
                 * @function decode
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {realtalk.manager.GetOperatorList2.ByID} ByID
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByID.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorList2.ByID();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.id && message.id.length))
                                message.id = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.id.push(reader.uint64());
                            } else
                                message.id.push(reader.uint64());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByID message.
                 * @function verify
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByID.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id")) {
                        if (!Array.isArray(message.id))
                            return "id: array expected";
                        for (var i = 0; i < message.id.length; ++i)
                            if (!$util.isInteger(message.id[i]) && !(message.id[i] && $util.isInteger(message.id[i].low) && $util.isInteger(message.id[i].high)))
                                return "id: integer|Long[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ByID message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {realtalk.manager.GetOperatorList2.ByID} ByID
                 */
                ByID.fromObject = function fromObject(object) {
                    if (object instanceof $root.realtalk.manager.GetOperatorList2.ByID)
                        return object;
                    var message = new $root.realtalk.manager.GetOperatorList2.ByID();
                    if (object.id) {
                        if (!Array.isArray(object.id))
                            throw TypeError(".realtalk.manager.GetOperatorList2.ByID.id: array expected");
                        message.id = [];
                        for (var i = 0; i < object.id.length; ++i)
                            if ($util.Long)
                                (message.id[i] = $util.Long.fromValue(object.id[i])).unsigned = true;
                            else if (typeof object.id[i] === "string")
                                message.id[i] = parseInt(object.id[i], 10);
                            else if (typeof object.id[i] === "number")
                                message.id[i] = object.id[i];
                            else if (typeof object.id[i] === "object")
                                message.id[i] = new $util.LongBits(object.id[i].low >>> 0, object.id[i].high >>> 0).toNumber(true);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ByID message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @static
                 * @param {realtalk.manager.GetOperatorList2.ByID} message ByID
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByID.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.id = [];
                    if (message.id && message.id.length) {
                        object.id = [];
                        for (var j = 0; j < message.id.length; ++j)
                            if (typeof message.id[j] === "number")
                                object.id[j] = options.longs === String ? String(message.id[j]) : message.id[j];
                            else
                                object.id[j] = options.longs === String ? $util.Long.prototype.toString.call(message.id[j]) : options.longs === Number ? new $util.LongBits(message.id[j].low >>> 0, message.id[j].high >>> 0).toNumber(true) : message.id[j];
                    }
                    return object;
                };

                /**
                 * Converts this ByID to JSON.
                 * @function toJSON
                 * @memberof realtalk.manager.GetOperatorList2.ByID
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByID.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByID;
            })();

            return GetOperatorList2;
        })();

        manager.GetOperatorReportList = (function() {

            /**
             * Properties of a GetOperatorReportList.
             * @memberof realtalk.manager
             * @interface IGetOperatorReportList
             * @property {realtalk.manager.IDateTime|null} [from] GetOperatorReportList from
             * @property {realtalk.manager.IDateTime|null} [to] GetOperatorReportList to
             * @property {Array.<number|Long>|null} [operatorId] GetOperatorReportList operatorId
             */

            /**
             * Constructs a new GetOperatorReportList.
             * @memberof realtalk.manager
             * @classdesc Represents a GetOperatorReportList.
             * @implements IGetOperatorReportList
             * @constructor
             * @param {realtalk.manager.IGetOperatorReportList=} [properties] Properties to set
             */
            function GetOperatorReportList(properties) {
                this.operatorId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOperatorReportList from.
             * @member {realtalk.manager.IDateTime|null|undefined} from
             * @memberof realtalk.manager.GetOperatorReportList
             * @instance
             */
            GetOperatorReportList.prototype.from = null;

            /**
             * GetOperatorReportList to.
             * @member {realtalk.manager.IDateTime|null|undefined} to
             * @memberof realtalk.manager.GetOperatorReportList
             * @instance
             */
            GetOperatorReportList.prototype.to = null;

            /**
             * GetOperatorReportList operatorId.
             * @member {Array.<number|Long>} operatorId
             * @memberof realtalk.manager.GetOperatorReportList
             * @instance
             */
            GetOperatorReportList.prototype.operatorId = $util.emptyArray;

            /**
             * Creates a new GetOperatorReportList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {realtalk.manager.IGetOperatorReportList=} [properties] Properties to set
             * @returns {realtalk.manager.GetOperatorReportList} GetOperatorReportList instance
             */
            GetOperatorReportList.create = function create(properties) {
                return new GetOperatorReportList(properties);
            };

            /**
             * Encodes the specified GetOperatorReportList message. Does not implicitly {@link realtalk.manager.GetOperatorReportList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {realtalk.manager.IGetOperatorReportList} message GetOperatorReportList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOperatorReportList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    $root.realtalk.manager.DateTime.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    $root.realtalk.manager.DateTime.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.operatorId != null && message.operatorId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.operatorId.length; ++i)
                        writer.uint64(message.operatorId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetOperatorReportList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.GetOperatorReportList} GetOperatorReportList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOperatorReportList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.GetOperatorReportList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.from = $root.realtalk.manager.DateTime.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.to = $root.realtalk.manager.DateTime.decode(reader, reader.uint32());
                        break;
                    case 3:
                        if (!(message.operatorId && message.operatorId.length))
                            message.operatorId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.operatorId.push(reader.uint64());
                        } else
                            message.operatorId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOperatorReportList message.
             * @function verify
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOperatorReportList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from")) {
                    var error = $root.realtalk.manager.DateTime.verify(message.from);
                    if (error)
                        return "from." + error;
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    var error = $root.realtalk.manager.DateTime.verify(message.to);
                    if (error)
                        return "to." + error;
                }
                if (message.operatorId != null && message.hasOwnProperty("operatorId")) {
                    if (!Array.isArray(message.operatorId))
                        return "operatorId: array expected";
                    for (var i = 0; i < message.operatorId.length; ++i)
                        if (!$util.isInteger(message.operatorId[i]) && !(message.operatorId[i] && $util.isInteger(message.operatorId[i].low) && $util.isInteger(message.operatorId[i].high)))
                            return "operatorId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetOperatorReportList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.GetOperatorReportList} GetOperatorReportList
             */
            GetOperatorReportList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.GetOperatorReportList)
                    return object;
                var message = new $root.realtalk.manager.GetOperatorReportList();
                if (object.from != null) {
                    if (typeof object.from !== "object")
                        throw TypeError(".realtalk.manager.GetOperatorReportList.from: object expected");
                    message.from = $root.realtalk.manager.DateTime.fromObject(object.from);
                }
                if (object.to != null) {
                    if (typeof object.to !== "object")
                        throw TypeError(".realtalk.manager.GetOperatorReportList.to: object expected");
                    message.to = $root.realtalk.manager.DateTime.fromObject(object.to);
                }
                if (object.operatorId) {
                    if (!Array.isArray(object.operatorId))
                        throw TypeError(".realtalk.manager.GetOperatorReportList.operatorId: array expected");
                    message.operatorId = [];
                    for (var i = 0; i < object.operatorId.length; ++i)
                        if ($util.Long)
                            (message.operatorId[i] = $util.Long.fromValue(object.operatorId[i])).unsigned = true;
                        else if (typeof object.operatorId[i] === "string")
                            message.operatorId[i] = parseInt(object.operatorId[i], 10);
                        else if (typeof object.operatorId[i] === "number")
                            message.operatorId[i] = object.operatorId[i];
                        else if (typeof object.operatorId[i] === "object")
                            message.operatorId[i] = new $util.LongBits(object.operatorId[i].low >>> 0, object.operatorId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOperatorReportList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.GetOperatorReportList
             * @static
             * @param {realtalk.manager.GetOperatorReportList} message GetOperatorReportList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOperatorReportList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.operatorId = [];
                if (options.defaults) {
                    object.from = null;
                    object.to = null;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = $root.realtalk.manager.DateTime.toObject(message.from, options);
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = $root.realtalk.manager.DateTime.toObject(message.to, options);
                if (message.operatorId && message.operatorId.length) {
                    object.operatorId = [];
                    for (var j = 0; j < message.operatorId.length; ++j)
                        if (typeof message.operatorId[j] === "number")
                            object.operatorId[j] = options.longs === String ? String(message.operatorId[j]) : message.operatorId[j];
                        else
                            object.operatorId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId[j]) : options.longs === Number ? new $util.LongBits(message.operatorId[j].low >>> 0, message.operatorId[j].high >>> 0).toNumber(true) : message.operatorId[j];
                }
                return object;
            };

            /**
             * Converts this GetOperatorReportList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.GetOperatorReportList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOperatorReportList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOperatorReportList;
        })();

        manager.JoinToChat = (function() {

            /**
             * Properties of a JoinToChat.
             * @memberof realtalk.manager
             * @interface IJoinToChat
             * @property {number|Long|null} [chatId] JoinToChat chatId
             */

            /**
             * Constructs a new JoinToChat.
             * @memberof realtalk.manager
             * @classdesc Represents a JoinToChat.
             * @implements IJoinToChat
             * @constructor
             * @param {realtalk.manager.IJoinToChat=} [properties] Properties to set
             */
            function JoinToChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * JoinToChat chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.JoinToChat
             * @instance
             */
            JoinToChat.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new JoinToChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {realtalk.manager.IJoinToChat=} [properties] Properties to set
             * @returns {realtalk.manager.JoinToChat} JoinToChat instance
             */
            JoinToChat.create = function create(properties) {
                return new JoinToChat(properties);
            };

            /**
             * Encodes the specified JoinToChat message. Does not implicitly {@link realtalk.manager.JoinToChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {realtalk.manager.IJoinToChat} message JoinToChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JoinToChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a JoinToChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.JoinToChat} JoinToChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JoinToChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.JoinToChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a JoinToChat message.
             * @function verify
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JoinToChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a JoinToChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.JoinToChat} JoinToChat
             */
            JoinToChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.JoinToChat)
                    return object;
                var message = new $root.realtalk.manager.JoinToChat();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a JoinToChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.JoinToChat
             * @static
             * @param {realtalk.manager.JoinToChat} message JoinToChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JoinToChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this JoinToChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.JoinToChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JoinToChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return JoinToChat;
        })();

        /**
         * JwtKeyAlgorithm enum.
         * @name realtalk.manager.JwtKeyAlgorithm
         * @enum {number}
         * @property {number} JKA_RS256=0 JKA_RS256 value
         * @property {number} JKA_HS256=1 JKA_HS256 value
         */
        manager.JwtKeyAlgorithm = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "JKA_RS256"] = 0;
            values[valuesById[1] = "JKA_HS256"] = 1;
            return values;
        })();

        manager.JwtKey = (function() {

            /**
             * Properties of a JwtKey.
             * @memberof realtalk.manager
             * @interface IJwtKey
             * @property {number|Long|null} [id] JwtKey id
             * @property {realtalk.manager.JwtKeyAlgorithm|null} [algorithm] JwtKey algorithm
             * @property {string|null} [key] JwtKey key
             * @property {string|null} [issuer] JwtKey issuer
             * @property {boolean|null} [deleted] JwtKey deleted
             */

            /**
             * Constructs a new JwtKey.
             * @memberof realtalk.manager
             * @classdesc Represents a JwtKey.
             * @implements IJwtKey
             * @constructor
             * @param {realtalk.manager.IJwtKey=} [properties] Properties to set
             */
            function JwtKey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * JwtKey id.
             * @member {number|Long} id
             * @memberof realtalk.manager.JwtKey
             * @instance
             */
            JwtKey.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * JwtKey algorithm.
             * @member {realtalk.manager.JwtKeyAlgorithm} algorithm
             * @memberof realtalk.manager.JwtKey
             * @instance
             */
            JwtKey.prototype.algorithm = 0;

            /**
             * JwtKey key.
             * @member {string} key
             * @memberof realtalk.manager.JwtKey
             * @instance
             */
            JwtKey.prototype.key = "";

            /**
             * JwtKey issuer.
             * @member {string} issuer
             * @memberof realtalk.manager.JwtKey
             * @instance
             */
            JwtKey.prototype.issuer = "";

            /**
             * JwtKey deleted.
             * @member {boolean} deleted
             * @memberof realtalk.manager.JwtKey
             * @instance
             */
            JwtKey.prototype.deleted = false;

            /**
             * Creates a new JwtKey instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {realtalk.manager.IJwtKey=} [properties] Properties to set
             * @returns {realtalk.manager.JwtKey} JwtKey instance
             */
            JwtKey.create = function create(properties) {
                return new JwtKey(properties);
            };

            /**
             * Encodes the specified JwtKey message. Does not implicitly {@link realtalk.manager.JwtKey.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {realtalk.manager.IJwtKey} message JwtKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JwtKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.algorithm != null && Object.hasOwnProperty.call(message, "algorithm"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.algorithm);
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.key);
                if (message.issuer != null && Object.hasOwnProperty.call(message, "issuer"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.issuer);
                if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.deleted);
                return writer;
            };

            /**
             * Decodes a JwtKey message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.JwtKey} JwtKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JwtKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.JwtKey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.algorithm = reader.int32();
                        break;
                    case 3:
                        message.key = reader.string();
                        break;
                    case 4:
                        message.issuer = reader.string();
                        break;
                    case 5:
                        message.deleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a JwtKey message.
             * @function verify
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JwtKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                    switch (message.algorithm) {
                    default:
                        return "algorithm: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.issuer != null && message.hasOwnProperty("issuer"))
                    if (!$util.isString(message.issuer))
                        return "issuer: string expected";
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    if (typeof message.deleted !== "boolean")
                        return "deleted: boolean expected";
                return null;
            };

            /**
             * Creates a JwtKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.JwtKey} JwtKey
             */
            JwtKey.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.JwtKey)
                    return object;
                var message = new $root.realtalk.manager.JwtKey();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                switch (object.algorithm) {
                case "JKA_RS256":
                case 0:
                    message.algorithm = 0;
                    break;
                case "JKA_HS256":
                case 1:
                    message.algorithm = 1;
                    break;
                }
                if (object.key != null)
                    message.key = String(object.key);
                if (object.issuer != null)
                    message.issuer = String(object.issuer);
                if (object.deleted != null)
                    message.deleted = Boolean(object.deleted);
                return message;
            };

            /**
             * Creates a plain object from a JwtKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.JwtKey
             * @static
             * @param {realtalk.manager.JwtKey} message JwtKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JwtKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.algorithm = options.enums === String ? "JKA_RS256" : 0;
                    object.key = "";
                    object.issuer = "";
                    object.deleted = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                    object.algorithm = options.enums === String ? $root.realtalk.manager.JwtKeyAlgorithm[message.algorithm] : message.algorithm;
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.issuer != null && message.hasOwnProperty("issuer"))
                    object.issuer = message.issuer;
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    object.deleted = message.deleted;
                return object;
            };

            /**
             * Converts this JwtKey to JSON.
             * @function toJSON
             * @memberof realtalk.manager.JwtKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JwtKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return JwtKey;
        })();

        manager.JwtKeyList = (function() {

            /**
             * Properties of a JwtKeyList.
             * @memberof realtalk.manager
             * @interface IJwtKeyList
             * @property {Array.<realtalk.manager.IJwtKey>|null} [jwtKey] JwtKeyList jwtKey
             */

            /**
             * Constructs a new JwtKeyList.
             * @memberof realtalk.manager
             * @classdesc Represents a JwtKeyList.
             * @implements IJwtKeyList
             * @constructor
             * @param {realtalk.manager.IJwtKeyList=} [properties] Properties to set
             */
            function JwtKeyList(properties) {
                this.jwtKey = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * JwtKeyList jwtKey.
             * @member {Array.<realtalk.manager.IJwtKey>} jwtKey
             * @memberof realtalk.manager.JwtKeyList
             * @instance
             */
            JwtKeyList.prototype.jwtKey = $util.emptyArray;

            /**
             * Creates a new JwtKeyList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {realtalk.manager.IJwtKeyList=} [properties] Properties to set
             * @returns {realtalk.manager.JwtKeyList} JwtKeyList instance
             */
            JwtKeyList.create = function create(properties) {
                return new JwtKeyList(properties);
            };

            /**
             * Encodes the specified JwtKeyList message. Does not implicitly {@link realtalk.manager.JwtKeyList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {realtalk.manager.IJwtKeyList} message JwtKeyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JwtKeyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.jwtKey != null && message.jwtKey.length)
                    for (var i = 0; i < message.jwtKey.length; ++i)
                        $root.realtalk.manager.JwtKey.encode(message.jwtKey[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a JwtKeyList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.JwtKeyList} JwtKeyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JwtKeyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.JwtKeyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.jwtKey && message.jwtKey.length))
                            message.jwtKey = [];
                        message.jwtKey.push($root.realtalk.manager.JwtKey.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a JwtKeyList message.
             * @function verify
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JwtKeyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.jwtKey != null && message.hasOwnProperty("jwtKey")) {
                    if (!Array.isArray(message.jwtKey))
                        return "jwtKey: array expected";
                    for (var i = 0; i < message.jwtKey.length; ++i) {
                        var error = $root.realtalk.manager.JwtKey.verify(message.jwtKey[i]);
                        if (error)
                            return "jwtKey." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a JwtKeyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.JwtKeyList} JwtKeyList
             */
            JwtKeyList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.JwtKeyList)
                    return object;
                var message = new $root.realtalk.manager.JwtKeyList();
                if (object.jwtKey) {
                    if (!Array.isArray(object.jwtKey))
                        throw TypeError(".realtalk.manager.JwtKeyList.jwtKey: array expected");
                    message.jwtKey = [];
                    for (var i = 0; i < object.jwtKey.length; ++i) {
                        if (typeof object.jwtKey[i] !== "object")
                            throw TypeError(".realtalk.manager.JwtKeyList.jwtKey: object expected");
                        message.jwtKey[i] = $root.realtalk.manager.JwtKey.fromObject(object.jwtKey[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a JwtKeyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.JwtKeyList
             * @static
             * @param {realtalk.manager.JwtKeyList} message JwtKeyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JwtKeyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.jwtKey = [];
                if (message.jwtKey && message.jwtKey.length) {
                    object.jwtKey = [];
                    for (var j = 0; j < message.jwtKey.length; ++j)
                        object.jwtKey[j] = $root.realtalk.manager.JwtKey.toObject(message.jwtKey[j], options);
                }
                return object;
            };

            /**
             * Converts this JwtKeyList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.JwtKeyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JwtKeyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return JwtKeyList;
        })();

        manager.LastActivity = (function() {

            /**
             * Properties of a LastActivity.
             * @memberof realtalk.manager
             * @interface ILastActivity
             * @property {number|Long|null} [chatUserId] LastActivity chatUserId
             * @property {realtalk.manager.IDateTime|null} [time] LastActivity time
             */

            /**
             * Constructs a new LastActivity.
             * @memberof realtalk.manager
             * @classdesc Represents a LastActivity.
             * @implements ILastActivity
             * @constructor
             * @param {realtalk.manager.ILastActivity=} [properties] Properties to set
             */
            function LastActivity(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LastActivity chatUserId.
             * @member {number|Long} chatUserId
             * @memberof realtalk.manager.LastActivity
             * @instance
             */
            LastActivity.prototype.chatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * LastActivity time.
             * @member {realtalk.manager.IDateTime|null|undefined} time
             * @memberof realtalk.manager.LastActivity
             * @instance
             */
            LastActivity.prototype.time = null;

            /**
             * Creates a new LastActivity instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {realtalk.manager.ILastActivity=} [properties] Properties to set
             * @returns {realtalk.manager.LastActivity} LastActivity instance
             */
            LastActivity.create = function create(properties) {
                return new LastActivity(properties);
            };

            /**
             * Encodes the specified LastActivity message. Does not implicitly {@link realtalk.manager.LastActivity.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {realtalk.manager.ILastActivity} message LastActivity message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LastActivity.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatUserId != null && Object.hasOwnProperty.call(message, "chatUserId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatUserId);
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    $root.realtalk.manager.DateTime.encode(message.time, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a LastActivity message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.LastActivity} LastActivity
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LastActivity.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.LastActivity();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatUserId = reader.uint64();
                        break;
                    case 2:
                        message.time = $root.realtalk.manager.DateTime.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LastActivity message.
             * @function verify
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LastActivity.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (!$util.isInteger(message.chatUserId) && !(message.chatUserId && $util.isInteger(message.chatUserId.low) && $util.isInteger(message.chatUserId.high)))
                        return "chatUserId: integer|Long expected";
                if (message.time != null && message.hasOwnProperty("time")) {
                    var error = $root.realtalk.manager.DateTime.verify(message.time);
                    if (error)
                        return "time." + error;
                }
                return null;
            };

            /**
             * Creates a LastActivity message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.LastActivity} LastActivity
             */
            LastActivity.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.LastActivity)
                    return object;
                var message = new $root.realtalk.manager.LastActivity();
                if (object.chatUserId != null)
                    if ($util.Long)
                        (message.chatUserId = $util.Long.fromValue(object.chatUserId)).unsigned = true;
                    else if (typeof object.chatUserId === "string")
                        message.chatUserId = parseInt(object.chatUserId, 10);
                    else if (typeof object.chatUserId === "number")
                        message.chatUserId = object.chatUserId;
                    else if (typeof object.chatUserId === "object")
                        message.chatUserId = new $util.LongBits(object.chatUserId.low >>> 0, object.chatUserId.high >>> 0).toNumber(true);
                if (object.time != null) {
                    if (typeof object.time !== "object")
                        throw TypeError(".realtalk.manager.LastActivity.time: object expected");
                    message.time = $root.realtalk.manager.DateTime.fromObject(object.time);
                }
                return message;
            };

            /**
             * Creates a plain object from a LastActivity message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.LastActivity
             * @static
             * @param {realtalk.manager.LastActivity} message LastActivity
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LastActivity.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatUserId = options.longs === String ? "0" : 0;
                    object.time = null;
                }
                if (message.chatUserId != null && message.hasOwnProperty("chatUserId"))
                    if (typeof message.chatUserId === "number")
                        object.chatUserId = options.longs === String ? String(message.chatUserId) : message.chatUserId;
                    else
                        object.chatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.chatUserId) : options.longs === Number ? new $util.LongBits(message.chatUserId.low >>> 0, message.chatUserId.high >>> 0).toNumber(true) : message.chatUserId;
                if (message.time != null && message.hasOwnProperty("time"))
                    object.time = $root.realtalk.manager.DateTime.toObject(message.time, options);
                return object;
            };

            /**
             * Converts this LastActivity to JSON.
             * @function toJSON
             * @memberof realtalk.manager.LastActivity
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LastActivity.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LastActivity;
        })();

        manager.LeaveChat = (function() {

            /**
             * Properties of a LeaveChat.
             * @memberof realtalk.manager
             * @interface ILeaveChat
             * @property {number|Long|null} [chatId] LeaveChat chatId
             */

            /**
             * Constructs a new LeaveChat.
             * @memberof realtalk.manager
             * @classdesc Represents a LeaveChat.
             * @implements ILeaveChat
             * @constructor
             * @param {realtalk.manager.ILeaveChat=} [properties] Properties to set
             */
            function LeaveChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LeaveChat chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.LeaveChat
             * @instance
             */
            LeaveChat.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new LeaveChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {realtalk.manager.ILeaveChat=} [properties] Properties to set
             * @returns {realtalk.manager.LeaveChat} LeaveChat instance
             */
            LeaveChat.create = function create(properties) {
                return new LeaveChat(properties);
            };

            /**
             * Encodes the specified LeaveChat message. Does not implicitly {@link realtalk.manager.LeaveChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {realtalk.manager.ILeaveChat} message LeaveChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LeaveChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a LeaveChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.LeaveChat} LeaveChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LeaveChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.LeaveChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LeaveChat message.
             * @function verify
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LeaveChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a LeaveChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.LeaveChat} LeaveChat
             */
            LeaveChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.LeaveChat)
                    return object;
                var message = new $root.realtalk.manager.LeaveChat();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a LeaveChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.LeaveChat
             * @static
             * @param {realtalk.manager.LeaveChat} message LeaveChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LeaveChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this LeaveChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.LeaveChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LeaveChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LeaveChat;
        })();

        manager.Line = (function() {

            /**
             * Properties of a Line.
             * @memberof realtalk.manager
             * @interface ILine
             * @property {number|Long|null} [position] Line position
             * @property {Array.<number|Long>|null} [operatorId] Line operatorId
             */

            /**
             * Constructs a new Line.
             * @memberof realtalk.manager
             * @classdesc Represents a Line.
             * @implements ILine
             * @constructor
             * @param {realtalk.manager.ILine=} [properties] Properties to set
             */
            function Line(properties) {
                this.operatorId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Line position.
             * @member {number|Long} position
             * @memberof realtalk.manager.Line
             * @instance
             */
            Line.prototype.position = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Line operatorId.
             * @member {Array.<number|Long>} operatorId
             * @memberof realtalk.manager.Line
             * @instance
             */
            Line.prototype.operatorId = $util.emptyArray;

            /**
             * Creates a new Line instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Line
             * @static
             * @param {realtalk.manager.ILine=} [properties] Properties to set
             * @returns {realtalk.manager.Line} Line instance
             */
            Line.create = function create(properties) {
                return new Line(properties);
            };

            /**
             * Encodes the specified Line message. Does not implicitly {@link realtalk.manager.Line.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Line
             * @static
             * @param {realtalk.manager.ILine} message Line message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Line.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.position);
                if (message.operatorId != null && message.operatorId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.operatorId.length; ++i)
                        writer.uint64(message.operatorId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a Line message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Line
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Line} Line
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Line.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Line();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.position = reader.uint64();
                        break;
                    case 2:
                        if (!(message.operatorId && message.operatorId.length))
                            message.operatorId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.operatorId.push(reader.uint64());
                        } else
                            message.operatorId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Line message.
             * @function verify
             * @memberof realtalk.manager.Line
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Line.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.position != null && message.hasOwnProperty("position"))
                    if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                        return "position: integer|Long expected";
                if (message.operatorId != null && message.hasOwnProperty("operatorId")) {
                    if (!Array.isArray(message.operatorId))
                        return "operatorId: array expected";
                    for (var i = 0; i < message.operatorId.length; ++i)
                        if (!$util.isInteger(message.operatorId[i]) && !(message.operatorId[i] && $util.isInteger(message.operatorId[i].low) && $util.isInteger(message.operatorId[i].high)))
                            return "operatorId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a Line message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Line
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Line} Line
             */
            Line.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Line)
                    return object;
                var message = new $root.realtalk.manager.Line();
                if (object.position != null)
                    if ($util.Long)
                        (message.position = $util.Long.fromValue(object.position)).unsigned = true;
                    else if (typeof object.position === "string")
                        message.position = parseInt(object.position, 10);
                    else if (typeof object.position === "number")
                        message.position = object.position;
                    else if (typeof object.position === "object")
                        message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber(true);
                if (object.operatorId) {
                    if (!Array.isArray(object.operatorId))
                        throw TypeError(".realtalk.manager.Line.operatorId: array expected");
                    message.operatorId = [];
                    for (var i = 0; i < object.operatorId.length; ++i)
                        if ($util.Long)
                            (message.operatorId[i] = $util.Long.fromValue(object.operatorId[i])).unsigned = true;
                        else if (typeof object.operatorId[i] === "string")
                            message.operatorId[i] = parseInt(object.operatorId[i], 10);
                        else if (typeof object.operatorId[i] === "number")
                            message.operatorId[i] = object.operatorId[i];
                        else if (typeof object.operatorId[i] === "object")
                            message.operatorId[i] = new $util.LongBits(object.operatorId[i].low >>> 0, object.operatorId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a Line message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Line
             * @static
             * @param {realtalk.manager.Line} message Line
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Line.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.operatorId = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.position = options.longs === String ? "0" : 0;
                if (message.position != null && message.hasOwnProperty("position"))
                    if (typeof message.position === "number")
                        object.position = options.longs === String ? String(message.position) : message.position;
                    else
                        object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber(true) : message.position;
                if (message.operatorId && message.operatorId.length) {
                    object.operatorId = [];
                    for (var j = 0; j < message.operatorId.length; ++j)
                        if (typeof message.operatorId[j] === "number")
                            object.operatorId[j] = options.longs === String ? String(message.operatorId[j]) : message.operatorId[j];
                        else
                            object.operatorId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId[j]) : options.longs === Number ? new $util.LongBits(message.operatorId[j].low >>> 0, message.operatorId[j].high >>> 0).toNumber(true) : message.operatorId[j];
                }
                return object;
            };

            /**
             * Converts this Line to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Line
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Line.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Line;
        })();

        manager.LineList = (function() {

            /**
             * Properties of a LineList.
             * @memberof realtalk.manager
             * @interface ILineList
             * @property {Array.<realtalk.manager.ILine>|null} [line] LineList line
             */

            /**
             * Constructs a new LineList.
             * @memberof realtalk.manager
             * @classdesc Represents a LineList.
             * @implements ILineList
             * @constructor
             * @param {realtalk.manager.ILineList=} [properties] Properties to set
             */
            function LineList(properties) {
                this.line = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LineList line.
             * @member {Array.<realtalk.manager.ILine>} line
             * @memberof realtalk.manager.LineList
             * @instance
             */
            LineList.prototype.line = $util.emptyArray;

            /**
             * Creates a new LineList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.LineList
             * @static
             * @param {realtalk.manager.ILineList=} [properties] Properties to set
             * @returns {realtalk.manager.LineList} LineList instance
             */
            LineList.create = function create(properties) {
                return new LineList(properties);
            };

            /**
             * Encodes the specified LineList message. Does not implicitly {@link realtalk.manager.LineList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.LineList
             * @static
             * @param {realtalk.manager.ILineList} message LineList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LineList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.line != null && message.line.length)
                    for (var i = 0; i < message.line.length; ++i)
                        $root.realtalk.manager.Line.encode(message.line[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a LineList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.LineList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.LineList} LineList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LineList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.LineList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.line && message.line.length))
                            message.line = [];
                        message.line.push($root.realtalk.manager.Line.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LineList message.
             * @function verify
             * @memberof realtalk.manager.LineList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LineList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.line != null && message.hasOwnProperty("line")) {
                    if (!Array.isArray(message.line))
                        return "line: array expected";
                    for (var i = 0; i < message.line.length; ++i) {
                        var error = $root.realtalk.manager.Line.verify(message.line[i]);
                        if (error)
                            return "line." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LineList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.LineList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.LineList} LineList
             */
            LineList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.LineList)
                    return object;
                var message = new $root.realtalk.manager.LineList();
                if (object.line) {
                    if (!Array.isArray(object.line))
                        throw TypeError(".realtalk.manager.LineList.line: array expected");
                    message.line = [];
                    for (var i = 0; i < object.line.length; ++i) {
                        if (typeof object.line[i] !== "object")
                            throw TypeError(".realtalk.manager.LineList.line: object expected");
                        message.line[i] = $root.realtalk.manager.Line.fromObject(object.line[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LineList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.LineList
             * @static
             * @param {realtalk.manager.LineList} message LineList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LineList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.line = [];
                if (message.line && message.line.length) {
                    object.line = [];
                    for (var j = 0; j < message.line.length; ++j)
                        object.line[j] = $root.realtalk.manager.Line.toObject(message.line[j], options);
                }
                return object;
            };

            /**
             * Converts this LineList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.LineList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LineList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LineList;
        })();

        manager.MarkMessageAsRead = (function() {

            /**
             * Properties of a MarkMessageAsRead.
             * @memberof realtalk.manager
             * @interface IMarkMessageAsRead
             * @property {number|Long|null} [messageId] MarkMessageAsRead messageId
             */

            /**
             * Constructs a new MarkMessageAsRead.
             * @memberof realtalk.manager
             * @classdesc Represents a MarkMessageAsRead.
             * @implements IMarkMessageAsRead
             * @constructor
             * @param {realtalk.manager.IMarkMessageAsRead=} [properties] Properties to set
             */
            function MarkMessageAsRead(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MarkMessageAsRead messageId.
             * @member {number|Long} messageId
             * @memberof realtalk.manager.MarkMessageAsRead
             * @instance
             */
            MarkMessageAsRead.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new MarkMessageAsRead instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {realtalk.manager.IMarkMessageAsRead=} [properties] Properties to set
             * @returns {realtalk.manager.MarkMessageAsRead} MarkMessageAsRead instance
             */
            MarkMessageAsRead.create = function create(properties) {
                return new MarkMessageAsRead(properties);
            };

            /**
             * Encodes the specified MarkMessageAsRead message. Does not implicitly {@link realtalk.manager.MarkMessageAsRead.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {realtalk.manager.IMarkMessageAsRead} message MarkMessageAsRead message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkMessageAsRead.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.messageId);
                return writer;
            };

            /**
             * Decodes a MarkMessageAsRead message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.MarkMessageAsRead} MarkMessageAsRead
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkMessageAsRead.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.MarkMessageAsRead();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MarkMessageAsRead message.
             * @function verify
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkMessageAsRead.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                        return "messageId: integer|Long expected";
                return null;
            };

            /**
             * Creates a MarkMessageAsRead message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.MarkMessageAsRead} MarkMessageAsRead
             */
            MarkMessageAsRead.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.MarkMessageAsRead)
                    return object;
                var message = new $root.realtalk.manager.MarkMessageAsRead();
                if (object.messageId != null)
                    if ($util.Long)
                        (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = true;
                    else if (typeof object.messageId === "string")
                        message.messageId = parseInt(object.messageId, 10);
                    else if (typeof object.messageId === "number")
                        message.messageId = object.messageId;
                    else if (typeof object.messageId === "object")
                        message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a MarkMessageAsRead message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.MarkMessageAsRead
             * @static
             * @param {realtalk.manager.MarkMessageAsRead} message MarkMessageAsRead
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkMessageAsRead.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.messageId = options.longs === String ? "0" : 0;
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (typeof message.messageId === "number")
                        object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                    else
                        object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber(true) : message.messageId;
                return object;
            };

            /**
             * Converts this MarkMessageAsRead to JSON.
             * @function toJSON
             * @memberof realtalk.manager.MarkMessageAsRead
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkMessageAsRead.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MarkMessageAsRead;
        })();

        manager.MessageRating = (function() {

            /**
             * Properties of a MessageRating.
             * @memberof realtalk.manager
             * @interface IMessageRating
             * @property {number|Long|null} [messageId] MessageRating messageId
             * @property {realtalk.manager.MessageRating.Rating|null} [rating] MessageRating rating
             */

            /**
             * Constructs a new MessageRating.
             * @memberof realtalk.manager
             * @classdesc Represents a MessageRating.
             * @implements IMessageRating
             * @constructor
             * @param {realtalk.manager.IMessageRating=} [properties] Properties to set
             */
            function MessageRating(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageRating messageId.
             * @member {number|Long} messageId
             * @memberof realtalk.manager.MessageRating
             * @instance
             */
            MessageRating.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * MessageRating rating.
             * @member {realtalk.manager.MessageRating.Rating} rating
             * @memberof realtalk.manager.MessageRating
             * @instance
             */
            MessageRating.prototype.rating = 0;

            /**
             * Creates a new MessageRating instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {realtalk.manager.IMessageRating=} [properties] Properties to set
             * @returns {realtalk.manager.MessageRating} MessageRating instance
             */
            MessageRating.create = function create(properties) {
                return new MessageRating(properties);
            };

            /**
             * Encodes the specified MessageRating message. Does not implicitly {@link realtalk.manager.MessageRating.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {realtalk.manager.IMessageRating} message MessageRating message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageRating.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.messageId);
                if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rating);
                return writer;
            };

            /**
             * Decodes a MessageRating message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.MessageRating} MessageRating
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageRating.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.MessageRating();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageId = reader.uint64();
                        break;
                    case 2:
                        message.rating = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MessageRating message.
             * @function verify
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageRating.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                        return "messageId: integer|Long expected";
                if (message.rating != null && message.hasOwnProperty("rating"))
                    switch (message.rating) {
                    default:
                        return "rating: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                return null;
            };

            /**
             * Creates a MessageRating message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.MessageRating} MessageRating
             */
            MessageRating.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.MessageRating)
                    return object;
                var message = new $root.realtalk.manager.MessageRating();
                if (object.messageId != null)
                    if ($util.Long)
                        (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = true;
                    else if (typeof object.messageId === "string")
                        message.messageId = parseInt(object.messageId, 10);
                    else if (typeof object.messageId === "number")
                        message.messageId = object.messageId;
                    else if (typeof object.messageId === "object")
                        message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber(true);
                switch (object.rating) {
                case "WITHOUT_RATING":
                case 0:
                    message.rating = 0;
                    break;
                case "POOR":
                case 1:
                    message.rating = 1;
                    break;
                case "FAIR":
                case 2:
                    message.rating = 2;
                    break;
                case "AVERAGE":
                case 3:
                    message.rating = 3;
                    break;
                case "GOOD":
                case 4:
                    message.rating = 4;
                    break;
                case "EXCELLENT":
                case 5:
                    message.rating = 5;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageRating message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.MessageRating
             * @static
             * @param {realtalk.manager.MessageRating} message MessageRating
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageRating.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.messageId = options.longs === String ? "0" : 0;
                    object.rating = options.enums === String ? "WITHOUT_RATING" : 0;
                }
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (typeof message.messageId === "number")
                        object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                    else
                        object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber(true) : message.messageId;
                if (message.rating != null && message.hasOwnProperty("rating"))
                    object.rating = options.enums === String ? $root.realtalk.manager.MessageRating.Rating[message.rating] : message.rating;
                return object;
            };

            /**
             * Converts this MessageRating to JSON.
             * @function toJSON
             * @memberof realtalk.manager.MessageRating
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageRating.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Rating enum.
             * @name realtalk.manager.MessageRating.Rating
             * @enum {number}
             * @property {number} WITHOUT_RATING=0 WITHOUT_RATING value
             * @property {number} POOR=1 POOR value
             * @property {number} FAIR=2 FAIR value
             * @property {number} AVERAGE=3 AVERAGE value
             * @property {number} GOOD=4 GOOD value
             * @property {number} EXCELLENT=5 EXCELLENT value
             */
            MessageRating.Rating = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WITHOUT_RATING"] = 0;
                values[valuesById[1] = "POOR"] = 1;
                values[valuesById[2] = "FAIR"] = 2;
                values[valuesById[3] = "AVERAGE"] = 3;
                values[valuesById[4] = "GOOD"] = 4;
                values[valuesById[5] = "EXCELLENT"] = 5;
                return values;
            })();

            return MessageRating;
        })();

        manager.Message = (function() {

            /**
             * Properties of a Message.
             * @memberof realtalk.manager
             * @interface IMessage
             * @property {number|Long|null} [id] Message id
             * @property {number|Long|null} [timestamp] Message timestamp
             * @property {number|Long|null} [fromChatUserId] Message fromChatUserId
             * @property {number|Long|null} [toChatId] Message toChatId
             * @property {string|null} [text] Message text
             * @property {boolean|null} [unfinished] Message unfinished
             * @property {boolean|null} [unread] Message unread
             * @property {realtalk.manager.MessageRating.Rating|null} [rating] Message rating
             * @property {Array.<realtalk.manager.IAttachment>|null} [attachment] Message attachment
             */

            /**
             * Constructs a new Message.
             * @memberof realtalk.manager
             * @classdesc Represents a Message.
             * @implements IMessage
             * @constructor
             * @param {realtalk.manager.IMessage=} [properties] Properties to set
             */
            function Message(properties) {
                this.attachment = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Message id.
             * @member {number|Long} id
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Message timestamp.
             * @member {number|Long} timestamp
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Message fromChatUserId.
             * @member {number|Long} fromChatUserId
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.fromChatUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Message toChatId.
             * @member {number|Long} toChatId
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.toChatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Message text.
             * @member {string} text
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.text = "";

            /**
             * Message unfinished.
             * @member {boolean} unfinished
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.unfinished = false;

            /**
             * Message unread.
             * @member {boolean} unread
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.unread = false;

            /**
             * Message rating.
             * @member {realtalk.manager.MessageRating.Rating} rating
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.rating = 0;

            /**
             * Message attachment.
             * @member {Array.<realtalk.manager.IAttachment>} attachment
             * @memberof realtalk.manager.Message
             * @instance
             */
            Message.prototype.attachment = $util.emptyArray;

            /**
             * Creates a new Message instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Message
             * @static
             * @param {realtalk.manager.IMessage=} [properties] Properties to set
             * @returns {realtalk.manager.Message} Message instance
             */
            Message.create = function create(properties) {
                return new Message(properties);
            };

            /**
             * Encodes the specified Message message. Does not implicitly {@link realtalk.manager.Message.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Message
             * @static
             * @param {realtalk.manager.IMessage} message Message message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Message.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestamp);
                if (message.fromChatUserId != null && Object.hasOwnProperty.call(message, "fromChatUserId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.fromChatUserId);
                if (message.toChatId != null && Object.hasOwnProperty.call(message, "toChatId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.toChatId);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.text);
                if (message.unfinished != null && Object.hasOwnProperty.call(message, "unfinished"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.unfinished);
                if (message.unread != null && Object.hasOwnProperty.call(message, "unread"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.unread);
                if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.rating);
                if (message.attachment != null && message.attachment.length)
                    for (var i = 0; i < message.attachment.length; ++i)
                        $root.realtalk.manager.Attachment.encode(message.attachment[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Message message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Message
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Message} Message
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Message.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Message();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.timestamp = reader.int64();
                        break;
                    case 3:
                        message.fromChatUserId = reader.uint64();
                        break;
                    case 4:
                        message.toChatId = reader.uint64();
                        break;
                    case 5:
                        message.text = reader.string();
                        break;
                    case 6:
                        message.unfinished = reader.bool();
                        break;
                    case 7:
                        message.unread = reader.bool();
                        break;
                    case 8:
                        message.rating = reader.int32();
                        break;
                    case 9:
                        if (!(message.attachment && message.attachment.length))
                            message.attachment = [];
                        message.attachment.push($root.realtalk.manager.Attachment.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Message message.
             * @function verify
             * @memberof realtalk.manager.Message
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Message.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.fromChatUserId != null && message.hasOwnProperty("fromChatUserId"))
                    if (!$util.isInteger(message.fromChatUserId) && !(message.fromChatUserId && $util.isInteger(message.fromChatUserId.low) && $util.isInteger(message.fromChatUserId.high)))
                        return "fromChatUserId: integer|Long expected";
                if (message.toChatId != null && message.hasOwnProperty("toChatId"))
                    if (!$util.isInteger(message.toChatId) && !(message.toChatId && $util.isInteger(message.toChatId.low) && $util.isInteger(message.toChatId.high)))
                        return "toChatId: integer|Long expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.unfinished != null && message.hasOwnProperty("unfinished"))
                    if (typeof message.unfinished !== "boolean")
                        return "unfinished: boolean expected";
                if (message.unread != null && message.hasOwnProperty("unread"))
                    if (typeof message.unread !== "boolean")
                        return "unread: boolean expected";
                if (message.rating != null && message.hasOwnProperty("rating"))
                    switch (message.rating) {
                    default:
                        return "rating: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.attachment != null && message.hasOwnProperty("attachment")) {
                    if (!Array.isArray(message.attachment))
                        return "attachment: array expected";
                    for (var i = 0; i < message.attachment.length; ++i) {
                        var error = $root.realtalk.manager.Attachment.verify(message.attachment[i]);
                        if (error)
                            return "attachment." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Message message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Message
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Message} Message
             */
            Message.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Message)
                    return object;
                var message = new $root.realtalk.manager.Message();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.fromChatUserId != null)
                    if ($util.Long)
                        (message.fromChatUserId = $util.Long.fromValue(object.fromChatUserId)).unsigned = true;
                    else if (typeof object.fromChatUserId === "string")
                        message.fromChatUserId = parseInt(object.fromChatUserId, 10);
                    else if (typeof object.fromChatUserId === "number")
                        message.fromChatUserId = object.fromChatUserId;
                    else if (typeof object.fromChatUserId === "object")
                        message.fromChatUserId = new $util.LongBits(object.fromChatUserId.low >>> 0, object.fromChatUserId.high >>> 0).toNumber(true);
                if (object.toChatId != null)
                    if ($util.Long)
                        (message.toChatId = $util.Long.fromValue(object.toChatId)).unsigned = true;
                    else if (typeof object.toChatId === "string")
                        message.toChatId = parseInt(object.toChatId, 10);
                    else if (typeof object.toChatId === "number")
                        message.toChatId = object.toChatId;
                    else if (typeof object.toChatId === "object")
                        message.toChatId = new $util.LongBits(object.toChatId.low >>> 0, object.toChatId.high >>> 0).toNumber(true);
                if (object.text != null)
                    message.text = String(object.text);
                if (object.unfinished != null)
                    message.unfinished = Boolean(object.unfinished);
                if (object.unread != null)
                    message.unread = Boolean(object.unread);
                switch (object.rating) {
                case "WITHOUT_RATING":
                case 0:
                    message.rating = 0;
                    break;
                case "POOR":
                case 1:
                    message.rating = 1;
                    break;
                case "FAIR":
                case 2:
                    message.rating = 2;
                    break;
                case "AVERAGE":
                case 3:
                    message.rating = 3;
                    break;
                case "GOOD":
                case 4:
                    message.rating = 4;
                    break;
                case "EXCELLENT":
                case 5:
                    message.rating = 5;
                    break;
                }
                if (object.attachment) {
                    if (!Array.isArray(object.attachment))
                        throw TypeError(".realtalk.manager.Message.attachment: array expected");
                    message.attachment = [];
                    for (var i = 0; i < object.attachment.length; ++i) {
                        if (typeof object.attachment[i] !== "object")
                            throw TypeError(".realtalk.manager.Message.attachment: object expected");
                        message.attachment[i] = $root.realtalk.manager.Attachment.fromObject(object.attachment[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Message message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Message
             * @static
             * @param {realtalk.manager.Message} message Message
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Message.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.attachment = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.fromChatUserId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromChatUserId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.toChatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toChatId = options.longs === String ? "0" : 0;
                    object.text = "";
                    object.unfinished = false;
                    object.unread = false;
                    object.rating = options.enums === String ? "WITHOUT_RATING" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.fromChatUserId != null && message.hasOwnProperty("fromChatUserId"))
                    if (typeof message.fromChatUserId === "number")
                        object.fromChatUserId = options.longs === String ? String(message.fromChatUserId) : message.fromChatUserId;
                    else
                        object.fromChatUserId = options.longs === String ? $util.Long.prototype.toString.call(message.fromChatUserId) : options.longs === Number ? new $util.LongBits(message.fromChatUserId.low >>> 0, message.fromChatUserId.high >>> 0).toNumber(true) : message.fromChatUserId;
                if (message.toChatId != null && message.hasOwnProperty("toChatId"))
                    if (typeof message.toChatId === "number")
                        object.toChatId = options.longs === String ? String(message.toChatId) : message.toChatId;
                    else
                        object.toChatId = options.longs === String ? $util.Long.prototype.toString.call(message.toChatId) : options.longs === Number ? new $util.LongBits(message.toChatId.low >>> 0, message.toChatId.high >>> 0).toNumber(true) : message.toChatId;
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.unfinished != null && message.hasOwnProperty("unfinished"))
                    object.unfinished = message.unfinished;
                if (message.unread != null && message.hasOwnProperty("unread"))
                    object.unread = message.unread;
                if (message.rating != null && message.hasOwnProperty("rating"))
                    object.rating = options.enums === String ? $root.realtalk.manager.MessageRating.Rating[message.rating] : message.rating;
                if (message.attachment && message.attachment.length) {
                    object.attachment = [];
                    for (var j = 0; j < message.attachment.length; ++j)
                        object.attachment[j] = $root.realtalk.manager.Attachment.toObject(message.attachment[j], options);
                }
                return object;
            };

            /**
             * Converts this Message to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Message
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Message.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Message;
        })();

        manager.MessageList = (function() {

            /**
             * Properties of a MessageList.
             * @memberof realtalk.manager
             * @interface IMessageList
             * @property {Array.<realtalk.manager.IMessage>|null} [message] MessageList message
             */

            /**
             * Constructs a new MessageList.
             * @memberof realtalk.manager
             * @classdesc Represents a MessageList.
             * @implements IMessageList
             * @constructor
             * @param {realtalk.manager.IMessageList=} [properties] Properties to set
             */
            function MessageList(properties) {
                this.message = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageList message.
             * @member {Array.<realtalk.manager.IMessage>} message
             * @memberof realtalk.manager.MessageList
             * @instance
             */
            MessageList.prototype.message = $util.emptyArray;

            /**
             * Creates a new MessageList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {realtalk.manager.IMessageList=} [properties] Properties to set
             * @returns {realtalk.manager.MessageList} MessageList instance
             */
            MessageList.create = function create(properties) {
                return new MessageList(properties);
            };

            /**
             * Encodes the specified MessageList message. Does not implicitly {@link realtalk.manager.MessageList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {realtalk.manager.IMessageList} message MessageList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.message != null && message.message.length)
                    for (var i = 0; i < message.message.length; ++i)
                        $root.realtalk.manager.Message.encode(message.message[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MessageList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.MessageList} MessageList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.MessageList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.message && message.message.length))
                            message.message = [];
                        message.message.push($root.realtalk.manager.Message.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MessageList message.
             * @function verify
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.message != null && message.hasOwnProperty("message")) {
                    if (!Array.isArray(message.message))
                        return "message: array expected";
                    for (var i = 0; i < message.message.length; ++i) {
                        var error = $root.realtalk.manager.Message.verify(message.message[i]);
                        if (error)
                            return "message." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MessageList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.MessageList} MessageList
             */
            MessageList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.MessageList)
                    return object;
                var message = new $root.realtalk.manager.MessageList();
                if (object.message) {
                    if (!Array.isArray(object.message))
                        throw TypeError(".realtalk.manager.MessageList.message: array expected");
                    message.message = [];
                    for (var i = 0; i < object.message.length; ++i) {
                        if (typeof object.message[i] !== "object")
                            throw TypeError(".realtalk.manager.MessageList.message: object expected");
                        message.message[i] = $root.realtalk.manager.Message.fromObject(object.message[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.MessageList
             * @static
             * @param {realtalk.manager.MessageList} message MessageList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.message = [];
                if (message.message && message.message.length) {
                    object.message = [];
                    for (var j = 0; j < message.message.length; ++j)
                        object.message[j] = $root.realtalk.manager.Message.toObject(message.message[j], options);
                }
                return object;
            };

            /**
             * Converts this MessageList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.MessageList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageList;
        })();

        manager.NumberUnreadMessages = (function() {

            /**
             * Properties of a NumberUnreadMessages.
             * @memberof realtalk.manager
             * @interface INumberUnreadMessages
             * @property {number|Long|null} [chatId] NumberUnreadMessages chatId
             * @property {number|Long|null} [count] NumberUnreadMessages count
             */

            /**
             * Constructs a new NumberUnreadMessages.
             * @memberof realtalk.manager
             * @classdesc Represents a NumberUnreadMessages.
             * @implements INumberUnreadMessages
             * @constructor
             * @param {realtalk.manager.INumberUnreadMessages=} [properties] Properties to set
             */
            function NumberUnreadMessages(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NumberUnreadMessages chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.NumberUnreadMessages
             * @instance
             */
            NumberUnreadMessages.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * NumberUnreadMessages count.
             * @member {number|Long} count
             * @memberof realtalk.manager.NumberUnreadMessages
             * @instance
             */
            NumberUnreadMessages.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new NumberUnreadMessages instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {realtalk.manager.INumberUnreadMessages=} [properties] Properties to set
             * @returns {realtalk.manager.NumberUnreadMessages} NumberUnreadMessages instance
             */
            NumberUnreadMessages.create = function create(properties) {
                return new NumberUnreadMessages(properties);
            };

            /**
             * Encodes the specified NumberUnreadMessages message. Does not implicitly {@link realtalk.manager.NumberUnreadMessages.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {realtalk.manager.INumberUnreadMessages} message NumberUnreadMessages message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NumberUnreadMessages.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.count);
                return writer;
            };

            /**
             * Decodes a NumberUnreadMessages message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.NumberUnreadMessages} NumberUnreadMessages
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NumberUnreadMessages.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.NumberUnreadMessages();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.count = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a NumberUnreadMessages message.
             * @function verify
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NumberUnreadMessages.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                        return "count: integer|Long expected";
                return null;
            };

            /**
             * Creates a NumberUnreadMessages message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.NumberUnreadMessages} NumberUnreadMessages
             */
            NumberUnreadMessages.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.NumberUnreadMessages)
                    return object;
                var message = new $root.realtalk.manager.NumberUnreadMessages();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.count != null)
                    if ($util.Long)
                        (message.count = $util.Long.fromValue(object.count)).unsigned = true;
                    else if (typeof object.count === "string")
                        message.count = parseInt(object.count, 10);
                    else if (typeof object.count === "number")
                        message.count = object.count;
                    else if (typeof object.count === "object")
                        message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a NumberUnreadMessages message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.NumberUnreadMessages
             * @static
             * @param {realtalk.manager.NumberUnreadMessages} message NumberUnreadMessages
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NumberUnreadMessages.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.count = options.longs === String ? "0" : 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.count != null && message.hasOwnProperty("count"))
                    if (typeof message.count === "number")
                        object.count = options.longs === String ? String(message.count) : message.count;
                    else
                        object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber(true) : message.count;
                return object;
            };

            /**
             * Converts this NumberUnreadMessages to JSON.
             * @function toJSON
             * @memberof realtalk.manager.NumberUnreadMessages
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NumberUnreadMessages.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return NumberUnreadMessages;
        })();

        manager.OperatorReport = (function() {

            /**
             * Properties of an OperatorReport.
             * @memberof realtalk.manager
             * @interface IOperatorReport
             * @property {number|Long|null} [operatorId] OperatorReport operatorId
             * @property {number|Long|null} [numChatsProcessed] OperatorReport numChatsProcessed
             * @property {Array.<number|Long>|null} [conversationDurationMs] OperatorReport conversationDurationMs
             * @property {Array.<number|Long>|null} [responsePrintTimeMs] OperatorReport responsePrintTimeMs
             */

            /**
             * Constructs a new OperatorReport.
             * @memberof realtalk.manager
             * @classdesc Represents an OperatorReport.
             * @implements IOperatorReport
             * @constructor
             * @param {realtalk.manager.IOperatorReport=} [properties] Properties to set
             */
            function OperatorReport(properties) {
                this.conversationDurationMs = [];
                this.responsePrintTimeMs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperatorReport operatorId.
             * @member {number|Long} operatorId
             * @memberof realtalk.manager.OperatorReport
             * @instance
             */
            OperatorReport.prototype.operatorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OperatorReport numChatsProcessed.
             * @member {number|Long} numChatsProcessed
             * @memberof realtalk.manager.OperatorReport
             * @instance
             */
            OperatorReport.prototype.numChatsProcessed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OperatorReport conversationDurationMs.
             * @member {Array.<number|Long>} conversationDurationMs
             * @memberof realtalk.manager.OperatorReport
             * @instance
             */
            OperatorReport.prototype.conversationDurationMs = $util.emptyArray;

            /**
             * OperatorReport responsePrintTimeMs.
             * @member {Array.<number|Long>} responsePrintTimeMs
             * @memberof realtalk.manager.OperatorReport
             * @instance
             */
            OperatorReport.prototype.responsePrintTimeMs = $util.emptyArray;

            /**
             * Creates a new OperatorReport instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {realtalk.manager.IOperatorReport=} [properties] Properties to set
             * @returns {realtalk.manager.OperatorReport} OperatorReport instance
             */
            OperatorReport.create = function create(properties) {
                return new OperatorReport(properties);
            };

            /**
             * Encodes the specified OperatorReport message. Does not implicitly {@link realtalk.manager.OperatorReport.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {realtalk.manager.IOperatorReport} message OperatorReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperatorReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operatorId != null && Object.hasOwnProperty.call(message, "operatorId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorId);
                if (message.numChatsProcessed != null && Object.hasOwnProperty.call(message, "numChatsProcessed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.numChatsProcessed);
                if (message.conversationDurationMs != null && message.conversationDurationMs.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.conversationDurationMs.length; ++i)
                        writer.uint64(message.conversationDurationMs[i]);
                    writer.ldelim();
                }
                if (message.responsePrintTimeMs != null && message.responsePrintTimeMs.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.responsePrintTimeMs.length; ++i)
                        writer.uint64(message.responsePrintTimeMs[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an OperatorReport message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.OperatorReport} OperatorReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperatorReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.OperatorReport();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.operatorId = reader.uint64();
                        break;
                    case 2:
                        message.numChatsProcessed = reader.uint64();
                        break;
                    case 3:
                        if (!(message.conversationDurationMs && message.conversationDurationMs.length))
                            message.conversationDurationMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.conversationDurationMs.push(reader.uint64());
                        } else
                            message.conversationDurationMs.push(reader.uint64());
                        break;
                    case 4:
                        if (!(message.responsePrintTimeMs && message.responsePrintTimeMs.length))
                            message.responsePrintTimeMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.responsePrintTimeMs.push(reader.uint64());
                        } else
                            message.responsePrintTimeMs.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OperatorReport message.
             * @function verify
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperatorReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (!$util.isInteger(message.operatorId) && !(message.operatorId && $util.isInteger(message.operatorId.low) && $util.isInteger(message.operatorId.high)))
                        return "operatorId: integer|Long expected";
                if (message.numChatsProcessed != null && message.hasOwnProperty("numChatsProcessed"))
                    if (!$util.isInteger(message.numChatsProcessed) && !(message.numChatsProcessed && $util.isInteger(message.numChatsProcessed.low) && $util.isInteger(message.numChatsProcessed.high)))
                        return "numChatsProcessed: integer|Long expected";
                if (message.conversationDurationMs != null && message.hasOwnProperty("conversationDurationMs")) {
                    if (!Array.isArray(message.conversationDurationMs))
                        return "conversationDurationMs: array expected";
                    for (var i = 0; i < message.conversationDurationMs.length; ++i)
                        if (!$util.isInteger(message.conversationDurationMs[i]) && !(message.conversationDurationMs[i] && $util.isInteger(message.conversationDurationMs[i].low) && $util.isInteger(message.conversationDurationMs[i].high)))
                            return "conversationDurationMs: integer|Long[] expected";
                }
                if (message.responsePrintTimeMs != null && message.hasOwnProperty("responsePrintTimeMs")) {
                    if (!Array.isArray(message.responsePrintTimeMs))
                        return "responsePrintTimeMs: array expected";
                    for (var i = 0; i < message.responsePrintTimeMs.length; ++i)
                        if (!$util.isInteger(message.responsePrintTimeMs[i]) && !(message.responsePrintTimeMs[i] && $util.isInteger(message.responsePrintTimeMs[i].low) && $util.isInteger(message.responsePrintTimeMs[i].high)))
                            return "responsePrintTimeMs: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates an OperatorReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.OperatorReport} OperatorReport
             */
            OperatorReport.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.OperatorReport)
                    return object;
                var message = new $root.realtalk.manager.OperatorReport();
                if (object.operatorId != null)
                    if ($util.Long)
                        (message.operatorId = $util.Long.fromValue(object.operatorId)).unsigned = true;
                    else if (typeof object.operatorId === "string")
                        message.operatorId = parseInt(object.operatorId, 10);
                    else if (typeof object.operatorId === "number")
                        message.operatorId = object.operatorId;
                    else if (typeof object.operatorId === "object")
                        message.operatorId = new $util.LongBits(object.operatorId.low >>> 0, object.operatorId.high >>> 0).toNumber(true);
                if (object.numChatsProcessed != null)
                    if ($util.Long)
                        (message.numChatsProcessed = $util.Long.fromValue(object.numChatsProcessed)).unsigned = true;
                    else if (typeof object.numChatsProcessed === "string")
                        message.numChatsProcessed = parseInt(object.numChatsProcessed, 10);
                    else if (typeof object.numChatsProcessed === "number")
                        message.numChatsProcessed = object.numChatsProcessed;
                    else if (typeof object.numChatsProcessed === "object")
                        message.numChatsProcessed = new $util.LongBits(object.numChatsProcessed.low >>> 0, object.numChatsProcessed.high >>> 0).toNumber(true);
                if (object.conversationDurationMs) {
                    if (!Array.isArray(object.conversationDurationMs))
                        throw TypeError(".realtalk.manager.OperatorReport.conversationDurationMs: array expected");
                    message.conversationDurationMs = [];
                    for (var i = 0; i < object.conversationDurationMs.length; ++i)
                        if ($util.Long)
                            (message.conversationDurationMs[i] = $util.Long.fromValue(object.conversationDurationMs[i])).unsigned = true;
                        else if (typeof object.conversationDurationMs[i] === "string")
                            message.conversationDurationMs[i] = parseInt(object.conversationDurationMs[i], 10);
                        else if (typeof object.conversationDurationMs[i] === "number")
                            message.conversationDurationMs[i] = object.conversationDurationMs[i];
                        else if (typeof object.conversationDurationMs[i] === "object")
                            message.conversationDurationMs[i] = new $util.LongBits(object.conversationDurationMs[i].low >>> 0, object.conversationDurationMs[i].high >>> 0).toNumber(true);
                }
                if (object.responsePrintTimeMs) {
                    if (!Array.isArray(object.responsePrintTimeMs))
                        throw TypeError(".realtalk.manager.OperatorReport.responsePrintTimeMs: array expected");
                    message.responsePrintTimeMs = [];
                    for (var i = 0; i < object.responsePrintTimeMs.length; ++i)
                        if ($util.Long)
                            (message.responsePrintTimeMs[i] = $util.Long.fromValue(object.responsePrintTimeMs[i])).unsigned = true;
                        else if (typeof object.responsePrintTimeMs[i] === "string")
                            message.responsePrintTimeMs[i] = parseInt(object.responsePrintTimeMs[i], 10);
                        else if (typeof object.responsePrintTimeMs[i] === "number")
                            message.responsePrintTimeMs[i] = object.responsePrintTimeMs[i];
                        else if (typeof object.responsePrintTimeMs[i] === "object")
                            message.responsePrintTimeMs[i] = new $util.LongBits(object.responsePrintTimeMs[i].low >>> 0, object.responsePrintTimeMs[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from an OperatorReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.OperatorReport
             * @static
             * @param {realtalk.manager.OperatorReport} message OperatorReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperatorReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.conversationDurationMs = [];
                    object.responsePrintTimeMs = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.operatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.operatorId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.numChatsProcessed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.numChatsProcessed = options.longs === String ? "0" : 0;
                }
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (typeof message.operatorId === "number")
                        object.operatorId = options.longs === String ? String(message.operatorId) : message.operatorId;
                    else
                        object.operatorId = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId) : options.longs === Number ? new $util.LongBits(message.operatorId.low >>> 0, message.operatorId.high >>> 0).toNumber(true) : message.operatorId;
                if (message.numChatsProcessed != null && message.hasOwnProperty("numChatsProcessed"))
                    if (typeof message.numChatsProcessed === "number")
                        object.numChatsProcessed = options.longs === String ? String(message.numChatsProcessed) : message.numChatsProcessed;
                    else
                        object.numChatsProcessed = options.longs === String ? $util.Long.prototype.toString.call(message.numChatsProcessed) : options.longs === Number ? new $util.LongBits(message.numChatsProcessed.low >>> 0, message.numChatsProcessed.high >>> 0).toNumber(true) : message.numChatsProcessed;
                if (message.conversationDurationMs && message.conversationDurationMs.length) {
                    object.conversationDurationMs = [];
                    for (var j = 0; j < message.conversationDurationMs.length; ++j)
                        if (typeof message.conversationDurationMs[j] === "number")
                            object.conversationDurationMs[j] = options.longs === String ? String(message.conversationDurationMs[j]) : message.conversationDurationMs[j];
                        else
                            object.conversationDurationMs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.conversationDurationMs[j]) : options.longs === Number ? new $util.LongBits(message.conversationDurationMs[j].low >>> 0, message.conversationDurationMs[j].high >>> 0).toNumber(true) : message.conversationDurationMs[j];
                }
                if (message.responsePrintTimeMs && message.responsePrintTimeMs.length) {
                    object.responsePrintTimeMs = [];
                    for (var j = 0; j < message.responsePrintTimeMs.length; ++j)
                        if (typeof message.responsePrintTimeMs[j] === "number")
                            object.responsePrintTimeMs[j] = options.longs === String ? String(message.responsePrintTimeMs[j]) : message.responsePrintTimeMs[j];
                        else
                            object.responsePrintTimeMs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.responsePrintTimeMs[j]) : options.longs === Number ? new $util.LongBits(message.responsePrintTimeMs[j].low >>> 0, message.responsePrintTimeMs[j].high >>> 0).toNumber(true) : message.responsePrintTimeMs[j];
                }
                return object;
            };

            /**
             * Converts this OperatorReport to JSON.
             * @function toJSON
             * @memberof realtalk.manager.OperatorReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperatorReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OperatorReport;
        })();

        manager.OperatorReportList = (function() {

            /**
             * Properties of an OperatorReportList.
             * @memberof realtalk.manager
             * @interface IOperatorReportList
             * @property {Array.<realtalk.manager.IOperatorReport>|null} [operatorReport] OperatorReportList operatorReport
             */

            /**
             * Constructs a new OperatorReportList.
             * @memberof realtalk.manager
             * @classdesc Represents an OperatorReportList.
             * @implements IOperatorReportList
             * @constructor
             * @param {realtalk.manager.IOperatorReportList=} [properties] Properties to set
             */
            function OperatorReportList(properties) {
                this.operatorReport = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperatorReportList operatorReport.
             * @member {Array.<realtalk.manager.IOperatorReport>} operatorReport
             * @memberof realtalk.manager.OperatorReportList
             * @instance
             */
            OperatorReportList.prototype.operatorReport = $util.emptyArray;

            /**
             * Creates a new OperatorReportList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {realtalk.manager.IOperatorReportList=} [properties] Properties to set
             * @returns {realtalk.manager.OperatorReportList} OperatorReportList instance
             */
            OperatorReportList.create = function create(properties) {
                return new OperatorReportList(properties);
            };

            /**
             * Encodes the specified OperatorReportList message. Does not implicitly {@link realtalk.manager.OperatorReportList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {realtalk.manager.IOperatorReportList} message OperatorReportList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperatorReportList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operatorReport != null && message.operatorReport.length)
                    for (var i = 0; i < message.operatorReport.length; ++i)
                        $root.realtalk.manager.OperatorReport.encode(message.operatorReport[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OperatorReportList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.OperatorReportList} OperatorReportList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperatorReportList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.OperatorReportList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.operatorReport && message.operatorReport.length))
                            message.operatorReport = [];
                        message.operatorReport.push($root.realtalk.manager.OperatorReport.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OperatorReportList message.
             * @function verify
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperatorReportList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operatorReport != null && message.hasOwnProperty("operatorReport")) {
                    if (!Array.isArray(message.operatorReport))
                        return "operatorReport: array expected";
                    for (var i = 0; i < message.operatorReport.length; ++i) {
                        var error = $root.realtalk.manager.OperatorReport.verify(message.operatorReport[i]);
                        if (error)
                            return "operatorReport." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OperatorReportList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.OperatorReportList} OperatorReportList
             */
            OperatorReportList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.OperatorReportList)
                    return object;
                var message = new $root.realtalk.manager.OperatorReportList();
                if (object.operatorReport) {
                    if (!Array.isArray(object.operatorReport))
                        throw TypeError(".realtalk.manager.OperatorReportList.operatorReport: array expected");
                    message.operatorReport = [];
                    for (var i = 0; i < object.operatorReport.length; ++i) {
                        if (typeof object.operatorReport[i] !== "object")
                            throw TypeError(".realtalk.manager.OperatorReportList.operatorReport: object expected");
                        message.operatorReport[i] = $root.realtalk.manager.OperatorReport.fromObject(object.operatorReport[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OperatorReportList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.OperatorReportList
             * @static
             * @param {realtalk.manager.OperatorReportList} message OperatorReportList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperatorReportList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.operatorReport = [];
                if (message.operatorReport && message.operatorReport.length) {
                    object.operatorReport = [];
                    for (var j = 0; j < message.operatorReport.length; ++j)
                        object.operatorReport[j] = $root.realtalk.manager.OperatorReport.toObject(message.operatorReport[j], options);
                }
                return object;
            };

            /**
             * Converts this OperatorReportList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.OperatorReportList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperatorReportList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OperatorReportList;
        })();

        /**
         * OperatorRole enum.
         * @name realtalk.manager.OperatorRole
         * @enum {number}
         * @property {number} OR_MANAGE_OPERATORS=0 OR_MANAGE_OPERATORS value
         * @property {number} OR_CHAT_OPERATOR=1 OR_CHAT_OPERATOR value
         */
        manager.OperatorRole = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OR_MANAGE_OPERATORS"] = 0;
            values[valuesById[1] = "OR_CHAT_OPERATOR"] = 1;
            return values;
        })();

        manager.Operator = (function() {

            /**
             * Properties of an Operator.
             * @memberof realtalk.manager
             * @interface IOperator
             * @property {number|Long|null} [id] Operator id
             * @property {string|null} [password] Operator password
             * @property {string|null} [name] Operator name
             * @property {string|null} [email] Operator email
             * @property {string|null} [phone] Operator phone
             * @property {Array.<realtalk.manager.OperatorRole>|null} [role] Operator role
             * @property {boolean|null} [deleted] Operator deleted
             * @property {boolean|null} [activated] Operator activated
             * @property {string|null} [avatar] Operator avatar
             * @property {string|null} [externalId] Operator externalId
             */

            /**
             * Constructs a new Operator.
             * @memberof realtalk.manager
             * @classdesc Represents an Operator.
             * @implements IOperator
             * @constructor
             * @param {realtalk.manager.IOperator=} [properties] Properties to set
             */
            function Operator(properties) {
                this.role = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Operator id.
             * @member {number|Long} id
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Operator password.
             * @member {string} password
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.password = "";

            /**
             * Operator name.
             * @member {string} name
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.name = "";

            /**
             * Operator email.
             * @member {string} email
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.email = "";

            /**
             * Operator phone.
             * @member {string} phone
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.phone = "";

            /**
             * Operator role.
             * @member {Array.<realtalk.manager.OperatorRole>} role
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.role = $util.emptyArray;

            /**
             * Operator deleted.
             * @member {boolean} deleted
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.deleted = false;

            /**
             * Operator activated.
             * @member {boolean} activated
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.activated = false;

            /**
             * Operator avatar.
             * @member {string} avatar
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.avatar = "";

            /**
             * Operator externalId.
             * @member {string} externalId
             * @memberof realtalk.manager.Operator
             * @instance
             */
            Operator.prototype.externalId = "";

            /**
             * Creates a new Operator instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Operator
             * @static
             * @param {realtalk.manager.IOperator=} [properties] Properties to set
             * @returns {realtalk.manager.Operator} Operator instance
             */
            Operator.create = function create(properties) {
                return new Operator(properties);
            };

            /**
             * Encodes the specified Operator message. Does not implicitly {@link realtalk.manager.Operator.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Operator
             * @static
             * @param {realtalk.manager.IOperator} message Operator message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Operator.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
                if (message.role != null && message.role.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (var i = 0; i < message.role.length; ++i)
                        writer.int32(message.role[i]);
                    writer.ldelim();
                }
                if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.deleted);
                if (message.activated != null && Object.hasOwnProperty.call(message, "activated"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.activated);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.avatar);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.externalId);
                return writer;
            };

            /**
             * Decodes an Operator message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Operator
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Operator} Operator
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Operator.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Operator();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.password = reader.string();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        message.phone = reader.string();
                        break;
                    case 6:
                        if (!(message.role && message.role.length))
                            message.role = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.role.push(reader.int32());
                        } else
                            message.role.push(reader.int32());
                        break;
                    case 7:
                        message.deleted = reader.bool();
                        break;
                    case 8:
                        message.activated = reader.bool();
                        break;
                    case 9:
                        message.avatar = reader.string();
                        break;
                    case 10:
                        message.externalId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Operator message.
             * @function verify
             * @memberof realtalk.manager.Operator
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Operator.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.role != null && message.hasOwnProperty("role")) {
                    if (!Array.isArray(message.role))
                        return "role: array expected";
                    for (var i = 0; i < message.role.length; ++i)
                        switch (message.role[i]) {
                        default:
                            return "role: enum value[] expected";
                        case 0:
                        case 1:
                            break;
                        }
                }
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    if (typeof message.deleted !== "boolean")
                        return "deleted: boolean expected";
                if (message.activated != null && message.hasOwnProperty("activated"))
                    if (typeof message.activated !== "boolean")
                        return "activated: boolean expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isString(message.externalId))
                        return "externalId: string expected";
                return null;
            };

            /**
             * Creates an Operator message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Operator
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Operator} Operator
             */
            Operator.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Operator)
                    return object;
                var message = new $root.realtalk.manager.Operator();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.role) {
                    if (!Array.isArray(object.role))
                        throw TypeError(".realtalk.manager.Operator.role: array expected");
                    message.role = [];
                    for (var i = 0; i < object.role.length; ++i)
                        switch (object.role[i]) {
                        default:
                        case "OR_MANAGE_OPERATORS":
                        case 0:
                            message.role[i] = 0;
                            break;
                        case "OR_CHAT_OPERATOR":
                        case 1:
                            message.role[i] = 1;
                            break;
                        }
                }
                if (object.deleted != null)
                    message.deleted = Boolean(object.deleted);
                if (object.activated != null)
                    message.activated = Boolean(object.activated);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.externalId != null)
                    message.externalId = String(object.externalId);
                return message;
            };

            /**
             * Creates a plain object from an Operator message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Operator
             * @static
             * @param {realtalk.manager.Operator} message Operator
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Operator.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.role = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.password = "";
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                    object.deleted = false;
                    object.activated = false;
                    object.avatar = "";
                    object.externalId = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.role && message.role.length) {
                    object.role = [];
                    for (var j = 0; j < message.role.length; ++j)
                        object.role[j] = options.enums === String ? $root.realtalk.manager.OperatorRole[message.role[j]] : message.role[j];
                }
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    object.deleted = message.deleted;
                if (message.activated != null && message.hasOwnProperty("activated"))
                    object.activated = message.activated;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                return object;
            };

            /**
             * Converts this Operator to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Operator
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Operator.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Operator;
        })();

        manager.OperatorList = (function() {

            /**
             * Properties of an OperatorList.
             * @memberof realtalk.manager
             * @interface IOperatorList
             * @property {Array.<realtalk.manager.IOperator>|null} [operator] OperatorList operator
             */

            /**
             * Constructs a new OperatorList.
             * @memberof realtalk.manager
             * @classdesc Represents an OperatorList.
             * @implements IOperatorList
             * @constructor
             * @param {realtalk.manager.IOperatorList=} [properties] Properties to set
             */
            function OperatorList(properties) {
                this.operator = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperatorList operator.
             * @member {Array.<realtalk.manager.IOperator>} operator
             * @memberof realtalk.manager.OperatorList
             * @instance
             */
            OperatorList.prototype.operator = $util.emptyArray;

            /**
             * Creates a new OperatorList instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {realtalk.manager.IOperatorList=} [properties] Properties to set
             * @returns {realtalk.manager.OperatorList} OperatorList instance
             */
            OperatorList.create = function create(properties) {
                return new OperatorList(properties);
            };

            /**
             * Encodes the specified OperatorList message. Does not implicitly {@link realtalk.manager.OperatorList.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {realtalk.manager.IOperatorList} message OperatorList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperatorList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operator != null && message.operator.length)
                    for (var i = 0; i < message.operator.length; ++i)
                        $root.realtalk.manager.Operator.encode(message.operator[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OperatorList message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.OperatorList} OperatorList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperatorList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.OperatorList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.operator && message.operator.length))
                            message.operator = [];
                        message.operator.push($root.realtalk.manager.Operator.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OperatorList message.
             * @function verify
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperatorList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operator != null && message.hasOwnProperty("operator")) {
                    if (!Array.isArray(message.operator))
                        return "operator: array expected";
                    for (var i = 0; i < message.operator.length; ++i) {
                        var error = $root.realtalk.manager.Operator.verify(message.operator[i]);
                        if (error)
                            return "operator." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OperatorList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.OperatorList} OperatorList
             */
            OperatorList.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.OperatorList)
                    return object;
                var message = new $root.realtalk.manager.OperatorList();
                if (object.operator) {
                    if (!Array.isArray(object.operator))
                        throw TypeError(".realtalk.manager.OperatorList.operator: array expected");
                    message.operator = [];
                    for (var i = 0; i < object.operator.length; ++i) {
                        if (typeof object.operator[i] !== "object")
                            throw TypeError(".realtalk.manager.OperatorList.operator: object expected");
                        message.operator[i] = $root.realtalk.manager.Operator.fromObject(object.operator[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OperatorList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.OperatorList
             * @static
             * @param {realtalk.manager.OperatorList} message OperatorList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperatorList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.operator = [];
                if (message.operator && message.operator.length) {
                    object.operator = [];
                    for (var j = 0; j < message.operator.length; ++j)
                        object.operator[j] = $root.realtalk.manager.Operator.toObject(message.operator[j], options);
                }
                return object;
            };

            /**
             * Converts this OperatorList to JSON.
             * @function toJSON
             * @memberof realtalk.manager.OperatorList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperatorList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OperatorList;
        })();

        manager.Organization = (function() {

            /**
             * Properties of an Organization.
             * @memberof realtalk.manager
             * @interface IOrganization
             * @property {string|null} [appId] Organization appId
             * @property {string|null} [name] Organization name
             * @property {Array.<string>|null} [domain] Organization domain
             * @property {number|Long|null} [ownerOperatorId] Organization ownerOperatorId
             */

            /**
             * Constructs a new Organization.
             * @memberof realtalk.manager
             * @classdesc Represents an Organization.
             * @implements IOrganization
             * @constructor
             * @param {realtalk.manager.IOrganization=} [properties] Properties to set
             */
            function Organization(properties) {
                this.domain = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Organization appId.
             * @member {string} appId
             * @memberof realtalk.manager.Organization
             * @instance
             */
            Organization.prototype.appId = "";

            /**
             * Organization name.
             * @member {string} name
             * @memberof realtalk.manager.Organization
             * @instance
             */
            Organization.prototype.name = "";

            /**
             * Organization domain.
             * @member {Array.<string>} domain
             * @memberof realtalk.manager.Organization
             * @instance
             */
            Organization.prototype.domain = $util.emptyArray;

            /**
             * Organization ownerOperatorId.
             * @member {number|Long} ownerOperatorId
             * @memberof realtalk.manager.Organization
             * @instance
             */
            Organization.prototype.ownerOperatorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Organization instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Organization
             * @static
             * @param {realtalk.manager.IOrganization=} [properties] Properties to set
             * @returns {realtalk.manager.Organization} Organization instance
             */
            Organization.create = function create(properties) {
                return new Organization(properties);
            };

            /**
             * Encodes the specified Organization message. Does not implicitly {@link realtalk.manager.Organization.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Organization
             * @static
             * @param {realtalk.manager.IOrganization} message Organization message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.appId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.domain != null && message.domain.length)
                    for (var i = 0; i < message.domain.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.domain[i]);
                if (message.ownerOperatorId != null && Object.hasOwnProperty.call(message, "ownerOperatorId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.ownerOperatorId);
                return writer;
            };

            /**
             * Decodes an Organization message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Organization
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Organization} Organization
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Organization();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.appId = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        if (!(message.domain && message.domain.length))
                            message.domain = [];
                        message.domain.push(reader.string());
                        break;
                    case 4:
                        message.ownerOperatorId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Organization message.
             * @function verify
             * @memberof realtalk.manager.Organization
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Organization.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.appId != null && message.hasOwnProperty("appId"))
                    if (!$util.isString(message.appId))
                        return "appId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.domain != null && message.hasOwnProperty("domain")) {
                    if (!Array.isArray(message.domain))
                        return "domain: array expected";
                    for (var i = 0; i < message.domain.length; ++i)
                        if (!$util.isString(message.domain[i]))
                            return "domain: string[] expected";
                }
                if (message.ownerOperatorId != null && message.hasOwnProperty("ownerOperatorId"))
                    if (!$util.isInteger(message.ownerOperatorId) && !(message.ownerOperatorId && $util.isInteger(message.ownerOperatorId.low) && $util.isInteger(message.ownerOperatorId.high)))
                        return "ownerOperatorId: integer|Long expected";
                return null;
            };

            /**
             * Creates an Organization message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Organization
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Organization} Organization
             */
            Organization.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Organization)
                    return object;
                var message = new $root.realtalk.manager.Organization();
                if (object.appId != null)
                    message.appId = String(object.appId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.domain) {
                    if (!Array.isArray(object.domain))
                        throw TypeError(".realtalk.manager.Organization.domain: array expected");
                    message.domain = [];
                    for (var i = 0; i < object.domain.length; ++i)
                        message.domain[i] = String(object.domain[i]);
                }
                if (object.ownerOperatorId != null)
                    if ($util.Long)
                        (message.ownerOperatorId = $util.Long.fromValue(object.ownerOperatorId)).unsigned = true;
                    else if (typeof object.ownerOperatorId === "string")
                        message.ownerOperatorId = parseInt(object.ownerOperatorId, 10);
                    else if (typeof object.ownerOperatorId === "number")
                        message.ownerOperatorId = object.ownerOperatorId;
                    else if (typeof object.ownerOperatorId === "object")
                        message.ownerOperatorId = new $util.LongBits(object.ownerOperatorId.low >>> 0, object.ownerOperatorId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an Organization message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Organization
             * @static
             * @param {realtalk.manager.Organization} message Organization
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.domain = [];
                if (options.defaults) {
                    object.appId = "";
                    object.name = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.ownerOperatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ownerOperatorId = options.longs === String ? "0" : 0;
                }
                if (message.appId != null && message.hasOwnProperty("appId"))
                    object.appId = message.appId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.domain && message.domain.length) {
                    object.domain = [];
                    for (var j = 0; j < message.domain.length; ++j)
                        object.domain[j] = message.domain[j];
                }
                if (message.ownerOperatorId != null && message.hasOwnProperty("ownerOperatorId"))
                    if (typeof message.ownerOperatorId === "number")
                        object.ownerOperatorId = options.longs === String ? String(message.ownerOperatorId) : message.ownerOperatorId;
                    else
                        object.ownerOperatorId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerOperatorId) : options.longs === Number ? new $util.LongBits(message.ownerOperatorId.low >>> 0, message.ownerOperatorId.high >>> 0).toNumber(true) : message.ownerOperatorId;
                return object;
            };

            /**
             * Converts this Organization to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Organization
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Organization;
        })();

        manager.RemoveFromLine = (function() {

            /**
             * Properties of a RemoveFromLine.
             * @memberof realtalk.manager
             * @interface IRemoveFromLine
             * @property {number|Long|null} [operatorId] RemoveFromLine operatorId
             * @property {number|Long|null} [position] RemoveFromLine position
             */

            /**
             * Constructs a new RemoveFromLine.
             * @memberof realtalk.manager
             * @classdesc Represents a RemoveFromLine.
             * @implements IRemoveFromLine
             * @constructor
             * @param {realtalk.manager.IRemoveFromLine=} [properties] Properties to set
             */
            function RemoveFromLine(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveFromLine operatorId.
             * @member {number|Long} operatorId
             * @memberof realtalk.manager.RemoveFromLine
             * @instance
             */
            RemoveFromLine.prototype.operatorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RemoveFromLine position.
             * @member {number|Long} position
             * @memberof realtalk.manager.RemoveFromLine
             * @instance
             */
            RemoveFromLine.prototype.position = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RemoveFromLine instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {realtalk.manager.IRemoveFromLine=} [properties] Properties to set
             * @returns {realtalk.manager.RemoveFromLine} RemoveFromLine instance
             */
            RemoveFromLine.create = function create(properties) {
                return new RemoveFromLine(properties);
            };

            /**
             * Encodes the specified RemoveFromLine message. Does not implicitly {@link realtalk.manager.RemoveFromLine.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {realtalk.manager.IRemoveFromLine} message RemoveFromLine message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveFromLine.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operatorId != null && Object.hasOwnProperty.call(message, "operatorId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorId);
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.position);
                return writer;
            };

            /**
             * Decodes a RemoveFromLine message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.RemoveFromLine} RemoveFromLine
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveFromLine.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.RemoveFromLine();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.operatorId = reader.uint64();
                        break;
                    case 2:
                        message.position = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RemoveFromLine message.
             * @function verify
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveFromLine.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (!$util.isInteger(message.operatorId) && !(message.operatorId && $util.isInteger(message.operatorId.low) && $util.isInteger(message.operatorId.high)))
                        return "operatorId: integer|Long expected";
                if (message.position != null && message.hasOwnProperty("position"))
                    if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                        return "position: integer|Long expected";
                return null;
            };

            /**
             * Creates a RemoveFromLine message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.RemoveFromLine} RemoveFromLine
             */
            RemoveFromLine.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.RemoveFromLine)
                    return object;
                var message = new $root.realtalk.manager.RemoveFromLine();
                if (object.operatorId != null)
                    if ($util.Long)
                        (message.operatorId = $util.Long.fromValue(object.operatorId)).unsigned = true;
                    else if (typeof object.operatorId === "string")
                        message.operatorId = parseInt(object.operatorId, 10);
                    else if (typeof object.operatorId === "number")
                        message.operatorId = object.operatorId;
                    else if (typeof object.operatorId === "object")
                        message.operatorId = new $util.LongBits(object.operatorId.low >>> 0, object.operatorId.high >>> 0).toNumber(true);
                if (object.position != null)
                    if ($util.Long)
                        (message.position = $util.Long.fromValue(object.position)).unsigned = true;
                    else if (typeof object.position === "string")
                        message.position = parseInt(object.position, 10);
                    else if (typeof object.position === "number")
                        message.position = object.position;
                    else if (typeof object.position === "object")
                        message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RemoveFromLine message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.RemoveFromLine
             * @static
             * @param {realtalk.manager.RemoveFromLine} message RemoveFromLine
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveFromLine.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.operatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.operatorId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.position = options.longs === String ? "0" : 0;
                }
                if (message.operatorId != null && message.hasOwnProperty("operatorId"))
                    if (typeof message.operatorId === "number")
                        object.operatorId = options.longs === String ? String(message.operatorId) : message.operatorId;
                    else
                        object.operatorId = options.longs === String ? $util.Long.prototype.toString.call(message.operatorId) : options.longs === Number ? new $util.LongBits(message.operatorId.low >>> 0, message.operatorId.high >>> 0).toNumber(true) : message.operatorId;
                if (message.position != null && message.hasOwnProperty("position"))
                    if (typeof message.position === "number")
                        object.position = options.longs === String ? String(message.position) : message.position;
                    else
                        object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber(true) : message.position;
                return object;
            };

            /**
             * Converts this RemoveFromLine to JSON.
             * @function toJSON
             * @memberof realtalk.manager.RemoveFromLine
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveFromLine.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveFromLine;
        })();

        manager.RemoveOperatorRoom = (function() {

            /**
             * Properties of a RemoveOperatorRoom.
             * @memberof realtalk.manager
             * @interface IRemoveOperatorRoom
             * @property {number|Long|null} [chatId] RemoveOperatorRoom chatId
             */

            /**
             * Constructs a new RemoveOperatorRoom.
             * @memberof realtalk.manager
             * @classdesc Represents a RemoveOperatorRoom.
             * @implements IRemoveOperatorRoom
             * @constructor
             * @param {realtalk.manager.IRemoveOperatorRoom=} [properties] Properties to set
             */
            function RemoveOperatorRoom(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveOperatorRoom chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @instance
             */
            RemoveOperatorRoom.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RemoveOperatorRoom instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {realtalk.manager.IRemoveOperatorRoom=} [properties] Properties to set
             * @returns {realtalk.manager.RemoveOperatorRoom} RemoveOperatorRoom instance
             */
            RemoveOperatorRoom.create = function create(properties) {
                return new RemoveOperatorRoom(properties);
            };

            /**
             * Encodes the specified RemoveOperatorRoom message. Does not implicitly {@link realtalk.manager.RemoveOperatorRoom.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {realtalk.manager.IRemoveOperatorRoom} message RemoveOperatorRoom message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveOperatorRoom.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a RemoveOperatorRoom message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.RemoveOperatorRoom} RemoveOperatorRoom
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveOperatorRoom.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.RemoveOperatorRoom();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RemoveOperatorRoom message.
             * @function verify
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveOperatorRoom.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a RemoveOperatorRoom message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.RemoveOperatorRoom} RemoveOperatorRoom
             */
            RemoveOperatorRoom.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.RemoveOperatorRoom)
                    return object;
                var message = new $root.realtalk.manager.RemoveOperatorRoom();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RemoveOperatorRoom message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @static
             * @param {realtalk.manager.RemoveOperatorRoom} message RemoveOperatorRoom
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveOperatorRoom.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this RemoveOperatorRoom to JSON.
             * @function toJSON
             * @memberof realtalk.manager.RemoveOperatorRoom
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveOperatorRoom.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveOperatorRoom;
        })();

        manager.RemoveOrganizationDomain = (function() {

            /**
             * Properties of a RemoveOrganizationDomain.
             * @memberof realtalk.manager
             * @interface IRemoveOrganizationDomain
             * @property {string|null} [domain] RemoveOrganizationDomain domain
             */

            /**
             * Constructs a new RemoveOrganizationDomain.
             * @memberof realtalk.manager
             * @classdesc Represents a RemoveOrganizationDomain.
             * @implements IRemoveOrganizationDomain
             * @constructor
             * @param {realtalk.manager.IRemoveOrganizationDomain=} [properties] Properties to set
             */
            function RemoveOrganizationDomain(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveOrganizationDomain domain.
             * @member {string} domain
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @instance
             */
            RemoveOrganizationDomain.prototype.domain = "";

            /**
             * Creates a new RemoveOrganizationDomain instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {realtalk.manager.IRemoveOrganizationDomain=} [properties] Properties to set
             * @returns {realtalk.manager.RemoveOrganizationDomain} RemoveOrganizationDomain instance
             */
            RemoveOrganizationDomain.create = function create(properties) {
                return new RemoveOrganizationDomain(properties);
            };

            /**
             * Encodes the specified RemoveOrganizationDomain message. Does not implicitly {@link realtalk.manager.RemoveOrganizationDomain.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {realtalk.manager.IRemoveOrganizationDomain} message RemoveOrganizationDomain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveOrganizationDomain.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.domain);
                return writer;
            };

            /**
             * Decodes a RemoveOrganizationDomain message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.RemoveOrganizationDomain} RemoveOrganizationDomain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveOrganizationDomain.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.RemoveOrganizationDomain();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.domain = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RemoveOrganizationDomain message.
             * @function verify
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveOrganizationDomain.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    if (!$util.isString(message.domain))
                        return "domain: string expected";
                return null;
            };

            /**
             * Creates a RemoveOrganizationDomain message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.RemoveOrganizationDomain} RemoveOrganizationDomain
             */
            RemoveOrganizationDomain.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.RemoveOrganizationDomain)
                    return object;
                var message = new $root.realtalk.manager.RemoveOrganizationDomain();
                if (object.domain != null)
                    message.domain = String(object.domain);
                return message;
            };

            /**
             * Creates a plain object from a RemoveOrganizationDomain message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @static
             * @param {realtalk.manager.RemoveOrganizationDomain} message RemoveOrganizationDomain
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveOrganizationDomain.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.domain = "";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = message.domain;
                return object;
            };

            /**
             * Converts this RemoveOrganizationDomain to JSON.
             * @function toJSON
             * @memberof realtalk.manager.RemoveOrganizationDomain
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveOrganizationDomain.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveOrganizationDomain;
        })();

        manager.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof realtalk.manager
             * @interface IResult
             * @property {realtalk.manager.Result.Code|null} [code] Result code
             * @property {string|null} [description] Result description
             */

            /**
             * Constructs a new Result.
             * @memberof realtalk.manager
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {realtalk.manager.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {realtalk.manager.Result.Code} code
             * @memberof realtalk.manager.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result description.
             * @member {string} description
             * @memberof realtalk.manager.Result
             * @instance
             */
            Result.prototype.description = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Result
             * @static
             * @param {realtalk.manager.IResult=} [properties] Properties to set
             * @returns {realtalk.manager.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link realtalk.manager.Result.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Result
             * @static
             * @param {realtalk.manager.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof realtalk.manager.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Result)
                    return object;
                var message = new $root.realtalk.manager.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "FAIL_INVALID_REQUEST":
                case 1:
                    message.code = 1;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Result
             * @static
             * @param {realtalk.manager.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.realtalk.manager.Result.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name realtalk.manager.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} FAIL_INVALID_REQUEST=1 FAIL_INVALID_REQUEST value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "FAIL_INVALID_REQUEST"] = 1;
                return values;
            })();

            return Result;
        })();

        manager.SendToNextLine = (function() {

            /**
             * Properties of a SendToNextLine.
             * @memberof realtalk.manager
             * @interface ISendToNextLine
             * @property {number|Long|null} [chatId] SendToNextLine chatId
             */

            /**
             * Constructs a new SendToNextLine.
             * @memberof realtalk.manager
             * @classdesc Represents a SendToNextLine.
             * @implements ISendToNextLine
             * @constructor
             * @param {realtalk.manager.ISendToNextLine=} [properties] Properties to set
             */
            function SendToNextLine(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SendToNextLine chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.SendToNextLine
             * @instance
             */
            SendToNextLine.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new SendToNextLine instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {realtalk.manager.ISendToNextLine=} [properties] Properties to set
             * @returns {realtalk.manager.SendToNextLine} SendToNextLine instance
             */
            SendToNextLine.create = function create(properties) {
                return new SendToNextLine(properties);
            };

            /**
             * Encodes the specified SendToNextLine message. Does not implicitly {@link realtalk.manager.SendToNextLine.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {realtalk.manager.ISendToNextLine} message SendToNextLine message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendToNextLine.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                return writer;
            };

            /**
             * Decodes a SendToNextLine message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.SendToNextLine} SendToNextLine
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendToNextLine.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.SendToNextLine();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SendToNextLine message.
             * @function verify
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendToNextLine.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a SendToNextLine message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.SendToNextLine} SendToNextLine
             */
            SendToNextLine.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.SendToNextLine)
                    return object;
                var message = new $root.realtalk.manager.SendToNextLine();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SendToNextLine message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.SendToNextLine
             * @static
             * @param {realtalk.manager.SendToNextLine} message SendToNextLine
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendToNextLine.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                return object;
            };

            /**
             * Converts this SendToNextLine to JSON.
             * @function toJSON
             * @memberof realtalk.manager.SendToNextLine
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendToNextLine.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SendToNextLine;
        })();

        manager.ServerMessage = (function() {

            /**
             * Properties of a ServerMessage.
             * @memberof realtalk.manager
             * @interface IServerMessage
             * @property {number|Long|null} [id] ServerMessage id
             * @property {realtalk.manager.IResult|null} [result] ServerMessage result
             * @property {realtalk.manager.IOperator|null} [operator] ServerMessage operator
             * @property {realtalk.manager.IOperatorList|null} [operatorList] ServerMessage operatorList
             * @property {realtalk.manager.IMessage|null} [message] ServerMessage message
             * @property {realtalk.manager.IMessageList|null} [messageList] ServerMessage messageList
             * @property {realtalk.manager.IChat|null} [chat] ServerMessage chat
             * @property {realtalk.manager.IChatList|null} [chatList] ServerMessage chatList
             * @property {realtalk.manager.IChatUser|null} [chatUser] ServerMessage chatUser
             * @property {realtalk.manager.ILine|null} [line] ServerMessage line
             * @property {realtalk.manager.ILineList|null} [lineList] ServerMessage lineList
             * @property {realtalk.manager.IMessageRating|null} [messageRating] ServerMessage messageRating
             * @property {realtalk.manager.IOperatorReportList|null} [operatorReportList] ServerMessage operatorReportList
             * @property {realtalk.manager.IOrganization|null} [organization] ServerMessage organization
             * @property {realtalk.manager.IJwtKey|null} [jwtKey] ServerMessage jwtKey
             * @property {realtalk.manager.IJwtKeyList|null} [jwtKeyList] ServerMessage jwtKeyList
             * @property {realtalk.manager.ILastActivity|null} [lastActivity] ServerMessage lastActivity
             * @property {realtalk.manager.INumberUnreadMessages|null} [numberUnreadMessages] ServerMessage numberUnreadMessages
             */

            /**
             * Constructs a new ServerMessage.
             * @memberof realtalk.manager
             * @classdesc Represents a ServerMessage.
             * @implements IServerMessage
             * @constructor
             * @param {realtalk.manager.IServerMessage=} [properties] Properties to set
             */
            function ServerMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerMessage id.
             * @member {number|Long} id
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ServerMessage result.
             * @member {realtalk.manager.IResult|null|undefined} result
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.result = null;

            /**
             * ServerMessage operator.
             * @member {realtalk.manager.IOperator|null|undefined} operator
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.operator = null;

            /**
             * ServerMessage operatorList.
             * @member {realtalk.manager.IOperatorList|null|undefined} operatorList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.operatorList = null;

            /**
             * ServerMessage message.
             * @member {realtalk.manager.IMessage|null|undefined} message
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.message = null;

            /**
             * ServerMessage messageList.
             * @member {realtalk.manager.IMessageList|null|undefined} messageList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.messageList = null;

            /**
             * ServerMessage chat.
             * @member {realtalk.manager.IChat|null|undefined} chat
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.chat = null;

            /**
             * ServerMessage chatList.
             * @member {realtalk.manager.IChatList|null|undefined} chatList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.chatList = null;

            /**
             * ServerMessage chatUser.
             * @member {realtalk.manager.IChatUser|null|undefined} chatUser
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.chatUser = null;

            /**
             * ServerMessage line.
             * @member {realtalk.manager.ILine|null|undefined} line
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.line = null;

            /**
             * ServerMessage lineList.
             * @member {realtalk.manager.ILineList|null|undefined} lineList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.lineList = null;

            /**
             * ServerMessage messageRating.
             * @member {realtalk.manager.IMessageRating|null|undefined} messageRating
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.messageRating = null;

            /**
             * ServerMessage operatorReportList.
             * @member {realtalk.manager.IOperatorReportList|null|undefined} operatorReportList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.operatorReportList = null;

            /**
             * ServerMessage organization.
             * @member {realtalk.manager.IOrganization|null|undefined} organization
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.organization = null;

            /**
             * ServerMessage jwtKey.
             * @member {realtalk.manager.IJwtKey|null|undefined} jwtKey
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.jwtKey = null;

            /**
             * ServerMessage jwtKeyList.
             * @member {realtalk.manager.IJwtKeyList|null|undefined} jwtKeyList
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.jwtKeyList = null;

            /**
             * ServerMessage lastActivity.
             * @member {realtalk.manager.ILastActivity|null|undefined} lastActivity
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.lastActivity = null;

            /**
             * ServerMessage numberUnreadMessages.
             * @member {realtalk.manager.INumberUnreadMessages|null|undefined} numberUnreadMessages
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            ServerMessage.prototype.numberUnreadMessages = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ServerMessage body.
             * @member {"result"|"operator"|"operatorList"|"message"|"messageList"|"chat"|"chatList"|"chatUser"|"line"|"lineList"|"messageRating"|"operatorReportList"|"organization"|"jwtKey"|"jwtKeyList"|"lastActivity"|"numberUnreadMessages"|undefined} body
             * @memberof realtalk.manager.ServerMessage
             * @instance
             */
            Object.defineProperty(ServerMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "operator", "operatorList", "message", "messageList", "chat", "chatList", "chatUser", "line", "lineList", "messageRating", "operatorReportList", "organization", "jwtKey", "jwtKeyList", "lastActivity", "numberUnreadMessages"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ServerMessage instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {realtalk.manager.IServerMessage=} [properties] Properties to set
             * @returns {realtalk.manager.ServerMessage} ServerMessage instance
             */
            ServerMessage.create = function create(properties) {
                return new ServerMessage(properties);
            };

            /**
             * Encodes the specified ServerMessage message. Does not implicitly {@link realtalk.manager.ServerMessage.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {realtalk.manager.IServerMessage} message ServerMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.realtalk.manager.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                    $root.realtalk.manager.Operator.encode(message.operator, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.operatorList != null && Object.hasOwnProperty.call(message, "operatorList"))
                    $root.realtalk.manager.OperatorList.encode(message.operatorList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    $root.realtalk.manager.Message.encode(message.message, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.messageList != null && Object.hasOwnProperty.call(message, "messageList"))
                    $root.realtalk.manager.MessageList.encode(message.messageList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
                    $root.realtalk.manager.Chat.encode(message.chat, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.chatList != null && Object.hasOwnProperty.call(message, "chatList"))
                    $root.realtalk.manager.ChatList.encode(message.chatList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.chatUser != null && Object.hasOwnProperty.call(message, "chatUser"))
                    $root.realtalk.manager.ChatUser.encode(message.chatUser, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.line != null && Object.hasOwnProperty.call(message, "line"))
                    $root.realtalk.manager.Line.encode(message.line, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.lineList != null && Object.hasOwnProperty.call(message, "lineList"))
                    $root.realtalk.manager.LineList.encode(message.lineList, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.messageRating != null && Object.hasOwnProperty.call(message, "messageRating"))
                    $root.realtalk.manager.MessageRating.encode(message.messageRating, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.operatorReportList != null && Object.hasOwnProperty.call(message, "operatorReportList"))
                    $root.realtalk.manager.OperatorReportList.encode(message.operatorReportList, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.organization != null && Object.hasOwnProperty.call(message, "organization"))
                    $root.realtalk.manager.Organization.encode(message.organization, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.jwtKey != null && Object.hasOwnProperty.call(message, "jwtKey"))
                    $root.realtalk.manager.JwtKey.encode(message.jwtKey, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.jwtKeyList != null && Object.hasOwnProperty.call(message, "jwtKeyList"))
                    $root.realtalk.manager.JwtKeyList.encode(message.jwtKeyList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.lastActivity != null && Object.hasOwnProperty.call(message, "lastActivity"))
                    $root.realtalk.manager.LastActivity.encode(message.lastActivity, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.numberUnreadMessages != null && Object.hasOwnProperty.call(message, "numberUnreadMessages"))
                    $root.realtalk.manager.NumberUnreadMessages.encode(message.numberUnreadMessages, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerMessage message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.ServerMessage} ServerMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.ServerMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.realtalk.manager.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.operator = $root.realtalk.manager.Operator.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.operatorList = $root.realtalk.manager.OperatorList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.message = $root.realtalk.manager.Message.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.messageList = $root.realtalk.manager.MessageList.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.chat = $root.realtalk.manager.Chat.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.chatList = $root.realtalk.manager.ChatList.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.chatUser = $root.realtalk.manager.ChatUser.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.line = $root.realtalk.manager.Line.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.lineList = $root.realtalk.manager.LineList.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.messageRating = $root.realtalk.manager.MessageRating.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.operatorReportList = $root.realtalk.manager.OperatorReportList.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.organization = $root.realtalk.manager.Organization.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.jwtKey = $root.realtalk.manager.JwtKey.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.jwtKeyList = $root.realtalk.manager.JwtKeyList.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.lastActivity = $root.realtalk.manager.LastActivity.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.numberUnreadMessages = $root.realtalk.manager.NumberUnreadMessages.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerMessage message.
             * @function verify
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.operator != null && message.hasOwnProperty("operator")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Operator.verify(message.operator);
                        if (error)
                            return "operator." + error;
                    }
                }
                if (message.operatorList != null && message.hasOwnProperty("operatorList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.OperatorList.verify(message.operatorList);
                        if (error)
                            return "operatorList." + error;
                    }
                }
                if (message.message != null && message.hasOwnProperty("message")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Message.verify(message.message);
                        if (error)
                            return "message." + error;
                    }
                }
                if (message.messageList != null && message.hasOwnProperty("messageList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.MessageList.verify(message.messageList);
                        if (error)
                            return "messageList." + error;
                    }
                }
                if (message.chat != null && message.hasOwnProperty("chat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Chat.verify(message.chat);
                        if (error)
                            return "chat." + error;
                    }
                }
                if (message.chatList != null && message.hasOwnProperty("chatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.ChatList.verify(message.chatList);
                        if (error)
                            return "chatList." + error;
                    }
                }
                if (message.chatUser != null && message.hasOwnProperty("chatUser")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.ChatUser.verify(message.chatUser);
                        if (error)
                            return "chatUser." + error;
                    }
                }
                if (message.line != null && message.hasOwnProperty("line")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Line.verify(message.line);
                        if (error)
                            return "line." + error;
                    }
                }
                if (message.lineList != null && message.hasOwnProperty("lineList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.LineList.verify(message.lineList);
                        if (error)
                            return "lineList." + error;
                    }
                }
                if (message.messageRating != null && message.hasOwnProperty("messageRating")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.MessageRating.verify(message.messageRating);
                        if (error)
                            return "messageRating." + error;
                    }
                }
                if (message.operatorReportList != null && message.hasOwnProperty("operatorReportList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.OperatorReportList.verify(message.operatorReportList);
                        if (error)
                            return "operatorReportList." + error;
                    }
                }
                if (message.organization != null && message.hasOwnProperty("organization")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.Organization.verify(message.organization);
                        if (error)
                            return "organization." + error;
                    }
                }
                if (message.jwtKey != null && message.hasOwnProperty("jwtKey")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.JwtKey.verify(message.jwtKey);
                        if (error)
                            return "jwtKey." + error;
                    }
                }
                if (message.jwtKeyList != null && message.hasOwnProperty("jwtKeyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.JwtKeyList.verify(message.jwtKeyList);
                        if (error)
                            return "jwtKeyList." + error;
                    }
                }
                if (message.lastActivity != null && message.hasOwnProperty("lastActivity")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.LastActivity.verify(message.lastActivity);
                        if (error)
                            return "lastActivity." + error;
                    }
                }
                if (message.numberUnreadMessages != null && message.hasOwnProperty("numberUnreadMessages")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.realtalk.manager.NumberUnreadMessages.verify(message.numberUnreadMessages);
                        if (error)
                            return "numberUnreadMessages." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.ServerMessage} ServerMessage
             */
            ServerMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.ServerMessage)
                    return object;
                var message = new $root.realtalk.manager.ServerMessage();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.result: object expected");
                    message.result = $root.realtalk.manager.Result.fromObject(object.result);
                }
                if (object.operator != null) {
                    if (typeof object.operator !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.operator: object expected");
                    message.operator = $root.realtalk.manager.Operator.fromObject(object.operator);
                }
                if (object.operatorList != null) {
                    if (typeof object.operatorList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.operatorList: object expected");
                    message.operatorList = $root.realtalk.manager.OperatorList.fromObject(object.operatorList);
                }
                if (object.message != null) {
                    if (typeof object.message !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.message: object expected");
                    message.message = $root.realtalk.manager.Message.fromObject(object.message);
                }
                if (object.messageList != null) {
                    if (typeof object.messageList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.messageList: object expected");
                    message.messageList = $root.realtalk.manager.MessageList.fromObject(object.messageList);
                }
                if (object.chat != null) {
                    if (typeof object.chat !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.chat: object expected");
                    message.chat = $root.realtalk.manager.Chat.fromObject(object.chat);
                }
                if (object.chatList != null) {
                    if (typeof object.chatList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.chatList: object expected");
                    message.chatList = $root.realtalk.manager.ChatList.fromObject(object.chatList);
                }
                if (object.chatUser != null) {
                    if (typeof object.chatUser !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.chatUser: object expected");
                    message.chatUser = $root.realtalk.manager.ChatUser.fromObject(object.chatUser);
                }
                if (object.line != null) {
                    if (typeof object.line !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.line: object expected");
                    message.line = $root.realtalk.manager.Line.fromObject(object.line);
                }
                if (object.lineList != null) {
                    if (typeof object.lineList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.lineList: object expected");
                    message.lineList = $root.realtalk.manager.LineList.fromObject(object.lineList);
                }
                if (object.messageRating != null) {
                    if (typeof object.messageRating !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.messageRating: object expected");
                    message.messageRating = $root.realtalk.manager.MessageRating.fromObject(object.messageRating);
                }
                if (object.operatorReportList != null) {
                    if (typeof object.operatorReportList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.operatorReportList: object expected");
                    message.operatorReportList = $root.realtalk.manager.OperatorReportList.fromObject(object.operatorReportList);
                }
                if (object.organization != null) {
                    if (typeof object.organization !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.organization: object expected");
                    message.organization = $root.realtalk.manager.Organization.fromObject(object.organization);
                }
                if (object.jwtKey != null) {
                    if (typeof object.jwtKey !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.jwtKey: object expected");
                    message.jwtKey = $root.realtalk.manager.JwtKey.fromObject(object.jwtKey);
                }
                if (object.jwtKeyList != null) {
                    if (typeof object.jwtKeyList !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.jwtKeyList: object expected");
                    message.jwtKeyList = $root.realtalk.manager.JwtKeyList.fromObject(object.jwtKeyList);
                }
                if (object.lastActivity != null) {
                    if (typeof object.lastActivity !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.lastActivity: object expected");
                    message.lastActivity = $root.realtalk.manager.LastActivity.fromObject(object.lastActivity);
                }
                if (object.numberUnreadMessages != null) {
                    if (typeof object.numberUnreadMessages !== "object")
                        throw TypeError(".realtalk.manager.ServerMessage.numberUnreadMessages: object expected");
                    message.numberUnreadMessages = $root.realtalk.manager.NumberUnreadMessages.fromObject(object.numberUnreadMessages);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.ServerMessage
             * @static
             * @param {realtalk.manager.ServerMessage} message ServerMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.realtalk.manager.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.operator != null && message.hasOwnProperty("operator")) {
                    object.operator = $root.realtalk.manager.Operator.toObject(message.operator, options);
                    if (options.oneofs)
                        object.body = "operator";
                }
                if (message.operatorList != null && message.hasOwnProperty("operatorList")) {
                    object.operatorList = $root.realtalk.manager.OperatorList.toObject(message.operatorList, options);
                    if (options.oneofs)
                        object.body = "operatorList";
                }
                if (message.message != null && message.hasOwnProperty("message")) {
                    object.message = $root.realtalk.manager.Message.toObject(message.message, options);
                    if (options.oneofs)
                        object.body = "message";
                }
                if (message.messageList != null && message.hasOwnProperty("messageList")) {
                    object.messageList = $root.realtalk.manager.MessageList.toObject(message.messageList, options);
                    if (options.oneofs)
                        object.body = "messageList";
                }
                if (message.chat != null && message.hasOwnProperty("chat")) {
                    object.chat = $root.realtalk.manager.Chat.toObject(message.chat, options);
                    if (options.oneofs)
                        object.body = "chat";
                }
                if (message.chatList != null && message.hasOwnProperty("chatList")) {
                    object.chatList = $root.realtalk.manager.ChatList.toObject(message.chatList, options);
                    if (options.oneofs)
                        object.body = "chatList";
                }
                if (message.chatUser != null && message.hasOwnProperty("chatUser")) {
                    object.chatUser = $root.realtalk.manager.ChatUser.toObject(message.chatUser, options);
                    if (options.oneofs)
                        object.body = "chatUser";
                }
                if (message.line != null && message.hasOwnProperty("line")) {
                    object.line = $root.realtalk.manager.Line.toObject(message.line, options);
                    if (options.oneofs)
                        object.body = "line";
                }
                if (message.lineList != null && message.hasOwnProperty("lineList")) {
                    object.lineList = $root.realtalk.manager.LineList.toObject(message.lineList, options);
                    if (options.oneofs)
                        object.body = "lineList";
                }
                if (message.messageRating != null && message.hasOwnProperty("messageRating")) {
                    object.messageRating = $root.realtalk.manager.MessageRating.toObject(message.messageRating, options);
                    if (options.oneofs)
                        object.body = "messageRating";
                }
                if (message.operatorReportList != null && message.hasOwnProperty("operatorReportList")) {
                    object.operatorReportList = $root.realtalk.manager.OperatorReportList.toObject(message.operatorReportList, options);
                    if (options.oneofs)
                        object.body = "operatorReportList";
                }
                if (message.organization != null && message.hasOwnProperty("organization")) {
                    object.organization = $root.realtalk.manager.Organization.toObject(message.organization, options);
                    if (options.oneofs)
                        object.body = "organization";
                }
                if (message.jwtKey != null && message.hasOwnProperty("jwtKey")) {
                    object.jwtKey = $root.realtalk.manager.JwtKey.toObject(message.jwtKey, options);
                    if (options.oneofs)
                        object.body = "jwtKey";
                }
                if (message.jwtKeyList != null && message.hasOwnProperty("jwtKeyList")) {
                    object.jwtKeyList = $root.realtalk.manager.JwtKeyList.toObject(message.jwtKeyList, options);
                    if (options.oneofs)
                        object.body = "jwtKeyList";
                }
                if (message.lastActivity != null && message.hasOwnProperty("lastActivity")) {
                    object.lastActivity = $root.realtalk.manager.LastActivity.toObject(message.lastActivity, options);
                    if (options.oneofs)
                        object.body = "lastActivity";
                }
                if (message.numberUnreadMessages != null && message.hasOwnProperty("numberUnreadMessages")) {
                    object.numberUnreadMessages = $root.realtalk.manager.NumberUnreadMessages.toObject(message.numberUnreadMessages, options);
                    if (options.oneofs)
                        object.body = "numberUnreadMessages";
                }
                return object;
            };

            /**
             * Converts this ServerMessage to JSON.
             * @function toJSON
             * @memberof realtalk.manager.ServerMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerMessage;
        })();

        manager.UpdateOperatorRoom = (function() {

            /**
             * Properties of an UpdateOperatorRoom.
             * @memberof realtalk.manager
             * @interface IUpdateOperatorRoom
             * @property {number|Long|null} [chatId] UpdateOperatorRoom chatId
             * @property {string|null} [name] UpdateOperatorRoom name
             */

            /**
             * Constructs a new UpdateOperatorRoom.
             * @memberof realtalk.manager
             * @classdesc Represents an UpdateOperatorRoom.
             * @implements IUpdateOperatorRoom
             * @constructor
             * @param {realtalk.manager.IUpdateOperatorRoom=} [properties] Properties to set
             */
            function UpdateOperatorRoom(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateOperatorRoom chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @instance
             */
            UpdateOperatorRoom.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateOperatorRoom name.
             * @member {string} name
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @instance
             */
            UpdateOperatorRoom.prototype.name = "";

            /**
             * Creates a new UpdateOperatorRoom instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {realtalk.manager.IUpdateOperatorRoom=} [properties] Properties to set
             * @returns {realtalk.manager.UpdateOperatorRoom} UpdateOperatorRoom instance
             */
            UpdateOperatorRoom.create = function create(properties) {
                return new UpdateOperatorRoom(properties);
            };

            /**
             * Encodes the specified UpdateOperatorRoom message. Does not implicitly {@link realtalk.manager.UpdateOperatorRoom.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {realtalk.manager.IUpdateOperatorRoom} message UpdateOperatorRoom message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateOperatorRoom.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an UpdateOperatorRoom message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.UpdateOperatorRoom} UpdateOperatorRoom
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateOperatorRoom.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.UpdateOperatorRoom();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateOperatorRoom message.
             * @function verify
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateOperatorRoom.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an UpdateOperatorRoom message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.UpdateOperatorRoom} UpdateOperatorRoom
             */
            UpdateOperatorRoom.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.UpdateOperatorRoom)
                    return object;
                var message = new $root.realtalk.manager.UpdateOperatorRoom();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an UpdateOperatorRoom message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @static
             * @param {realtalk.manager.UpdateOperatorRoom} message UpdateOperatorRoom
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateOperatorRoom.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this UpdateOperatorRoom to JSON.
             * @function toJSON
             * @memberof realtalk.manager.UpdateOperatorRoom
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateOperatorRoom.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateOperatorRoom;
        })();

        manager.UpdateOrganization = (function() {

            /**
             * Properties of an UpdateOrganization.
             * @memberof realtalk.manager
             * @interface IUpdateOrganization
             * @property {string|null} [name] UpdateOrganization name
             * @property {number|Long|null} [ownerOperatorId] UpdateOrganization ownerOperatorId
             */

            /**
             * Constructs a new UpdateOrganization.
             * @memberof realtalk.manager
             * @classdesc Represents an UpdateOrganization.
             * @implements IUpdateOrganization
             * @constructor
             * @param {realtalk.manager.IUpdateOrganization=} [properties] Properties to set
             */
            function UpdateOrganization(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateOrganization name.
             * @member {string} name
             * @memberof realtalk.manager.UpdateOrganization
             * @instance
             */
            UpdateOrganization.prototype.name = "";

            /**
             * UpdateOrganization ownerOperatorId.
             * @member {number|Long} ownerOperatorId
             * @memberof realtalk.manager.UpdateOrganization
             * @instance
             */
            UpdateOrganization.prototype.ownerOperatorId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UpdateOrganization instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {realtalk.manager.IUpdateOrganization=} [properties] Properties to set
             * @returns {realtalk.manager.UpdateOrganization} UpdateOrganization instance
             */
            UpdateOrganization.create = function create(properties) {
                return new UpdateOrganization(properties);
            };

            /**
             * Encodes the specified UpdateOrganization message. Does not implicitly {@link realtalk.manager.UpdateOrganization.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {realtalk.manager.IUpdateOrganization} message UpdateOrganization message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateOrganization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.ownerOperatorId != null && Object.hasOwnProperty.call(message, "ownerOperatorId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.ownerOperatorId);
                return writer;
            };

            /**
             * Decodes an UpdateOrganization message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.UpdateOrganization} UpdateOrganization
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateOrganization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.UpdateOrganization();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.ownerOperatorId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateOrganization message.
             * @function verify
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateOrganization.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.ownerOperatorId != null && message.hasOwnProperty("ownerOperatorId"))
                    if (!$util.isInteger(message.ownerOperatorId) && !(message.ownerOperatorId && $util.isInteger(message.ownerOperatorId.low) && $util.isInteger(message.ownerOperatorId.high)))
                        return "ownerOperatorId: integer|Long expected";
                return null;
            };

            /**
             * Creates an UpdateOrganization message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.UpdateOrganization} UpdateOrganization
             */
            UpdateOrganization.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.UpdateOrganization)
                    return object;
                var message = new $root.realtalk.manager.UpdateOrganization();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.ownerOperatorId != null)
                    if ($util.Long)
                        (message.ownerOperatorId = $util.Long.fromValue(object.ownerOperatorId)).unsigned = true;
                    else if (typeof object.ownerOperatorId === "string")
                        message.ownerOperatorId = parseInt(object.ownerOperatorId, 10);
                    else if (typeof object.ownerOperatorId === "number")
                        message.ownerOperatorId = object.ownerOperatorId;
                    else if (typeof object.ownerOperatorId === "object")
                        message.ownerOperatorId = new $util.LongBits(object.ownerOperatorId.low >>> 0, object.ownerOperatorId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an UpdateOrganization message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.UpdateOrganization
             * @static
             * @param {realtalk.manager.UpdateOrganization} message UpdateOrganization
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateOrganization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.ownerOperatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ownerOperatorId = options.longs === String ? "0" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.ownerOperatorId != null && message.hasOwnProperty("ownerOperatorId"))
                    if (typeof message.ownerOperatorId === "number")
                        object.ownerOperatorId = options.longs === String ? String(message.ownerOperatorId) : message.ownerOperatorId;
                    else
                        object.ownerOperatorId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerOperatorId) : options.longs === Number ? new $util.LongBits(message.ownerOperatorId.low >>> 0, message.ownerOperatorId.high >>> 0).toNumber(true) : message.ownerOperatorId;
                return object;
            };

            /**
             * Converts this UpdateOrganization to JSON.
             * @function toJSON
             * @memberof realtalk.manager.UpdateOrganization
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateOrganization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateOrganization;
        })();

        manager.UpdateP2PChat = (function() {

            /**
             * Properties of an UpdateP2PChat.
             * @memberof realtalk.manager
             * @interface IUpdateP2PChat
             * @property {number|Long|null} [chatId] UpdateP2PChat chatId
             * @property {boolean|null} [visible] UpdateP2PChat visible
             */

            /**
             * Constructs a new UpdateP2PChat.
             * @memberof realtalk.manager
             * @classdesc Represents an UpdateP2PChat.
             * @implements IUpdateP2PChat
             * @constructor
             * @param {realtalk.manager.IUpdateP2PChat=} [properties] Properties to set
             */
            function UpdateP2PChat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateP2PChat chatId.
             * @member {number|Long} chatId
             * @memberof realtalk.manager.UpdateP2PChat
             * @instance
             */
            UpdateP2PChat.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateP2PChat visible.
             * @member {boolean} visible
             * @memberof realtalk.manager.UpdateP2PChat
             * @instance
             */
            UpdateP2PChat.prototype.visible = false;

            /**
             * Creates a new UpdateP2PChat instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {realtalk.manager.IUpdateP2PChat=} [properties] Properties to set
             * @returns {realtalk.manager.UpdateP2PChat} UpdateP2PChat instance
             */
            UpdateP2PChat.create = function create(properties) {
                return new UpdateP2PChat(properties);
            };

            /**
             * Encodes the specified UpdateP2PChat message. Does not implicitly {@link realtalk.manager.UpdateP2PChat.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {realtalk.manager.IUpdateP2PChat} message UpdateP2PChat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateP2PChat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatId);
                if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.visible);
                return writer;
            };

            /**
             * Decodes an UpdateP2PChat message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.UpdateP2PChat} UpdateP2PChat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateP2PChat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.UpdateP2PChat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chatId = reader.uint64();
                        break;
                    case 2:
                        message.visible = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateP2PChat message.
             * @function verify
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateP2PChat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.visible != null && message.hasOwnProperty("visible"))
                    if (typeof message.visible !== "boolean")
                        return "visible: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateP2PChat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.UpdateP2PChat} UpdateP2PChat
             */
            UpdateP2PChat.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.UpdateP2PChat)
                    return object;
                var message = new $root.realtalk.manager.UpdateP2PChat();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = true;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber(true);
                if (object.visible != null)
                    message.visible = Boolean(object.visible);
                return message;
            };

            /**
             * Creates a plain object from an UpdateP2PChat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.UpdateP2PChat
             * @static
             * @param {realtalk.manager.UpdateP2PChat} message UpdateP2PChat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateP2PChat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.visible = false;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber(true) : message.chatId;
                if (message.visible != null && message.hasOwnProperty("visible"))
                    object.visible = message.visible;
                return object;
            };

            /**
             * Converts this UpdateP2PChat to JSON.
             * @function toJSON
             * @memberof realtalk.manager.UpdateP2PChat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateP2PChat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateP2PChat;
        })();

        manager.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof realtalk.manager
             * @interface IVersion
             */

            /**
             * Constructs a new Version.
             * @memberof realtalk.manager
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {realtalk.manager.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof realtalk.manager.Version
             * @static
             * @param {realtalk.manager.IVersion=} [properties] Properties to set
             * @returns {realtalk.manager.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link realtalk.manager.Version.verify|verify} messages.
             * @function encode
             * @memberof realtalk.manager.Version
             * @static
             * @param {realtalk.manager.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof realtalk.manager.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {realtalk.manager.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.realtalk.manager.Version();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof realtalk.manager.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof realtalk.manager.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {realtalk.manager.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.realtalk.manager.Version)
                    return object;
                return new $root.realtalk.manager.Version();
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof realtalk.manager.Version
             * @static
             * @param {realtalk.manager.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof realtalk.manager.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Value enum.
             * @name realtalk.manager.Version.Value
             * @enum {number}
             * @property {number} PLACEHOLDER1=0 PLACEHOLDER1 value
             * @property {number} PLACEHOLDER2=0 PLACEHOLDER2 value
             * @property {number} MAJOR=1 MAJOR value
             * @property {number} MINOR=7 MINOR value
             * @property {number} PATCH=0 PATCH value
             */
            Version.Value = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PLACEHOLDER1"] = 0;
                values["PLACEHOLDER2"] = 0;
                values[valuesById[1] = "MAJOR"] = 1;
                values[valuesById[7] = "MINOR"] = 7;
                values["PATCH"] = 0;
                return values;
            })();

            return Version;
        })();

        return manager;
    })();

    return realtalk;
})();

module.exports = $root;
