import { getContext, put } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import { get, isEmpty } from 'lodash';
import { MANAGER_REQUESTS, SERVICE_NAMES } from 'Constants';

export function* getCurrentOperator() {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.getCurrentOperatorPending());
        const { body } = yield managerService.request(MANAGER_REQUESTS.GET_CURRENT_OPERATOR, {});
        yield put(actions.manager.getCurrentOperatorSuccess(body));
    } catch (err) {
        yield put(actions.manager.getCurrentOperatorFailure(err.message));
    }
}

export function* getOperatorList() {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);

        yield put(actions.manager.getOperatorListPending());

        const response = yield managerService.request(MANAGER_REQUESTS.GET_OPERATOR_LIST, {});
        const responseOperatorsList = get(response, 'body.operator', []);

        if (isEmpty(responseOperatorsList)) {
            yield put(actions.manager.operatorListIsEmpty());
            return;
        }

        yield put(actions.manager.getOperatorListSuccess(responseOperatorsList));
    } catch (err) {
        yield put(actions.manager.getOperatorListFailure(err.message));
    }
}

export function* createOperator({ payload: operator }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.createOperatorPending());
        yield managerService.request(MANAGER_REQUESTS.OPERATOR, operator);
        yield put(actions.manager.createOperatorSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.createOperatorFailure(err.message));
    }
}

export function* editProfile({ payload: operator }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.editProfilePending());
        yield managerService.request(MANAGER_REQUESTS.OPERATOR, operator);
        yield put(actions.manager.editProfileSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.editProfileFailure(err.message));
    }
}

export function* removeOperator({ payload: operator }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.removeOperatorPending());
        yield managerService.request(MANAGER_REQUESTS.OPERATOR, operator);
        yield put(actions.manager.removeOperatorSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.removeOperatorFailure(err.message));
    }
}

export function* editOperator({ payload: operator }) {
    try {
        const managerService = yield getContext(SERVICE_NAMES.MANAGER);
        yield put(actions.manager.editOperatorPending());
        yield managerService.request(MANAGER_REQUESTS.OPERATOR, operator);
        yield put(actions.manager.editOperatorSuccess());
    } catch (err) {
        console.log(err);
        yield put(actions.manager.editOperatorFailure(err.message));
    }
}
