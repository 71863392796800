import { REQUEST_STATUSES, CONNECTION_STATUSES } from 'Constants';

// Connection
export const isOpenConnectionStatus = (status) => status === CONNECTION_STATUSES.OPEN;

// Request
export const isInitRequestStatus = (status) => status === REQUEST_STATUSES.INIT;
export const isSuccessRequestStatus = (status) => status === REQUEST_STATUSES.SUCCESS;
export const isPendingRequestStatus = (status) => status === REQUEST_STATUSES.PENDING;
export const isErrorRequestStatus = (status) => status === REQUEST_STATUSES.FAILURE;
export const isEmptyRequestStatus = (status) => status === REQUEST_STATUSES.EMPTY;
