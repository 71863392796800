import { createAction } from '@reduxjs/toolkit';
import { SERVICE_NAMES } from 'Constants';

export const resetAvatar = createAction('RESET_AVATAR');
export const requestDownloadAvatarPending = createAction('FILE_REQUEST_DOWNLOAD_AVATAR_PENDING');
export const requestDownloadAvatarSuccess = createAction('FILE_REQUEST_DOWNLOAD_AVATAR_SUCCESS');
export const requestDownloadAvatarFailure = createAction('FILE_REQUEST_DOWNLOAD_AVATAR_FAILURE');

export const uploadFile = (file) => async (dispatch, getState, services) => {
    try {
        const response = await services[SERVICE_NAMES.FILE].upload({
            file
        });

        return { error: null, result: response };
    } catch (error) {
        return { error };
    }
};

export const downloadAvatarFile = (fileId, id) => async (dispatch, getState, services) => {
    dispatch(requestDownloadAvatarPending());
    try {
        const response = await services[SERVICE_NAMES.FILE].download(fileId);
        dispatch(requestDownloadAvatarSuccess({ response, id }));

        return { error: null, result: response };
    } catch (error) {
        dispatch(requestDownloadAvatarFailure(error.message));
        return { error };
    }
};

// It is a synchronous action (no server request is needed)
export const getUrl = ({ fileId }) => (dispatch, getState, services) => {
    try {
        const url = services[SERVICE_NAMES.FILE].getUrl({
            fileId
        });

        return { error: null, result: url };
    } catch (error) {
        return { error };
    }
};
