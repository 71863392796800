import { createReducer } from '@reduxjs/toolkit';
import { uniq, concat } from 'lodash';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const numberUnreadMessages = createReducer(initialState.numberUnreadMessages, {
    [actions.manager.getNumberUnreadMessagesSuccess]: ({ allIds, byId }, { payload }) => {
        const { chatId, count } = payload;
        return {
            byId: { ...byId, [chatId]: count },
            allIds: uniq(concat(chatId, allIds))
        };
    }
});

export default numberUnreadMessages;
