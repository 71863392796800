import { createReducer } from '@reduxjs/toolkit';
import { keyBy, uniq, concat } from 'lodash';
import initialState from 'Store/initialState';
import * as actions from 'Store/actions';

const chats = createReducer(initialState.chats, {
    [actions.manager.getChatListSuccess]: (state, { payload: chats }) => {
        const allIds = chats.map((chat) => chat.id);
        const byId = keyBy(chats, (chat) => chat.id);
        return { byId, allIds };
    },
    [actions.manager.gotChat]: ({ allIds, byId }, { payload: chat }) => {
        return {
            byId: { ...byId, [chat.id]: chat },
            allIds: uniq(concat(chat.id, allIds))
        };
    }
});

export default chats;
