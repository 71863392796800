export const ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    PROFILE: '/profile',
    SETTINGS: '/settings',
    ACTIVATION: '/activate',
    RESET_PASSWORD: '/reset-password',
    OPERATORS: '/operators',
    CREATE_OPERATOR: '/operators/create-operator',
    DOMAINS: '/domains',
    CREATE_DOMAIN: '/domains/create-domain'
};
