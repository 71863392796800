import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '../scss/main.scss';
import 'Utils/siteNamespace';
import { initI18n } from './i18n';
import initTabbingControl from 'Utils/initTabbingControl';
import GrecaptchaService from 'Services/auth/GrecaptchaService';
import AuthService from 'Services/auth/AuthService';
import ManagerWebSocketService from 'Services/websocket/ManagerWebSocketService';
import FileService from 'Services/FileService';
import { ERRORS, SERVICE_NAMES, ENV_VARIABLES, getWebSocketRoutes } from 'Constants';
import AppRoot from './AppRoot';
import AppError from 'Components/AppError/UnauthenticatedAppError';
import initStore from 'Store';
import { setAppError, auth, setLocaleInStore } from 'Store/actions';
import { init as initLogger } from 'Utils/logger';

initTabbingControl();

initLogger();

const appRootTag = document.getElementById('app-root');
const handleAuthCheck = (Component) => {
    ReactDOM.render(Component, appRootTag);
};

const CatchErrorRoot = ({ store }) => {
    return (
        <Provider store={store}>
            <AppError name={ERRORS.SERVER} />
        </Provider>
    );
};

let store;

Promise.all([initI18n()])
    .then(([locale]) => {
        const WebSocketUrl = getWebSocketRoutes(ENV_VARIABLES.DOMAIN)(SERVICE_NAMES.MANAGER);

        const services = {
            [SERVICE_NAMES.GRECAPTCHA]: new GrecaptchaService(ENV_VARIABLES.GRECAPTCHA_KEY),
            [SERVICE_NAMES.AUTH]: new AuthService(ENV_VARIABLES.DOMAIN),
            [SERVICE_NAMES.MANAGER]: new ManagerWebSocketService({ url: WebSocketUrl }),
            [SERVICE_NAMES.FILE]: new FileService(ENV_VARIABLES.DOMAIN)
        };

        store = initStore(services);
        store.dispatch(setLocaleInStore(locale));
        return store.dispatch(auth.requestRefresh());
    })
    .then(() => {
        handleAuthCheck(<AppRoot store={store} />);
    })
    .catch((err) => {
        const catchLocalStore = store ? store : { dispatch: () => {} };
        catchLocalStore.dispatch(setAppError(ERRORS.SERVER));
        handleAuthCheck(<CatchErrorRoot store={catchLocalStore} />);
        console.error(err);
    });
