export const MAX_FILE_SIZE = 32 * 1024 * 1024; // 32MB (1024 - to be consistent with the server)

export const FILE_MIMETYPES = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    zip: 'application/zip',
    gzip: 'application/gzip',
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    webp: 'image/webp'
};

export const FILE_SIZE_TYPES = {
    BYTES: 'bytes',
    KILOBTYES: 'kilobytes',
    MEGABYTES: 'megabytes'
};
