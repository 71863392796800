import { combineReducers } from '@reduxjs/toolkit';
import * as actions from '../actions';
import { getCurrentLang } from 'AppDir/i18n';
import isAuthenticated from './isAuthenticated';
import isMainMenuOpened from './isMainMenuOpened';
import connectionsStatuses from './connectionsStatuses';
import requestStatuses from './requestStatuses';
import appError from './appError';
import currentLocale from './currentLocale';
import currentOperator from './currentOperator';
import currentOrganization from './currentOrganization';
import chats from './chats';
import activeChatId from './activeChatId';
import chatUsers from './chatUsers';
import messages from './messages';
import operators from './operators';
import avatars from './avatars';
import numberUnreadMessages from './numberUnreadMessages';
import lastMessages from './lastMessages';

const appReducer = combineReducers({
    isAuthenticated,
    isMainMenuOpened,
    appError,
    connectionsStatuses,
    requestStatuses,
    currentLocale,
    currentOperator,
    currentOrganization,
    chats,
    activeChatId,
    chatUsers,
    messages,
    operators,
    avatars,
    numberUnreadMessages,
    lastMessages
});

/**
 * Root reducer for resetting the store
 * Credits to Dan Abramov: https://stackoverflow.com/a/35641992
 **/
const rootReducer = (state, action) => {
    if (action.type === actions.auth.requestLogoutSuccess.toString()) {
        const currentLocale = getCurrentLang();
        state = {
            currentLocale
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
