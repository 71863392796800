export const REQUEST_STATUSES = {
    INIT: 'init',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILURE: 'failure',
    EMPTY: 'empty'
};

export const CONNECTION_STATUSES = {
    INIT: 'init',
    CONNECTING: 'connecting',
    OPEN: 'open',
    CLOSE: 'close',
    ON_ERROR: 'onError'
};
