import { put, select } from 'redux-saga/effects';
import { includes, values } from 'lodash';
import * as actions from 'Store/actions';
import { updateRootTagLang } from 'AppDir/i18n';
import i18n from 'i18next';
import { LOCALES } from 'Constants';

export function* handleSetLocale({ payload: locale }) {
    if (!includes(values(LOCALES), locale)) {
        return;
    }

    // Upd i18next state
    yield i18n.changeLanguage(locale);
    updateRootTagLang();

    // save to srv function subscribed to this action
    yield put(actions.setLocaleAndSendIt(locale));
}

export function* handleGotLocale({ payload: locale }) {
    if (!includes(values(LOCALES), locale)) {
        return;
    }

    const currentLocale = yield select((state) => state.currentLocale);
    if (currentLocale === locale) {
        return;
    }

    // Upd i18next state
    yield i18n.changeLanguage(locale);
    updateRootTagLang();

    yield put(actions.setLocaleInStore(locale));
}
