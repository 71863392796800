import { CONNECTION_STATUSES, REQUEST_STATUSES, ERRORS } from 'Constants';

const initialState = {
    isAuthenticated: false,
    isMainMenuOpened: false,
    appError: ERRORS.NONE,
    connectionsStatuses: {
        manager: CONNECTION_STATUSES.INIT
    },
    requestStatuses: {
        currentOperator: REQUEST_STATUSES.INIT,
        chats: REQUEST_STATUSES.INIT,
        activation: REQUEST_STATUSES.INIT,
        messages: REQUEST_STATUSES.INIT,
        sendMessage: REQUEST_STATUSES.INIT,
        operators: REQUEST_STATUSES.INIT,
        createOperator: REQUEST_STATUSES.INIT,
        editProfile: REQUEST_STATUSES.INIT,
        addOrganizationDomain: REQUEST_STATUSES.INIT,
        removeOrganizationDomain: REQUEST_STATUSES.INIT,
        removeOperator: REQUEST_STATUSES.INIT,
        editOperator: REQUEST_STATUSES.INIT,
        banChat: REQUEST_STATUSES.INIT,
        currentOrganization: REQUEST_STATUSES.INIT,
        updateOrganization: REQUEST_STATUSES.INIT,
        numberUnreadMessages: REQUEST_STATUSES.INIT,
        lastMessages: REQUEST_STATUSES.INIT,
        markMessageAsRead: REQUEST_STATUSES.INIT
    },
    currentLocale: '',
    currentOperator: {},
    currentOrganization: {},
    chats: {
        byId: {},
        allIds: []
    },
    chatUsers: {
        byId: {},
        allIds: []
    },
    activeChatId: null,
    messages: {
        byId: {},
        allIds: []
    },
    operators: {
        byId: {},
        allIds: []
    },
    avatars: {
        byId: {},
        allIds: []
    },
    numberUnreadMessages: {
        byId: {},
        allIds: []
    },
    lastMessages: {
        byId: {},
        allIds: []
    }
};

export default initialState;
